<template>
  <div>
    <div
      @click.prevent="toggleAccordion"
      class=""
      :aria-expanded="isOpen"
      :aria-controls="id"
    >
      <div class="title-wrapper">
        <slot name="title" />
      </div>
    </div>

    <transition name="bounce" duration-enter="0.2" duration-leave="0.2" appear>
      <div v-if="isOpen" :id="id" class="accordion-content">
        <slot name="content" />
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
    toggleAccordion: {
      type: Function,
      default: () => {},
    },
  },
};
</script>
<style lang="scss" scoped src="@/Common/Styles/appAccordion.scss"></style>
