<template>
  <div class="nft-pfp-wrapper">
    <div class="nft-pfp-file-input">
      <div class="file-input-wrapper">
        <app-file-input
          v-model="localSelectedFileValue"
          name="SelectedFileNftPfp"
          :nftPfp="true"
          accept="image/png,image/jpeg,image/gif,image/webp"
        />
      </div>
      <div class="nft-pfp-explanation-text">
        <div>
          <img
            src="@/Common/Icons/infoIcon.svg"
            alt="image-regular-twitter-pfp"
          />
        </div>
        <div class="nft-pfp-text">
          <p>
            NFT holders of a specific NFT collection can participate by setting
            their Twitter profile picture as an NFT "hexagon symbol". Specify a
            collection address in a field below.
          </p>
          <p>
            Upload an image on the left that will represent your AirBro campaign
            on our platform.
          </p>
        </div>
      </div>
    </div>
    <div class="nft-pfp-data-input-wrapper">
      <div class="row nft-colletion-address">
        <div class="col-md-12">
          <app-input
            type="text"
            v-model="nftCollectionAddress"
            name="NftCollectionAddress"
            inputClass="form-box-input"
            labelClass="label"
            labelText="NFT Address"
            placeholder="Collection Address"
            @change="changeNftCollectionAddress(nftCollectionAddress)"
          />
        </div>
      </div>
      <div class="number-of-days-wrapper">
        <div class="number-of-days-input-wrapper">
          <app-input
            type="text"
            v-model="campaignDaysToHoldPFP"
            name="CampaignDaysToHoldPFP"
            inputClass="form-box-input"
            labelClass="label"
            labelText="Duration"
            placeholder="Enter number of days"
            @change="changeDaysToHoldPFP(campaignDaysToHoldPFP)"
          />
        </div>
        <div class="nft-pfp-explanation-text folowers">
          <div>
            <img
              src="@/Common/Icons/infoIcon.svg"
              alt="image-regular-twitter-pfp"
            />
          </div>
          <div class="nft-pfp-text">
            <p>
              Define how long participants will have to hold the image as their
              Profile Picture.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref, watch } from "vue";
import { useStore } from "vuex";
import AppInput from "@/elements/AppInput";
import AppFileInput from "@/elements/AppFileInput";
import useNftPfpRequirements from "@/composables/useNftPfpRequirements";
export default {
  components: {
    AppInput,
    AppFileInput,
  },
  setup() {
    const store = useStore();
    const { changeNftCollectionAddress, changeDaysToHoldPFP } =
      useNftPfpRequirements(store);

    const nftCollectionAddress = ref("");
    const numberOfFolowers = ref("");
    const accountCreatedDate = ref("");
    const requirementNftAddress = ref("");
    const localSelectedFileValue = ref({});
    const campaignDaysToHoldPFP = ref("");

    watch(localSelectedFileValue, (newValue) => {
      if (newValue) {
        store.dispatch("nftPfpRequirements/commitByKey", {
          selectedFileImage: newValue,
        });
      }
    });

    return {
      numberOfFolowers,
      accountCreatedDate,
      nftCollectionAddress,
      requirementNftAddress,
      localSelectedFileValue,
      changeNftCollectionAddress,
      campaignDaysToHoldPFP,
      changeDaysToHoldPFP,
    };
  },
};
</script>
<style lang="scss" scoped src="@/Common/Styles/nftPFP.scss"></style>
