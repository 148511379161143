<template>
  <div class="campaign-details-wrapper">
    <app-accordion
      :isOpen="isOpen"
      :toggleAccordion="toggleAccordion"
      id="details"
    >
      <template v-slot:title>
        <div
          class="campaign-headline-wrapper"
          :class="{ accordionOpen: isOpen }"
        >
          <div class="illustration-wrapper">
            <img
              src="@/Common/Icons/campaignDetailsIllustration.svg"
              alt="campaignDetailsIllustration"
              class="campaign-details-illustration"
            />
          </div>
          <div class="headline-text-wrapper">
            <span
              class="campaign-headline"
              :class="{ campaignHeadlineAccordionOpen: isOpen }"
              >Campaign Details</span
            >
            <span class="camapign-undertext"
              >Make your campaign appealing to your future participants.</span
            >
          </div>
          <div class="arrow-wrapper">
            <img
              src="@/Common/Icons/accordionArrowDown.svg"
              alt="arrowUp"
              v-if="!isOpen"
            />
            <img
              src="@/Common/Icons/accordionArrowUp.svg"
              alt="arrowDown"
              v-else
            />
          </div>
        </div>
      </template>
      <template v-slot:content>
        <div class="campaign-details-wrapper">
          <div class="row">
            <!-- <div class="col-md-4 center-text">
            <span class="capmaign-input-label"
              >Campaign Name <span>*</span></span
            >
          </div> -->
            <div class="col-md-12">
              <app-input
                type="text"
                v-model="campaignName"
                name="CampaignName"
                inputClass="form-box-input"
                labelClass="label"
                labelText="Campaign Name"
                placeholder="Enter Campaign Title..."
                @change="changeCampaignName(campaignName)"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <app-textarea
                type="text"
                v-model="campaignDescription"
                labelClass="label"
                labelText="Campaign Description"
                name="CampaignDescription"
                textAreaClass="form-box-textarea"
                placeholder='e.g “1000 NFTs for Loyal holders"'
                cols="1"
                rows="4"
                @change="changeCampaignDescription(campaignDescription)"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-6">
                  <app-input-date-picker
                    name="StartDate"
                    placeholder="Choose a Date..."
                    v-model="campaignStartDate"
                    :minDate="minDateStart()"
                    position="left"
                    :label="true"
                    labelClass="label"
                    labelText="Campaign Starts on"
                    format="YYYY-MM-DD HH:mm"
                    @update:modelValue="
                      changeCampaignStartDate(campaignStartDate)
                    "
                  />
                </div>
                <div class="col-md-6">
                  <app-input-date-picker
                    name="EndDate"
                    placeholder="Choose a Date..."
                    v-model="campaignEndDate"
                    :minDate="minDateEnd"
                    position="left"
                    :label="true"
                    labelClass="label"
                    labelText="Campaign Ends on"
                    format="YYYY-MM-DD HH:mm"
                    @update:modelValue="changeCampaignEndDate(campaignEndDate)"
                  />
                </div>
              </div>
              <div class="start-date-explanation-text folowers col-md-12">
                <div>
                  <img
                    src="@/Common/Icons/infoIcon.svg"
                    alt="image-regular-twitter-pfp"
                  />
                </div>
                <div class="start-date-text">
                  <p>
                    Campaign duration is time period available to join campaign.
                    This period will be reduced by the time necessary for
                    participants to hold picture.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <app-input
                type="text"
                v-model="campaignNumberOfParticipats"
                name="CampaignNumberOfParticipats"
                labelText="Max Number of Participants (optional)"
                inputClass="form-box-input"
                labelClass="label"
                placeholder="Enter Max Number of participants"
                @change="
                  changeCampaignNumberOfParticipats(campaignNumberOfParticipats)
                "
              />
            </div>
            <div class="col-md-6">
              <app-input
                type="text"
                v-model="campaignMaxNumberOfWinners"
                name="CampaignMaxNumberOfWinners"
                inputClass="form-box-input"
                labelText="Max Number of Winners (optional)"
                labelClass="label"
                placeholder="Enter Max Number of posible winners"
                @change="
                  changeCampaignMaxNumberOfWinners(campaignMaxNumberOfWinners)
                "
              />
            </div>
          </div>
          <div class="gasless-claim">
            <div>
              <input
                type="checkbox"
                v-model="isGaslessClaimLocal"
                true-value="true"
                false-value="false"
                name="GaslessClaim"
                class="checkbox-input"
                labelClass="label"
              />
            </div>
            <div class="center-text">
              <span class="capmaign-input-label">Gasless Reward Claiming</span>
            </div>
          </div>
        </div>
      </template>
    </app-accordion>
  </div>
</template>
<script>
import { ref, computed } from "vue";
import { useStore } from "vuex";
import AppInput from "@/elements/AppInput";
import AppInputDatePicker from "@/elements/AppInputDatePicker";
import useCampaign from "@/composables/useCampaign";
import AppTextarea from "@/elements/AppTextarea";
import AppAccordion from "@/elements/AppAccordion.vue";
export default {
  components: {
    AppInput,
    AppTextarea,
    AppInputDatePicker,
    AppAccordion,
  },
  setup() {
    const store = useStore();
    const {
      isGaslessClaim,
      changeCampaignName,
      isGaslessClaimLocal,
      changeIsGaslessClaim,
      changeCampaignStartDate,
      changeCampaignEndDate,
      changeCampaignDescription,
      changeCampaignMaxNumberOfWinners,
      changeCampaignNumberOfParticipats,
    } = useCampaign(store);

    const campaignName = ref("");
    const campaignEndDate = ref("");
    const campaignStartDate = ref("");
    const campaignDescription = ref("");
    const campaignNumberOfParticipats = ref("");
    const campaignMaxNumberOfWinners = ref("");
    const isOpen = ref(true);

    const minDateStart = () => {
      return new Date(new Date().setDate(new Date().getDate()));
    };
    const minDateEnd = computed(() => {
      if (campaignStartDate.value !== "") {
        return new Date(
          new Date().setDate(new Date(campaignStartDate.value).getDate())
        );
      }
      return null;
    });

    const toggleAccordion = () => {
      isOpen.value = !isOpen.value;
    };
    return {
      isOpen,
      minDateEnd,
      minDateStart,
      campaignName,
      isGaslessClaim,
      toggleAccordion,
      campaignEndDate,
      campaignStartDate,
      changeCampaignName,
      isGaslessClaimLocal,
      campaignDescription,
      changeIsGaslessClaim,
      changeCampaignEndDate,
      changeCampaignStartDate,
      changeCampaignDescription,
      campaignMaxNumberOfWinners,
      campaignNumberOfParticipats,
      changeCampaignMaxNumberOfWinners,
      changeCampaignNumberOfParticipats,
    };
  },
};
</script>

<style lang="scss" scoped src="@/Common/Styles/campaignDetails.scss"></style>
