import { computed } from "vue";

const useCustomPfpRequirements = (store) => {
  const customPfpImage = computed(() =>
    store.getters["customPfpRequirements/getByKey"]("customPfpImage")
  );
  const selectedFileCustomPfpImage = computed(() =>
    store.getters["customPfpRequirements/getByKey"]("selectedFileImage")
  );
  const campaignDaysToHoldPFPCustom = computed(() =>
    store.getters["customPfpRequirements/getByKey"](
      "campaignDaysToHoldPFPCustom"
    )
  );

  const changeDaysToHoldPFPCustom = (value) => {
    store.dispatch("customPfpRequirements/commitByKey", {
      campaignDaysToHoldPFPCustom: value,
    });
  };

  return {
    changeDaysToHoldPFPCustom,
    customPfpImage,
    selectedFileCustomPfpImage,
    campaignDaysToHoldPFPCustom,
  };
};
export default useCustomPfpRequirements;
