<template>
  <section class="action-card">
    <div class="card">
      <div class="card-header">
        <h3 class="epilogue-text-m-dark">Required Action</h3>
        <h2 class="epilogue-subtitle-alt">
          <template
            v-if="
              campaignData.proofType === 'image' ||
              campaignData.proofType === 'nft'
            "
            >Change Twitter Profile Picture</template
          >
          <template v-else-if="campaignData.proofType === 'post'"
            >Post Tweet</template
          >
        </h2>
        <div class="tags">
          <span
            v-if="
              campaignData.proofType === 'image' ||
              campaignData.proofType === 'nft'
            "
            class="tag epilogue-xs"
          >
            <img
              src="@/Common/Icons/CampaignSinglePage/holdDuration.svg"
              alt="Hold Duration"
              class="tag-icon"
            />
            {{ campaignData.holdDuration }} Days
          </span>
          <span v-if="campaignData.maxWinners > 0" class="tag epilogue-xs">
            <img
              src="@/Common/Icons/CampaignSinglePage/presentIcon.svg"
              alt="Winner Choosing Type"
              class="tag-icon"
            />
            <template
              v-if="
                campaignData.proofType === 'image' ||
                campaignData.proofType === 'nft' ||
                (campaignData.proofType === 'post' &&
                  postData.pickWinners === 'random')
              "
            >
              Winners chosen randomly
            </template>
            <template
              v-else-if="
                campaignData.proofType === 'post' &&
                postData.pickWinners === 'mostLiked'
              "
            >
              Winners chosen by most likes
            </template>
          </span>
        </div>
      </div>
      <div class="card-body" v-if="campaignData.proofType === 'post'">
        <div class="description">
          {{ postData.title }}
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { onMounted, ref, watch } from "vue";

export default {
  name: "CampaignActionCard",
  components: {},
  props: {
    campaignData: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const postData = ref({});

    watch(
      () => props.campaignData.sharingPost,
      async (newVal, oldVal) => {
        oldVal;
        if (newVal) postData.value = JSON.parse(newVal);
      }
    );
    onMounted(async () => {});
    return {
      postData,
    };
  },
};
</script>
<style lang="scss" scoped src="@/Common/Styles/actionCard.scss"></style>
