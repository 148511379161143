<template>
  <Modal :modalActive="modalActive" :modalClass="'campaign-share-modal'">
    <div>
      <div class="share-modal-image">
        <img src="@/Common/Icons/shareModalImage.svg" alt="shareModalImage" />
      </div>
      <div class="share-modal-info-text">
        <p class="share-modal-text-first-line">
          Campaign Created Successfully!
        </p>
        <p class="share-modal-text-second-line">
          You can now share your campaign to the world or check it out here
        </p>
      </div>
      <div class="share-options">
        <div class="twitter-share">
          <ShareNetwork
            v-for="network in networks"
            :network="network.network"
            :key="network.network"
            :url="campaignUrl"
            :title="sharing.title"
            :description="sharing.description"
            :quote="sharing.quote"
            :hashtags="sharing.hashtags"
          >
            <div
              :class="[{ active: twitterActive }, 'button']"
              @mousedown="twitterActive = true"
              @mouseup="twitterActive = false"
            >
              <div>
                <img src="@/Common/Icons/shareModalTwitterLogo.svg" alt="" />
              </div>
              <div>
                <span>SHARE ON TWITTER</span>
              </div>
            </div>
          </ShareNetwork>
        </div>
        <div class="link-share">
          <span v-show="shareMsg" class="tooltip-share">{{ shareMsg }}</span>
          <div
            :class="[{ active: copyActive }, 'button']"
            @mousedown="(copyActive = true), shareLink()"
            @mouseup="copyActive = false"
          >
            <img src="@/Common/Icons/shareModalCopyLink.svg" alt="" />
            <span>COPY LINK</span>
          </div>
        </div>
      </div>
    </div>
  </Modal>
</template>
<script>
import Modal from "@/components/Modal";
import useUtilities from "@/composables/useUtilities";
import { ref } from "vue";

export default {
  name: "CampaignShareModal",
  components: {
    Modal,
  },
  props: {
    shareInfo: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const shareMsg = ref("");
    const twitterActive = ref(false);
    const copyActive = ref(false);
    const { copyToClipboard } = useUtilities();
    const campaignUrl = ref(
      window.location.origin + "/campaign/" + props.shareInfo.campaignInfo.id
    );
    const sharing = ref({
      title: "Check out this campaign on AirBro: ",
      description: "",
      quote: "",
      hashtags: ["airbro"],
      twitterUser: "",
    });
    const networks = ref([
      {
        network: "twitter",
        name: "Twitter",
        icon: require(`@/Common/Icons/twitterIconGreen.svg`),
        shareIcon: require(`@/Common/Icons/twitterIconGreen.svg`),
        shareIconHover: require(`@/Common/Icons/twitterIconGreen.svg`),
        shareIconClick: require(`@/Common/Icons/twitterIconBlue.svg`),
      },
    ]);
    const shareLink = () => {
      copyToClipboard(campaignUrl.value);
      shareMsg.value = "Link copied";
      setTimeout(() => {
        shareMsg.value = "";
      }, 3000);
    };
    return {
      sharing,
      networks,
      shareMsg,
      shareLink,
      copyActive,
      campaignUrl,
      twitterActive,
      copyToClipboard,
    };
  },
};
</script>
