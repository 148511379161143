<template>
  <Modal
    @close="toggleModal"
    :modalActive="modalActive"
    :modalClass="'campaign-share-modal'"
  >
    <div>
      <h2 class="title-accent-sign-up">Submitted Successfully!</h2>
      <p class="description sign-up-desription">
        Thank you for your interest in our services.
      </p>
      <p class="description sign-up-desription">
        We'll be in touch soon to discuss how we can help you achieve your
        goals.
      </p>
      <p class="description sign-up-desription">
        In the meantime, please feel free to browse our website and learn more
        about what we have to offer.
      </p>
      <p class="description sign-up-desription">Thanks again, Your AirBro</p>
    </div>
  </Modal>
</template>
<script>
import Modal from "@/components/Modal";

export default {
  name: "SignUpCreatorModal",
  components: {
    Modal,
  },
  props: {
    modalActive: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return {};
  },
};
</script>
