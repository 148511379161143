<template>
  <div class="airbro-counters-wrapper">
    <div v-for="counter in counters" v-bind:key="counter.title" class="counter">
      <div class="epilogue-subtitle text-capitalize">
        {{ counter.title }}
      </div>
      <h3 class="epilogue-title-l white">
        {{ counter.number }}
      </h3>
      <p class="epilogue-smaller">
        {{ counter.description }}
      </p>
    </div>
  </div>
</template>
<script>
import { ref, onMounted } from "vue";
import { useStore } from "vuex";
import useCampaign from "@/composables/useCampaign";

export default {
  name: "AirBroCounters",
  setup() {
    const store = useStore();
    const { getCampaignStats } = useCampaign(store);
    const counters = ref([
      {
        title: "Bros",
        description: "Total number of all participants using AirBro",
        icon: require("@/Common/Icons/participantIcon.svg"),
        number: 412,
      },
      {
        title: "Reach",
        description: "Number of all followers from all participants",
        icon: require("@/Common/Icons/audienceIcon.svg"),
        number: 43714,
      },
    ]);

    onMounted(async () => {
      const response = await getCampaignStats();
      if (response) {
        counters.value[0].number = response.broSum;
        counters.value[1].number = response.reachSum;
      }
    });

    return {
      counters,
    };
  },
};
</script>
<style lang="scss" scoped src="@/Common/Styles/airBroCounters.scss"></style>
