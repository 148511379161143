<template>
  <div>
    <div class="add-requirements-wrapper">
      <p>ACCOUNT CREATED BEFORE</p>
    </div>
    <div class="twitter-account-before-wrapper">
      <div class="twitter-account-before-input-wrapper">
        <app-input-date-picker
          name="AccountDateCreation"
          placeholder="Choose a Date..."
          v-model="accountDateCreation"
          :maxDate="new Date()"
          position="left"
          :label="true"
          labelClass="label"
          labelText="Account Created Before"
          format="YYYY-MM-DD HH:mm"
          dark
          @update:modelValue="changeAccountDateCreation(accountDateCreation)"
        />
      </div>
      <div class="twitter-account-before-explanation-text folowers">
        <div>
          <img
            src="@/Common/Icons/infoIcon.svg"
            alt="image-regular-twitter-pfp"
          />
        </div>
        <div class="twitter-account-before-text">
          <p>
            Pick a date before which Twitter Account had to be created in order
            to Join.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref } from "vue";
import { useStore } from "vuex";
import AppInputDatePicker from "@/elements/AppInputDatePicker";
import useAditionalRequirements from "@/composables/useAditionalRequirements";
export default {
  props: {
    componentName: {
      type: String,
      default: "",
    },
  },
  components: {
    AppInputDatePicker,
  },
  setup() {
    const store = useStore();
    const { changeAccountDateCreation } = useAditionalRequirements(store);
    const accountDateCreation = ref("");

    return {
      accountDateCreation,
      changeAccountDateCreation,
    };
  },
};
</script>
//
<style
  lang="scss"
  scoped
  src="@/Common/Styles/twitterAccountBefore.scss"
></style>
