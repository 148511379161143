<template>
  <section class="participant-cta">
    <div class="airbro-grafitti">
      <img
        src="@/Common/Icons/HomePage/AirBroGrafitti.svg"
        alt="AirBro Grafitti"
      />
    </div>
    <div class="white-gradient"></div>
    <div class="custom-container">
      <h2 class="epilogue-title-l">Participate</h2>
      <p class="epilogue-subtitle">
        Anyone with a Twitter account and Wallet can join the campaign.
      </p>
      <section class="info-cards">
        <div v-for="(card, index) in cards" class="info-card" :key="index">
          <div class="line"></div>
          <div class="body">
            <div class="number epilogue-subtitle-alt">
              {{ card.number }}
            </div>
            <div class="title epilogue-large">
              {{ card.title }}
            </div>
            <div class="description epilogue-text">
              {{ card.description }}
            </div>
          </div>
        </div>
      </section>
      <app-button
        text="JOIN CAMPAIGN"
        buttonClass="primary-button-black"
        type="button"
        @click="openLink('/#campaigns-list')"
      />
    </div>
  </section>
</template>
<script>
import { onMounted, ref } from "vue";
import AppButton from "@/elements/AppButton";

export default {
  name: "ParticipantCta",
  components: {
    AppButton,
  },

  setup() {
    const cards = ref([
      {
        number: "01",
        title: "Join",
        description:
          "Check out many campaigns AirBro got in store for you and find those you vibe with the most",
      },
      {
        number: "02",
        title: "Connect",
        description:
          "Become an important member of the community and establish yourself as one of the early adopters",
      },
      {
        number: "03",
        title: "Earn",
        description:
          "Utilize your PFP and get rewarded for your loyal contribution to the community",
      },
    ]);

    const openLink = (url) => {
      window.open(url);
    };

    onMounted(async () => {});
    return {
      cards,
      openLink,
    };
  },
};
</script>
<style lang="scss" scoped src="@/Common/Styles/airBroHome.scss"></style>
