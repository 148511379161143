<template>
  <emptyTableState v-if="isCampaignData" />
  <div class="table-filter-wrap" v-else>
    <!-- <div class="campaigns-filter-search-wrap">
      <div class="campaigns-search">
        <app-input
          v-model="searchString"
          @keydown.enter.stop="searchFilter"
          inputClass="form-box-input"
          placeholder="Search Campaigns"
        />
        <img
          alt="search"
          class="campaigns-search-icon"
          src="@/Common/Icons/search_icon.svg"
        />
        <img
          alt="delete"
          v-show="searchString !== ''"
          @click="resetSearchString()"
          class="campaigns-search-cross"
          src="@/Common/Icons/search_cross.svg"
        />
      </div>
      <div class="campaigns-filter">
        <div class="filter-count">{{ filterCount }} filters selected</div>
        <div class="filter-wrap pointer" @click="toggleShowFilters()">
          Filter
          <img
            alt="filter"
            class="campaigns-filter-icon"
            src="@/Common/Icons/filter-icon.svg"
          />
        </div>
        <div class="filtering-options" v-if="showFilters">
          <teleport to="Body">
            <div
              class="filter-overlay"
              v-if="showFilters"
              @click="toggleShowFilters()"
            ></div>
          </teleport>
          <div class="filtering-option pfp-type">
            <p>Social Media</p>
            <ul>
              <li
                :class="{ active: filterOption.socialNetwork === 'twitter' }"
                @click="setFilter('socialNetwork', 'twitter')"
              >
                Twitter
              </li>
              <li
                :class="{ active: filterOption.socialNetwork === 'instagram' }"
                @click="setFilter('socialNetwork', 'instagram')"
              >
                Instagram
              </li>
            </ul>
          </div>
          <div class="filtering-option pfp-type">
            <p>PFP Type</p>
            <ul>
              <li
                :class="{ active: filterOption.pfpType === 'image' }"
                @click="setFilter('pfpType', 'image')"
              >
                Regular Image
              </li>
              <li
                :class="{ active: filterOption.pfpType === 'nft' }"
                @click="setFilter('pfpType', 'nft')"
              >
                NFT
              </li>
            </ul>
          </div>
          <div class="filtering-option reward">
            <p>Reward</p>
            <ul>
              <li
                :class="{ active: filterOption.reward === 'nft' }"
                @click="setFilter('reward', 'nft')"
              >
                NFT
              </li>
              <li
                :class="{ active: filterOption.reward === 'token' }"
                @click="setFilter('reward', 'token')"
              >
                Token
              </li>
            </ul>
          </div>
          <div class="filtering-option status">
            <p>Status</p>
            <ul>
              <li
                :class="{ active: filterOption.joined === 'true' }"
                @click="setFilter('joined', 'true')"
              >
                Joined
              </li>
              <li
                v-if="status !== 'pending'"
                :class="{ active: filterOption.claimed === 'false' }"
                @click="setFilter('claimed', 'false')"
              >
                Claim
              </li>
              <li
                v-if="status !== 'pending'"
                :class="{ active: filterOption.claimed === 'true' }"
                @click="setFilter('claimed', 'true')"
              >
                Claimed
              </li>
            </ul>
          </div>
          <div class="filtering-action">
            <div class="clear-filter" @click="setFilter()">Clear</div>
          </div>
        </div>
      </div>
    </div> -->

    <v-server-table
      :url="tableUrl"
      :columns="columns"
      :options="options"
      ref="campaignsTable"
      @row-click="openSingleCampaign"
    >
      <template v-slot:pfp="{ row }">
        <div class="image">
          <div class="campaign-image-wrapper" v-if="row.proofType === 'post'">
            <div v-if="row.type === 'nft'" class="hexagon wrapper">
              <video
                controls
                autoplay
                muted
                width="180"
                height="180"
                class="hexagon reward"
                v-if="checkFileMimeType(row.awardMimeType, 'video')"
                :src="getImgUrl(row.awardPath)"
              ></video>
              <div
                class="hexagon reward audio-wrap"
                v-if="checkFileMimeType(row.awardMimeType, 'audio')"
              >
                <audio
                  autoplay
                  muted
                  controls
                  preload="auto"
                  :src="getImgUrl(row.awardPath)"
                ></audio>
              </div>
              <img
                class="hexagon reward"
                v-if="checkFileMimeType(row.awardMimeType, 'image')"
                :src="getImgUrl(row.awardPath)"
                alt="NFT Reward"
              />
            </div>
            <div v-else-if="row.type === 'token'" class="circle wrapper">
              <img
                class="circle reward"
                :src="getTokenImgUrl(row.awardName)"
                alt="Token Reward"
              />
            </div>
            <img
              class="campaign-reward-icon"
              src="@/Common/Icons/HomePage/rewardIcon.svg"
              alt="Campaign Reward"
            />
          </div>
          <div
            v-else
            class="campaign-pfp"
            :class="{
              hexagon: row.proofType === 'nft',
              circle: row.proofType === 'image',
            }"
          >
            <img :src="getImgUrl(row.awardingCollImagePath)" />
          </div>
        </div>
      </template>
      <template v-slot:campaigns="{ row }">
        <div
          class="campaign-title epilogue-subtitle-alt"
          :data-campaign="row.id"
        >
          {{ row.name }}
        </div>
        <div class="campaign-description">
          {{ row.description }}
        </div>
      </template>
      <template v-slot:date="{ row }">
        <div v-if="row.status === 'pending'" class="epilogue-text-dark">
          <timer-mobile :time="row.startDate" class="timer" />
          <div>{{ formatDate(row.startDate) }}</div>
        </div>
        <div v-if="row.status === 'active'" class="epilogue-text-dark">
          <timer-mobile :time="row.endDate" class="timer" />
          <div>{{ formatDate(row.endDate) }}</div>
        </div>
        <div v-if="row.status === 'ended'" class="epilogue-text-dark">
          <p class="ended-text">Completed</p>
          <div>{{ formatDate(row.endDate) }}</div>
        </div>
      </template>
      <template v-slot:reward="{ row }">
        <div class="reward epilogue-text-dark">
          {{ row.type.toUpperCase() }}
        </div>
      </template>
      <template v-slot:bros="{ row }">
        <div class="bro-count epilogue-text-dark">
          {{ row.applicantsCount }}
        </div>
      </template>
      <template v-slot:reach="{ row }">
        <div class="reach-count epilogue-text-dark">
          <template v-if="row.applicantsCount > 0">
            {{ row.audienceCount }}
          </template>
          <template v-else> 0 </template>
        </div>
      </template>
      <template v-slot:flag="{ row }">
        <div class="flag-wrapper epilogue-text-dark">
          <div
            :class="row.avaliableFlag"
            class="join"
            v-if="row.avaliableFlag === 'info' && row.status === 'active'"
          >
            Join
          </div>
          <div :class="row.avaliableFlag" v-else>{{ row.avaliableFlag }}</div>
        </div>
      </template>
    </v-server-table>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { eventBus } from "@/main";
import useAuth from "@/composables/useAuth";
import { useRoute, useRouter } from "vue-router";
import useCampaign from "@/composables/useCampaign";
import useUtilities from "@/composables/useUtilities";
import { nextTick, computed, ref, onMounted } from "vue";
import EmptyTableState from "@/components/EmptyTableState";
import TimerMobile from "@/components/TimerMobile.vue";

export default {
  components: {
    TimerMobile,
    EmptyTableState,
  },
  props: {
    status: {
      type: String,
      default: "pending",
    },
  },
  setup(props) {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const campaignInfo = ref({});
    const isCampaignData = ref(false);
    const filterCount = ref(0);
    const searchString = ref("");
    const showFilters = ref(false);
    const campaignsTable = ref(null);
    const { isLoggedIn } = useAuth(store);
    const { formatDate, checkFileMimeType } = useUtilities();

    const { getCampaignByIdLogged, getCampaignById } = useCampaign(store);
    const filterOption = ref({
      socialNetwork: "",
      pfpType: "",
      reward: "",
      joined: "",
      claimed: "",
    });

    const columns = [
      "pfp",
      "campaigns",
      "date",
      "reward",
      "bros",
      "reach",
      "flag",
    ];

    const tableUrl = computed(() => {
      if (isLoggedIn.value) {
        return "/campaigns/loggedin";
      }
      return "/campaigns";
    });

    const getTokenImgUrl = (tokenName) => {
      switch (tokenName) {
        case "USDC":
          return require(`@/Common/Icons/Tokens/usdcToken.svg`);
        case "USDT":
          return require(`@/Common/Icons/Tokens/usdtToken.svg`);
        case "MATIC":
          return require(`@/Common/Icons/Tokens/polygonToken.svg`);
        case "BNB":
          return require(`@/Common/Icons/Tokens/bnbToken.svg`);
        case "DAI":
          return require(`@/Common/Icons/Tokens/daiToken.svg`);
        default:
          return require(`@/Common/Icons/Tokens/polygonToken.svg`);
      }
    };

    const options = ref({
      params: {},
      rowClassCallback: function (row) {
        return "campaign-id-" + row.id;
      },
      requestAdapter(data) {
        const params = {
          page: data.page,
          status: props.status,
          order: "start_first",
        };
        if (filterOption.value.socialNetwork)
          params.social_network = filterOption.value.socialNetwork;
        if (filterOption.value.pfpType)
          params.proof = filterOption.value.pfpType;
        if (filterOption.value.reward)
          params.reward = filterOption.value.reward;
        if (filterOption.value.claimed)
          params.claimed = filterOption.value.claimed;
        if (filterOption.value.joined)
          params.joined = filterOption.value.joined;

        if (data.search) {
          params.search = data.search;
        }

        return params;
      },
      responseAdapter({ data }) {
        data.campagins.length
          ? (isCampaignData.value = false)
          : (isCampaignData.value = true);
        return {
          data: data.campagins,
          count: data.total,
        };
      },
      filterable: false,
      headings: {
        pfp: "PFP",
        campaigns: "Campaigns",
        reward: "Reward",
        date: "Status",
        bros: "Bros",
        reach: "Reach",
        flag: "",
      },
      columnsClasses: {
        pfp: "pfp-head",
        participants: "pfp-participants",
      },
      sortable: [""],
      pagination: {
        virtual: false,
        chunk: 5,
      },
    });

    const getImgUrl = (imagePath) => {
      return process.env.VUE_APP_IMAGE_URL + imagePath;
    };
    //
    const openSingleCampaign = async (row) => {
      await store.dispatch("campaign/commitByKey", {
        campaignId: row.row.id,
      });
      localStorage.setItem("campaignId", row.row.id);
      router.push({ path: `/campaign/${row.row.id}` });
    };

    const toggleShowFilters = () => {
      showFilters.value = !showFilters.value;
    };
    const resetSearchString = () => {
      searchString.value = "";
    };

    const setFilter = (filter, value) => {
      if (!filter && !value) {
        for (const [key] of Object.entries(filterOption.value)) {
          filterOption.value[key] = "";
        }
        return [(filterCount.value = 0), campaignsTable.value.refresh()];
      }
      if (filterOption.value[filter] === value) {
        filterCount.value--;
        return [
          (filterOption.value[filter] = ""),
          campaignsTable.value.refresh(),
        ];
      }
      if (filterOption.value[filter] === "") {
        filterCount.value++;
      }
      filterOption.value[filter] = value;
      campaignsTable.value.refresh();
    };
    const searchFilter = () => {
      options.value.params.search = searchString.value;
      campaignsTable.value.refresh();
    };

    const changeIconDepensOnCamapign = (socialMediaIcon) => {
      switch (socialMediaIcon) {
        case "twitter":
          return require(`@/Common/Icons/twitterTableIcon.svg`);
        case "instagram":
          return require(`@/Common/Icons/instagramTableIcon.svg`);
      }
    };
    const changeIconDepensOnCamapignHover = (socialMediaIcon) => {
      switch (socialMediaIcon) {
        case "twitter":
          return require(`@/Common/Icons/twitterTableIconHover.svg`);
        case "instagram":
          return require(`@/Common/Icons/instagramTableIconHover.svg`);
      }
    };
    const changeIconDepensOnCamapignPFPImage = (socialMediaIcon) => {
      switch (socialMediaIcon) {
        case "twitter":
          return require(`@/Common/Icons/twitterCampaignLogo.svg`);
        case "instagram":
          return require(`@/Common/Icons/instagramProfileImage.svg`);
      }
    };
    const changeIconDepensOnCamapignPFPImageHover = (socialMediaIcon) => {
      switch (socialMediaIcon) {
        case "twitter":
          return require(`@/Common/Icons/twitterCampaignLogoHover.svg`);
        case "instagram":
          return require(`@/Common/Icons/instagramProfileImage.svg`);
      }
    };
    const changeCamapignPFPImage = (campaignType) => {
      switch (campaignType) {
        case "image":
          return require(`@/Common/Icons/regularPfpImage.svg`);
        case "nft":
          return require(`@/Common/Icons/nftPfpImage.svg`);
        case "post":
          return require(`@/Common/Icons/postContentImage.svg`);
      }
    };
    const changeCamapignPFPImageHover = (campaignType) => {
      switch (campaignType) {
        case "image":
          return require(`@/Common/Icons/regularPfpImageHover.svg`);
        case "nft":
          return require(`@/Common/Icons/nftPfpImageHover.svg`);
        case "post":
          return require(`@/Common/Icons/postContentImageHover.svg`);
      }
    };
    eventBus.on("resetCampaignsInfo", async (data) => {
      campaignInfo.value = data;
      await nextTick();
      campaignsTable.value.refresh();
    });

    eventBus.on("resetCampaignsClaim", async (data) => {
      campaignInfo.value = data;
      await nextTick();
      campaignsTable.value.refresh();
    });

    onMounted(async () => {
      if (route.query.campaign) {
        await store.dispatch("campaign/commitByKey", {
          campaignId: route.query.campaign,
        });
        if (isLoggedIn.value) {
          const response = await getCampaignByIdLogged();
          campaignInfo.value = response.data;
          await store.dispatch("campaign/commitByKey", {
            campaignFlag: campaignInfo.value.avaliableFlag,
          });
        }
        if (!isLoggedIn.value) {
          const response = await getCampaignById();
          campaignInfo.value = response.data;
          await store.dispatch("campaign/commitByKey", {
            campaignFlag: campaignInfo.value.avaliableFlag,
          });
        }
        router.replace({ query: null });
        await nextTick();
      }
    });
    return {
      columns,
      options,
      tableUrl,
      getImgUrl,
      setFilter,
      showFilters,
      formatDate,
      filterCount,
      campaignInfo,
      filterOption,
      searchFilter,
      searchString,
      campaignsTable,
      getTokenImgUrl,
      isCampaignData,
      checkFileMimeType,
      resetSearchString,
      toggleShowFilters,
      changeCamapignPFPImage,
      changeIconDepensOnCamapign,
      changeCamapignPFPImageHover,
      changeIconDepensOnCamapignHover,
      changeIconDepensOnCamapignPFPImage,
      changeIconDepensOnCamapignPFPImageHover,
      openSingleCampaign,
    };
  },
};
</script>
<style lang="scss" scoped src="@/Common/Styles/campaignTable.scss"></style>
