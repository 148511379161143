<template>
  <div>
    <div class="add-requirements-wrapper">
      <p>NFT OWNERSHIP</p>
    </div>
    <div class="ownership-nft-wrapper">
      <div class="ownership-nft-explanation-text">
        <div class="ownership-nft-text">
          <p>
            Define NFT Collection Address of an NFT that your participants have
            to hold in their wallets in order to join and participate in the
            campaign.
          </p>
        </div>
      </div>
      <div class="ownership-nft-input-wrapper">
        <app-input
          type="text"
          v-model="ownershipNftAddress"
          name="OwnershipNftAddress"
          inputClass="form-box-input"
          labelClass="label"
          labelText="NFT Address"
          placeholder="Enter or Paste Collection Address..."
          @change="changeOwnershipNftAddress(ownershipNftAddress)"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { ref } from "vue";
import { useStore } from "vuex";
import AppInput from "@/elements/AppInput";
import useAditionalRequirements from "@/composables/useAditionalRequirements";
export default {
  props: {
    componentName: {
      type: String,
      default: "",
    },
  },
  components: {
    AppInput,
  },
  setup() {
    const store = useStore();
    const { changeOwnershipNftAddress } = useAditionalRequirements(store);
    const ownershipNftAddress = ref("");

    return {
      ownershipNftAddress,
      changeOwnershipNftAddress,
    };
  },
};
</script>
//
<style lang="scss" scoped src="@/Common/Styles/proofOfOwnwership.scss"></style>
