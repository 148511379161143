<template>
  <div
    class="action-to-join-req"
    :class="{
      disable:
        !twHandle ||
        !twitterFolowersComaparation ||
        singleCamapignData.status === 'ended',
    }"
  >
    <div class="action-to-join-headline">
      <div v-if="singleCamapignData.avaliableFlag === 'joined'">
        <div class="campaign-joined">
          <p>3. Campaign Joined</p>
          <img src="@/Common/Icons/doneIcon.gif" alt="done" />
        </div>
      </div>
      <div class="action-title" v-else>
        <div>
          <div class="campaign-joined" v-if="socialProfileData.imageUrl">
            <p>3. Verified</p>
            <img src="@/Common/Icons/doneIcon.gif" alt="done" />
          </div>
          <div
            class="campaign-joined"
            v-if="socialProfileData.statusCode === 400"
          >
            <p>3. Verification failed</p>
            <img src="@/Common/Icons/notDoneIcon.svg" alt="Not done" />
          </div>
        </div>
        <div
          class="verifying-image"
          v-if="!socialProfileData.imageUrl && !socialProfileData.statusCode"
        >
          <p>3. Change Profile Picture</p>
          <img
            v-if="isLoader"
            src="@/Common/Icons/loaderThreeDots.gif"
            alt="done"
          />
        </div>
      </div>
      <span v-if="singleCamapignData.avaliableFlag === 'joined'"
        >Hold this PFP to be eligible to claim reward</span
      >
      <span v-else
        >Download, Replace and Verify your new Twitter PFP to join</span
      >
    </div>
    <div class="download-pfp-requirements">
      <div class="req-image-wrapper">
        <div class="headline-wrapper">
          <div>
            <div class="download-pfp-headline status">
              <img src="@/Common/Icons/statusIcon.svg" alt="ConditionsIcon" />
              <div>
                <span>Status</span>
                <div class="require-condition">
                  <p
                    v-if="
                      !isDownloadAction &&
                      !socialProfileData.imageUrl &&
                      singleCamapignData.avaliableFlag !== 'joined'
                    "
                  >
                    Download
                  </p>
                  <p
                    v-if="
                      isDownloadAction &&
                      !socialProfileData.imageUrl &&
                      singleCamapignData.avaliableFlag !== 'joined'
                    "
                  >
                    Verify
                  </p>
                  <p
                    v-if="
                      socialProfileData.imageUrl &&
                      singleCamapignData.avaliableFlag !== 'joined'
                    "
                  >
                    Join
                  </p>
                  <p v-if="singleCamapignData.avaliableFlag === 'joined'">
                    Joined
                  </p>
                  <p v-if="singleCamapignData.avaliableFlag === 'claim'">
                    Claim
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div class="download-pfp-headline duration">
              <img
                src="@/Common/Icons/changePfpIcon.svg"
                alt="ConditionsIcon"
              />
              <div>
                <span>Change PFP for</span>
                <div class="require-condition">
                  <p>
                    <span class="hold-duration">{{
                      singleCamapignData.holdDuration
                    }}</span>
                    days
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="holding-period">
          <span
            v-if="
              !socialProfileData.imageUrl &&
              singleCamapignData.avaliableFlag !== 'joined'
            "
            >Hold until TBD</span
          >
          <span v-else class="hold-until"
            >Hold Until - {{ dateUntilHold() }}</span
          >
        </div>
      </div>
      <div class="download-pfp-image-wrapper">
        <img
          v-if="singleCamapignData.awardingCollImagePath"
          :src="getImgUrl(singleCamapignData.awardingCollImagePath)"
          alt="requirementImage"
        />
      </div>
    </div>

    <div
      class="download-pfp-button-wrapper"
      v-if="singleCamapignData.avaliableFlag !== 'joined'"
    >
      <app-button
        text="DOWNLOAD PFP"
        buttonClass="small-button action-type-download"
        @click.prevent="downloadImage(singleCamapignData.awardingCollImagePath)"
        :disabled="
          !twHandle ||
          !twitterFolowersComaparation ||
          singleCamapignData.status === 'ended'
        "
      />
      <app-button
        :text="buttonText"
        class="small-button"
        @click.prevent="verifySocilaNetworkReq"
        :disabled="
          !twHandle ||
          !twitterFolowersComaparation ||
          singleCamapignData.status === 'ended'
        "
      />
    </div>
  </div>
</template>
<script>
import { ref, computed, onMounted } from "vue";
import moment from "moment";
import { useStore } from "vuex";
import AppButton from "@/elements/AppButton";
import useAuth from "@/composables/useAuth";
import useCampaign from "@/composables/useCampaign";

export default {
  components: {
    AppButton,
  },
  setup() {
    const store = useStore();
    const { twHandle, twFollowersCount } = useAuth(store);
    const { singleCamapignData, getSocialProfileData, socialProfileData } =
      useCampaign(store);

    const isDownloadAction = ref(false);
    const isLoader = ref(false);
    const buttonText = ref("VERIFY PFP");

    const verifySocilaNetworkReq = async () => {
      store.dispatch("campaign/commitByKey", {
        socialProfileData: {},
      });
      store.dispatch("campaign/commitByKey", {
        errors: [],
      });
      isLoader.value = true;
      buttonText.value = "VERIFYING...";
      const data = {
        postContent: singleCamapignData.value.sharingPost,
        handle: twHandle.value,
        socialNetwork: singleCamapignData.value.socialNetwork,
        campaignId: singleCamapignData.value.id,
      };
      await getSocialProfileData(data);

      await store.getters["campaign/getByKey"]("socialProfileData");
      isLoader.value = false;
      buttonText.value = "VERIFY PFP";
    };
    const getImgUrl = (imagePath) => {
      return process.env.VUE_APP_IMAGE_URL + imagePath;
    };
    const downloadImage = (image) => {
      fetch(getImgUrl(image))
        .then((resp) => resp.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.style.display = "none";
          a.href = url;
          a.download = image;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
        })
        .catch(() => console.log("error"));
      isDownloadAction.value = true;
    };
    const dateUntilHold = () => {
      let today = new Date();
      return moment(today)
        .add(singleCamapignData.value.holdDuration, "days")
        .format("MMM Do, HH:mm");
    };
    const twitterFolowersComaparation = computed(() => {
      return twFollowersCount.value >= singleCamapignData.value.profileFollowers
        ? true
        : false;
    });
    onMounted(() => {
      store.dispatch("campaign/commitByKey", {
        socialProfileData: {},
      });
      store.dispatch("campaign/commitByKey", {
        errors: [],
      });
    });
    return {
      twHandle,
      isLoader,
      getImgUrl,
      buttonText,
      dateUntilHold,
      downloadImage,
      isDownloadAction,
      socialProfileData,
      singleCamapignData,
      verifySocilaNetworkReq,
      twitterFolowersComaparation,
    };
  },
};
</script>
<style
  lang="scss"
  scoped
  src="@/Common/Styles/actionTypeRequirements.scss"
></style>
