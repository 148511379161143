import api from "../plugins/apiWrapper";

export default {
  // USERS
  async login(publicAddress) {
    return api.post("/auth/login", { walletAddress: publicAddress });
  },

  async logout() {
    return api.post("/auth/logout");
  },

  async fetchMe() {
    return api.get("/users/me");
  },

  async updateUser(userData) {
    return api.patch("/users", userData);
  },

  async updateUserTokens(tokensData) {
    return api.patch("/users/tokens", tokensData);
  },

  async fetchNonce(publicAddress) {
    return api.get(`/auth/getnonce/${publicAddress}`);
  },

  async verifyNonce(signature, nonce, publicAddress, signerApp) {
    return api.post("/auth/verify", {
      signature,
      nonce,
      walletAddress: publicAddress,
      signerApp,
    });
  },

  async subscribe(data) {
    return api.post("/subscribers", data);
  },
  async signUpCreator(data) {
    return api.post("/users/creator-signup", data);
  },
  async pendingCreators() {
    return api.get("/users/pending-creators");
  },
  async updateCreators(data) {
    return api.post("/users/update-creators", data);
  },
  // CAMPAIGNS
  async getAllCampaigns(params) {
    return api.get("campaigns", { params });
  },
  async getCampaignsLoggedIn(params) {
    return api.get("campaigns/loggedin", { params });
  },
  async getCampaignById(id) {
    return api.get(`campaigns/details/${id}`);
  },
  async getCampaignByIdLogged(id) {
    return api.get(`campaigns/loggedin/details/${id}`);
  },
  async getApplicantsByCampaignId(id, params) {
    return api.get(`campaigns/applicants/${id}`, { params });
  },
  async applyToTwitterCampaign(data) {
    return api.post("campaigns/manual-apply/", data);
  },
  async applyToInstagramCampaign(data) {
    return api.post("campaigns/manual-apply/instagram", data);
  },
  async getCampaignWinners(id) {
    return api.get(`campaigns/winners/${id}`);
  },
  async sendCampaignData(data) {
    return api.post("/campaigns", data);
  },
  async generateNftVideo(data) {
    return api.post("/campaigns/generate-nft-video", data);
  },
  async sendCampaignDataImages(id, formData) {
    return api.patch(`/campaigns/${id}/images`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  async getCampaignStats() {
    return api.get("campaigns/stats");
  },
  async getMerkleProof(id) {
    return api.get(`merkle-tree/${id}/proof`);
  },
  async checkSocialProfile(data) {
    return api.post("/social-profile", data);
  },
  //CLAIM REWARD
  async getClaimableCamapigns() {
    return api.get("/air-drop/claimable");
  },
  async getClaimableCount() {
    return api.get("/air-drop/claimable-count");
  },
  async claimGaslessReward(id) {
    return api.post(`/campaigns/gasless-claim/${id}`);
  },
};
