import api from "@/services/api";
import Moralis from "@/plugins/moralis";

const initialState = () => ({
  errors: [],
  buttonDisabled: false,
  isGaslessClaim: "false",
  componentKey: 0,
  campaignData: {},
  txHashBackend: "",
  campaignId: "",
  campaignFlag: "",
  tokenType: "nft",
  campaignName: "",
  componentTableName: "allCampaigns",
  modalMessage: "",
  modalMessageClass: "",
  campaignStartDate: "",
  campaignEndDate: "",
  campaignDescription: "",
  campaignStats: {},
  numberOfPrticipatns: "",
  maxNumberOfWinners: "",
  campaignType: "image",
  twitterAccRequirement: "",
  alreadyJoinedCampaign: false,
  campaignRequirementsComponentName: "",
  campaignRewardComponentName: "MintAirBro",
  singleCamapignData: {},
  reqCollectionNftMetadata: {},
  nftsForContractForWallet: [],
  socialProfileData: {},
  singleCampaignApplicantPageData: [],
  response: {},
});
const state = initialState();

const getters = {
  getByKey: (state) => (key) => state[key],
  getIsGaslessClaim: (state) =>
    state.isGaslessClaim === "false" ? false : true,
};

const mutations = {
  commitByKey(state, object) {
    Object.keys(object).forEach((key) => (state[key] = object[key]));
  },
  setCamapignData(state, campaignData) {
    state.campaignData = campaignData;
  },
  setSingleCamapignData(state, singleCamapignData) {
    state.singleCamapignData = singleCamapignData;
  },
  setCampaignStats(state, campaignStats) {
    state.campaignStats = campaignStats;
  },
  setReqCollectionNftMetadata(state, reqCollectionNftMetadata) {
    state.reqCollectionNftMetadata = reqCollectionNftMetadata;
  },
  setNftsForContractForWallet(state, nftsForContractForWallet) {
    state.nftsForContractForWallet = nftsForContractForWallet;
  },
  setSocialProfileData(state, socialProfileData) {
    state.socialProfileData = socialProfileData;
  },
  setResponse(state, response) {
    state.response = response;
  },
  setsingleCampaignApplicantPageData(state, singleCampaignApplicantPageData) {
    state.singleCampaignApplicantPageData = singleCampaignApplicantPageData;
  },
  resetState(state) {
    Object.assign(state, initialState());
  },
  pushError(state, error) {
    state.errors.push(error);
  },
};

const actions = {
  async applyToTwitterCampaign({ commit }, data) {
    try {
      return await api.applyToTwitterCampaign(data);
    } catch (error) {
      commit("pushError", error);
      throw error.response.data.message;
    }
  },

  async applyToInstagramCampaign({ commit }, data) {
    try {
      return await api.applyToInstagramCampaign(data);
    } catch (error) {
      commit("pushError", error);
      throw error.response.data.message;
    }
  },
  async getCampaignStats({ commit }) {
    try {
      const response = await api.getCampaignStats();
      commit("setCampaignStats", response.data);
      return response.data;
    } catch (error) {
      commit("pushError", error);
    }
  },
  async getCampaignById({ commit }, id) {
    try {
      const response = await api.getCampaignById(id);
      commit("setSingleCamapignData", response.data);
      return response.data;
    } catch (error) {
      commit("pushError", error);
    }
  },

  async sendCampaignData({ commit }, data) {
    try {
      const response = await api.sendCampaignData(data);
      commit("setCamapignData", response.data);
      return response.data;
    } catch (error) {
      commit("pushError", error);
    }
  },
  async getCampaignByIdLogged({ commit }, id) {
    try {
      const response = await api.getCampaignByIdLogged(id);
      commit("setSingleCamapignData", response.data);
      return response.data;
    } catch (error) {
      commit("pushError", error);
    }
  },
  async getApplicantsByCampaignId({ commit }, { id, params }) {
    try {
      const response = await api.getApplicantsByCampaignId(id, params);
      commit("setsingleCampaignApplicantPageData", response.data);
      return response.data;
    } catch (error) {
      commit("pushError", error);
    }
  },
  async getCampaignWinners({ commit }, id) {
    try {
      return await api.getCampaignWinners(id);
    } catch (error) {
      commit("pushError", error);
    }
  },
  async sendCampaignDataImages({ commit }, { id, formData }) {
    try {
      const response = await api.sendCampaignDataImages(id, formData);
      return response;
    } catch (error) {
      commit("pushError", error);
    }
  },
  async fetchNftMetadataReqCollection({ commit }, options) {
    try {
      const response = await Moralis.Web3API.token.getNFTMetadata(options);
      commit("setReqCollectionNftMetadata", response);
      return response;
    } catch (error) {
      commit("pushError", error);
    }
  },
  async fetchNFTsForContract({ commit }, options) {
    try {
      const response = await Moralis.Web3API.account.getNFTsForContract(
        options
      );
      commit("setNftsForContractForWallet", response.result);
    } catch (error) {
      commit("pushError", error);
    }
  },
  async getSocialProfileData({ commit }, data) {
    try {
      const response = await api.checkSocialProfile(data);
      commit("setSocialProfileData", response.data);
      commit("setResponse", response);
      return response.data;
    } catch (error) {
      commit("setSocialProfileData", error.response.data);
    }
  },
  resetState({ commit }) {
    commit("resetState");
  },
  commitByKey({ commit }, object) {
    commit("commitByKey", object);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
