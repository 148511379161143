<template>
  <section class="faq-section">
    <div class="faq-bg-image"></div>
    <div class="custom-container">
      <section class="question-accordion">
        <div
          v-for="(card, index) in questions"
          class="question"
          :class="[{ active: card.active }]"
          :key="index"
          @click="toggleAccordion(index)"
        >
          <div class="line"></div>
          <div class="line-full"></div>
          <div class="body">
            <div class="header">
              <div class="title epilogue-large">
                {{ card.title }}
              </div>
              <img
                v-if="!card.active"
                src="@/Common/Icons/HomePage/downArrow.svg"
                alt="Down Arrow"
                class="icon"
              />
              <img
                v-else
                src="@/Common/Icons/HomePage/upArrow.svg"
                alt="Up Arrow"
                class="icon"
              />
            </div>
            <div v-if="card.active" class="description epilogue-text-dark">
              {{ card.description }}
            </div>
          </div>
        </div>
      </section>
    </div>
  </section>
</template>
<script>
import { onMounted, ref } from "vue";

export default {
  name: "Faq",
  components: {},

  setup() {
    const prevIndex = ref();
    const toggleAccordion = (index) => {
      if (!isNaN(prevIndex.value) && prevIndex.value !== index)
        questions.value[prevIndex.value].active = false;
      questions.value[index].active = !questions.value[index].active;
      prevIndex.value = index;
    };

    const questions = ref([
      {
        title: "What is AirBro?",
        description:
          "AirBro is a Web3 marketing tool that empowers Web3 projects to maximize their reach and engagement through creative and impactful campaigns. By utilizing Twitter profile pictures or specific tweet posts as advertising space, AirBro gives Web3 projects the ability to showcase their brand through the avatars of their community. Participants can not only support relevant projects but also benefit from joining the campaigns and earn rewards. With AirBro, Web3 projects can not only engage their existing audience but also reach new users and expand their brand presence in the Web3 world.",
        active: false,
      },
      {
        title: "Types of campaigns available on AirBro?",
        description:
          "AirBro provides two types of campaigns: PFP campaign and Tweet campaign. The PFP (Profile Picture) campaign allows Web3 projects to advertise on users' profile pictures, while the Tweet campaign enables advertising on specific tweet posts.",
        active: false,
      },
      {
        title: "Types of the reward AirBro provides to campaign participants?",
        description:
          "By creating campaigns on AirBro, Web3 projects have the ability to reward their most loyal members with NFTs and ERC20 tokens. This incentivizes participation and helps to build a strong community around the project",
        active: false,
      },
      {
        title: "What blockchain is AirBro built on?",
        description:
          "AirBro is built on the Polygon blockchain, a fast and scalable Ethereum layer 2 solution.",
        active: false,
      },
      {
        title: "Who can create campaigns on AirBro?",
        description:
          "AirBro is an open platform, and everyone is welcome to create and join campaigns. Whether you're a Web3 project looking to promote your brand or a participant looking to support relevant projects and earn rewards, AirBro provides a seamless and accessible experience for all.",
        active: false,
      },
      {
        title: "What are campaign proofs?",
        description:
          "At AirBro, we prioritize transparency for all campaigns. To ensure that everyone can have confidence in the results of our campaigns, we offer campaign proofs in the form of daily scanning results in one easy-to-use CSV file",
        active: false,
      },
    ]);

    onMounted(async () => {});
    return {
      questions,
      toggleAccordion,
    };
  },
};
</script>
<style lang="scss" scoped src="@/Common/Styles/airBroHome.scss"></style>
