import Moralis from "@/plugins/moralis";
import { computed, ref, watch } from "vue";

const useMintAirBro = (store) => {
  const rewardNameValue = ref("");
  const symbolNameValue = ref("");
  const numberOfCopiesValue = ref("");
  const localDescriptionValue = ref("");
  const localSelectedFileValue = ref({});
  const isSoulboundNftLocal = ref("false");
  const videoClassActiveLocal = ref(false);

  const txHashTokenId = computed(() =>
    store.getters["mintAirBro/getByKey"]("txHashTokenId")
  );
  const totalNftAirdropAmount = computed(() =>
    store.getters["mintAirBro/getByKey"]("totalAirdropAmount")
  );
  const tokensPerClaimNFTs = computed(() =>
    store.getters["mintAirBro/getByKey"]("tokensPerClaim")
  );
  const isTransactionFinished = computed(() =>
    store.getters["mintAirBro/getByKey"]("isTransactionFinished")
  );
  const collectionName = computed(() =>
    store.getters["mintAirBro/getByKey"]("collectionName")
  );
  const symbolName = computed(() =>
    store.getters["mintAirBro/getByKey"]("symbolName")
  );
  const numberOfCopies = computed(() =>
    store.getters["mintAirBro/getByKey"]("numberOfCopies")
  );
  const localDescription = computed(() =>
    store.getters["mintAirBro/getByKey"]("localDescription")
  );
  const localSelectedFile = computed(() =>
    store.getters["mintAirBro/getByKey"]("localSelectedFile")
  );
  const isSoulboundNft = computed(
    () => store.getters["mintAirBro/getIsSoulboundNft"]
  );
  const rewardName = computed(() =>
    store.getters["mintAirBro/getByKey"]("rewardName")
  );
  const fileIPFSMetadata = computed(() =>
    store.getters["mintAirBro/getByKey"]("fileIPFSMetadata")
  );
  const fileIPFSImagePath = computed(() =>
    store.getters["mintAirBro/getByKey"]("fileIPFSImagePath")
  );
  const generateNftVideoName = computed(() =>
    store.getters["mintAirBro/getByKey"]("generateNftVideoName")
  );
  const videoClassActive = computed(() =>
    store.getters["mintAirBro/getByKey"]("videoClassActive")
  );

  const cidValue = computed(() => store.getters["mintAirBro/getByKey"]("cid"));

  const uploadImage = (data) => store.dispatch("mintAirBro/uploadImage", data);

  const uploadImageCloud = (data) =>
    store.dispatch("mintAirBro/uploadImageCloud", data);

  const generateNftVideo = (data) =>
    store.dispatch("mintAirBro/generateNftVideo", data);

  const uploadMetadata = (metadata) =>
    store.dispatch("mintAirBro/uploadMetadata", metadata);

  const uploadMetadataCloud = (metadata) =>
    store.dispatch("mintAirBro/uploadMetadataCloud", metadata);

  const mintNfts = (cid, amount) =>
    store.dispatch("mintAirBro/mintNfts", { cid, amount });

  const storeToIPFS = async (value) => {
    try {
      await Moralis.authenticate({ signingMessage: "AirBro" });
    } catch (error) {
      store.dispatch("campaign/commitByKey", {
        buttonDisabled: false,
      });
    }
    const image = await uploadImage(value);
    const metadata = {
      name: rewardName.value,
      decription: localDescription.value,
      image: "ipfs://" + image,
    };
    const cid = await uploadMetadata(metadata);
    store.dispatch("mintAirBro/commitByKey", {
      cid: cid,
    });
    return cid;
  };

  const storeToIPFSWalletConnect = async (value) => {
    const image = await uploadImageCloud(value);
    const metadata = {
      name: rewardName.value,
      decription: localDescription.value,
      image: image,
    };
    const cid = await uploadMetadataCloud(metadata);
    store.dispatch("mintAirBro/commitByKey", {
      cid: cid,
    });
    return cid;
  };

  const changeRewardName = () => {
    store.dispatch("mintAirBro/commitByKey", {
      rewardName: rewardNameValue.value,
    });
  };
  const changeRewardSymbol = () => {
    store.dispatch("mintAirBro/commitByKey", {
      symbolName: symbolNameValue.value,
    });
  };
  const changeNumberOfCopies = () => {
    store.dispatch("mintAirBro/commitByKey", {
      numberOfCopies: numberOfCopiesValue.value,
    });
  };
  const changeLocalDescription = () => {
    store.dispatch("mintAirBro/commitByKey", {
      localDescription: localDescriptionValue.value,
    });
  };

  const changeAirdropDuration = (duration) => {
    store.dispatch("mintAirBro/commitByKey", {
      airdropDuration: duration,
    });
  };

  watch(localSelectedFileValue, (newValue) => {
    if (newValue) {
      store.dispatch("mintAirBro/commitByKey", {
        localSelectedFile: newValue,
      });
    }
  });
  watch(isSoulboundNftLocal, (newValue) => {
    if (newValue) {
      store.dispatch("mintAirBro/commitByKey", {
        isSoulboundNft: isSoulboundNftLocal.value,
      });
    }
  });
  watch(isSoulboundNft, (newValue) => {
    if (!newValue) {
      isSoulboundNftLocal.value = "false";
    }
  });

  const generateVideoNftToggle = async () => {
    videoClassActiveLocal.value = !videoClassActiveLocal.value;
    store.dispatch("mintAirBro/commitByKey", {
      videoClassActive: videoClassActiveLocal.value,
    });
  };

  return {
    mintNfts,
    cidValue,
    rewardName,
    symbolName,
    storeToIPFS,
    uploadImage,
    txHashTokenId,
    isSoulboundNft,
    uploadMetadata,
    collectionName,
    numberOfCopies,
    rewardNameValue,
    symbolNameValue,
    videoClassActive,
    fileIPFSMetadata,
    changeRewardName,
    changeRewardSymbol,
    generateNftVideo,
    uploadImageCloud,
    localDescription,
    fileIPFSImagePath,
    localSelectedFile,
    tokensPerClaimNFTs,
    isSoulboundNftLocal,
    numberOfCopiesValue,
    uploadMetadataCloud,
    generateNftVideoName,
    changeNumberOfCopies,
    videoClassActiveLocal,
    changeAirdropDuration,
    totalNftAirdropAmount,
    localDescriptionValue,
    isTransactionFinished,
    localSelectedFileValue,
    changeLocalDescription,
    generateVideoNftToggle,
    storeToIPFSWalletConnect,
  };
};

export default useMintAirBro;
