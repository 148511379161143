import * as Yup from "yup";

export const existingTokenSchema = Yup.object().shape({
  AmountToDistribute: Yup.number("Must be a number")
    .required("Amount is required")
    .integer("Should not have decimals")
    .typeError("Must be a number")
    .nullable()
    .positive("Must be greater than 0"),
  TokenToDistribute: Yup.string().required("Token select is required"),
});
