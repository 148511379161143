<template>
  <div class="connected-button-wrapper">
    <div :class="[{ active: isClicked }, { noBorder: isClicked }, 'address-balance-wrapper']" @click="toggleIsClicked">
      <div class="address" :class="[{ noBottomBorder: isClicked }]">
        <div class="avatar-wrap">
          <img src="@/Common/Icons/polygonIconReskin.svg" alt="polygonIcon" class="avatar-icon" />
          <img src="@/Common/Icons/polygonBlack.svg" alt="polygonIcon" class="avatar-icon mobile" />
          <div class="wallet-address">{{ reduceAddress(4, 5) }}</div>
        </div>
        <div class="arrow-drop">
          <img src="@/Common/Icons/arrowDropDown.svg" alt="disconnectIcon" class="disconnect-icon" v-if="!isClicked" />
          <img src="@/Common/Icons/arrowDropUp.svg" alt="disconnectIcon" class="disconnect-icon" v-else />
          <img src="@/Common/Icons/triangleBlack.svg" alt="disconnectIcon" class="disconnect-icon mobile" />
        </div>
      </div>
    </div>
    <div class="disconnect-overlay" v-if="isClicked">
      <teleport to="body">
        <div class="connected-options-overlay" @click="toggleIsClicked" v-if="isClicked"></div>
      </teleport>
      <div class="connected-options">
        <div class="connected-options-values create-page">
          <p @click="$router.push('/create-twitter-campaign')">+ Create Campaign</p>
        </div>
        <div class="connected-options-values disconnect">
          <p class="disconnect" @click="disconnect(true)">Disconnect Wallet</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { onMounted, ref } from "vue";
import { ethereumService } from "@/main";
import useAuth from "@/composables/useAuth";
import useUtilities from "@/composables/useUtilities";

export default {
  setup() {
    const store = useStore();
    const { address, balance, disconnect } = useAuth(store, ethereumService);
    const { capitalizeFirstLetter } = useUtilities();
    const isClicked = ref(false);

    const reduceAddress = (lengthLeft, lengthRight) => {
      if (address.value) {
        const addressLeftPart = address.value.substring(0, lengthLeft);
        const addressRightPart = address.value.substring(42 - lengthRight, 42);
        return `${addressLeftPart}...${addressRightPart}`;
      }
      return "Not connected";
    };
    const toggleIsClicked = () => {
      isClicked.value = !isClicked.value;
    };

    onMounted(async () => {
      await store.dispatch("claiming/resetState");
      await store.dispatch("users/fetchBalance", address.value);
    });
    return {
      address,
      balance,
      isClicked,
      disconnect,
      reduceAddress,
      toggleIsClicked,
      capitalizeFirstLetter,
    };
  },
};
</script>
<style lang="scss" scoped src="@/Common/Styles/userAddressAndBalance.scss"></style>
