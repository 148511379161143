<template>
  <div class="app-input">
    <label :for="id" :class="labelClass">{{ labelText }} </label>
    <textarea
      class="textarea"
      v-bind="$attrs"
      v-model="inputValue"
      @input="$emit('update:modelValue', $event.target.value)"
      :id="id"
      :name="name"
      :placeholder="placeholder"
      :disabled="disabled"
      :class="[textAreaClass, errorMessage ? 'error-message-border' : '']"
      :style="textAreaStyle"
    >
    </textarea>
    <p class="validation-error-message" v-show="errorMessage || meta.valid">
      {{ errorMessage || successMessage }}
    </p>
  </div>
</template>

<script>
import { eventBus } from "@/main";
import { toRefs, watch } from "vue";
import { useField } from "vee-validate";

export default {
  name: "AppTextarea",
  props: {
    id: {
      type: String,
    },
    name: {
      type: String,
    },
    successMessage: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    labelText: {
      type: String,
      default: "",
    },
    modelValue: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    labelClass: {
      type: String,
      default: "",
    },
    textAreaClass: {
      type: String,
      default: "",
    },
    textAreaStyle: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const {
      value: inputValue,
      errorMessage,
      meta,
      resetField,
    } = useField(props.name, undefined, {
      initialValue: props.modelValue,
    });

    eventBus.on("resetErrors", () => {
      resetField();
    });
    let { modelValue } = toRefs(props);
    watch(
      () => modelValue,
      (modelValue) => {
        inputValue.value = modelValue.value;
      },
      { deep: true }
    );
    return {
      errorMessage,
      inputValue,
      meta,
    };
  },
};
</script>
