<template>
  <div
    class="preview-image"
    @dragenter.prevent=""
    @dragover.prevent=""
    @drop="dropImage"
  >
    <div class="inner" :class="{ audioImage: flagForImageTag == 'audio' }">
      <input
        :id="id"
        :name="name"
        v-bind="$attrs"
        type="file"
        @change="onFileChange"
        ref="inputRef"
        :accept="accept"
        :nft="nft"
        :pfp="pfp"
        :nftPfp="nftPfp"
        hidden
      />
      <label :for="id">
        <div v-if="!url" class="file-image-wrap">
          <div
            @click="onPickFile"
            class="drag-and-drop-text"
            :class="{ nftReward: nft }"
          >
            <img
              src="@/Common/Icons/downloadIconReskin.svg"
              alt="downloadIcon"
              class="download-icon"
            />
            <div v-if="pfp" class="text-span">
              <span class="PFP-span">Upload image</span>
              <span class="PFP-span">500x500</span>
              <span class="PFP-span">at least</span>
            </div>
            <div v-if="nft" class="nft-span">
              <span class="nft">Upload NFT</span>
              <span class="nft">Image</span>
            </div>
            <div v-if="nftPfp" class="nft-pfp">
              <span class="nft-pfp-span">Upload image</span>
              <span class="nft-pfp-span">500x500</span>
              <span class="nft-pfp-span">at least</span>
            </div>
          </div>
        </div>
      </label>
      <div v-if="url">
        <span class="clear-image" @click="clearFile">X</span>
        <img v-if="flagForImageTag == 'image'" class="image" :src="url" />
        <video
          v-if="flagForImageTag == 'video'"
          controls
          autoplay
          muted
          width="200"
          height="190"
        >
          <source :src="url" />
        </video>
        <audio
          v-if="flagForImageTag == 'audio'"
          autoplay
          muted
          controls
          preload="auto"
          class="file-audio"
        >
          <source :src="url" />
        </audio>
      </div>
    </div>
  </div>
  <div class="error-message-wrapper">
    <p v-show="errorMessage" class="input-error-message-file">
      {{ errorMessage }}
    </p>
  </div>
</template>
<script>
import { ref, watch } from "vue";
import { useField } from "vee-validate";
import { eventBus } from "../main";
export default {
  name: "FileInput",
  props: {
    id: {
      type: String,
    },
    name: {
      type: String,
      default: "",
    },
    modelValue: {
      type: Object,
      default: () => {},
    },
    accept: {
      type: String,
      default: "",
    },
    pfp: {
      type: Boolean,
      default: false,
    },
    nft: {
      type: Boolean,
      default: false,
    },
    nftPfp: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:modelValue"],
  setup(props, context) {
    const url = ref("");
    const fileImage = ref([]);
    const fileObject = ref({});
    const flagForImageTag = ref("");
    const inputRef = ref("dropFile");
    const { value, errorMessage, meta, setErrors } = useField(
      props.name,
      undefined,
      {
        initialValue: props.modelValue,
      }
    );
    watch(meta, (meta) => {
      if (!meta.valid && !meta.dirty) {
        setErrors("");
      }
    });
    const onFileChange = () => {
      const file = inputRef.value.files;
      for (let i = 0; i < file.length; i++) {
        value.value = file[i];
        fileObject.value = file[i];
        fileImage.value.push(file[i].type);
      }
      url.value = URL.createObjectURL(file[0]);
      context.emit("update:modelValue", fileObject.value);
      for (let i = 0; i < fileImage.value.length; i++) {
        if (fileImage.value[i].includes("video")) {
          flagForImageTag.value = "video";
        } else if (fileImage.value[i].includes("image")) {
          flagForImageTag.value = "image";
        } else if (fileImage.value[i].includes("audio")) {
          flagForImageTag.value = "audio";
        }
      }
    };
    const dropImage = (e) => {
      e.preventDefault();
      inputRef.value.files = e.dataTransfer.files;
      if (fileImage.value !== []) {
        fileImage.value = [];
      }
      onFileChange();
    };
    const onPickFile = () => {
      inputRef.value.click();
    };
    const clearFile = () => {
      // Clear actual input filed so
      // same file can be added twice in a row
      inputRef.value ? (inputRef.value.value = null) : null;
      url.value = "";
      fileImage.value = [];
      context.emit("update:modelValue", {});
      flagForImageTag.value = "";
      fileObject.value = {};
      value.value = {};
      errorMessage._value = undefined;
    };
    eventBus.on(`clearFile:${props.name}`, clearFile);
    return {
      url,
      fileImage,
      fileObject,
      flagForImageTag,
      inputRef,
      onFileChange,
      dropImage,
      onPickFile,
      clearFile,
      errorMessage,
      value,
      meta,
    };
  },
};
</script>
<style lang="scss" scoped src="@/Common/Styles/appFileInput.scss"></style>
