import api from "@/services/api";
import { ethereumService } from "@/main";

const initialState = () => ({
  id: "",
  utm: "",
  errors: [],
  address: "",
  walletAddressInput: "",
  balance: {},
  network: {},
  networkName: "",
  isLoggedIn: false,
  userEmail: "",
  hasSigned: false,
  userNft: [],
  twName: "",
  twEmailVerified: false,
  twHandle: "",
  twPhotoUrl: "",
  twCreatedAt: "",
  twFollowersCount: "",
  inUsername: "",
  signerAppWallet: "",
  linkToContent: "",
  role: "",
});
const state = initialState();

const getters = {
  getByKey: (state) => (key) => state[key],
};

const mutations = {
  pushError(state, error) {
    state.errors.push(error);
  },
  commitByKey(state, object) {
    Object.keys(object).forEach((key) => (state[key] = object[key]));
  },
  clearErrors(state) {
    state.errors.splice(0);
  },
  setAddress(state, address) {
    state.address = address;
  },
  setBalance(state, balance) {
    state.balance = balance;
  },
  setNetwork(state, network) {
    state.network = network;
  },
  setNetworkName(state, networkName) {
    state.networkName = networkName;
  },
  setRole(state, role) {
    state.role = role;
  },
  resetState(state) {
    Object.assign(state, initialState());
  },
};

const actions = {
  async fetchAddress({ commit }) {
    try {
      const address = await ethereumService.fetchAddress();
      commit("setAddress", address);
      return address;
    } catch (error) {
      commit("pushError", error);
    }
  },
  async fetchBalance({ commit }, balanceAddress) {
    try {
      const balance = await ethereumService.fetchBalance(balanceAddress);
      commit("setBalance", balance);
      return balance;
    } catch (error) {
      commit("pushError", error);
    }
  },
  async fetchNonce({ commit }, address) {
    try {
      const result = await api.fetchNonce(address);
      return result.data;
    } catch (error) {
      if (
        error.response.data.message === "User with this address doesn't exist."
      ) {
        return null;
      }
      commit("pushError", error);
    }
  },
  async signNonce({ commit }, nonce) {
    try {
      return await ethereumService.signNonce(nonce);
    } catch (error) {
      if ("code" in error && error.code === 4001) {
        return "rejected";
      }
      commit("pushError", error);
    }
  },

  async verifyNonce(
    { commit },
    { signature, nonce, publicAddress, signerApp }
  ) {
    try {
      const result = await api.verifyNonce(
        signature,
        nonce,
        publicAddress,
        signerApp
      );
      commit("commitByKey", {
        id: result.data.id,
      });
      return result;
    } catch (error) {
      commit("pushError", error);
    }
  },

  async login({ commit }, publicAddress) {
    try {
      const result = await api.login(publicAddress);
      return result.data;
    } catch (error) {
      commit("pushError", error);
    }
  },

  async fetchMe({ commit }) {
    try {
      const response = await api.fetchMe();
      commit("setRole", response.data.role);
      return response;
    } catch (error) {
      commit("pushError", error);
      throw new Error("Can't fetch user");
    }
  },

  async logout({ commit }) {
    try {
      return await api.logout();
    } catch (error) {
      commit("pushError", error);
    }
  },

  async updateUser({ commit }, userData) {
    try {
      return await api.updateUser(userData);
    } catch (error) {
      commit("pushError", error);
      throw new Error(error.response.status);
    }
  },
  async updateUserTokens({ commit }, tokenArray) {
    try {
      return await api.updateUserTokens({ tokensArray: tokenArray });
    } catch (error) {
      commit("pushError", error);
    }
  },

  async fetchNetwork({ commit }) {
    try {
      const result = await ethereumService.getNetwork();
      commit("setNetwork", result);
    } catch (error) {
      commit("pushError", error);
    }
  },
  async resetState({ commit }) {
    commit("resetState");
  },

  async fetchNetworkName({ commit }) {
    try {
      const result = await ethereumService.getNetworkName();
      commit("setNetworkName", result);
    } catch (error) {
      commit("pushError", error);
    }
  },
  async userSubscribe({ commit }, email) {
    try {
      const result = await api.subscribe({ email });
      return result;
    } catch (error) {
      commit("pushError", error);
    }
  },
  commitByKey({ commit }, object) {
    commit("commitByKey", object);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
