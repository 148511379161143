<template>
  <div class="campaign-page-info">
    <section class="header">
      <div>
        <a
          :href="
            'https://polygonscan.com/address/' +
            currentCampaignData.campaignCreatorAddress
          "
          class="creator"
        >
          <div class="profile-pic"></div>
          <span>
            {{
              condenseAddress(currentCampaignData.campaignCreatorAddress, 5, 4)
            }}
          </span>
        </a>
      </div>
      <div class="share">
        <ShareNetwork
          @click="trackTwitterShare()"
          v-for="network in networks"
          :network="network.network"
          :key="network.network"
          :url="twitterShare.url"
          :title="twitterShare.title"
          :description="twitterShare.description"
          :quote="twitterShare.quote"
          :hashtags="twitterShare.hashtags"
        >
          <div class="outline-button">
            <img
              src="@/Common/Icons/twitterLogoGray.svg"
              alt="Twitter Share"
              class="twitter-logo"
            />
            <span class="epilogue-small">Share</span>
          </div>
        </ShareNetwork>
        <div @click="copyLink(campaignUrl)" class="outline-button">
          <img
            src="@/Common/Icons/CampaignSinglePage/clipboardIcon.svg"
            alt="Clipboard Icon"
            class="clipboard-icon"
          />
          <div class="link-copied" v-if="linkCopied">
            <p>Link Copied</p>
          </div>
        </div>
      </div>
    </section>
    <div class="divider"></div>
    <section class="body">
      <h1 class="epilogue-title">
        {{ currentCampaignData.name }}
      </h1>
      <campaign-reward-card :campaignData="currentCampaignData" />
      <campaign-action-card :campaignData="currentCampaignData" />
      <campaign-countdown-card :campaignData="currentCampaignData" />
      <section class="details">
        <h2 class="epilogue-subtitle-alt">Campaign Details</h2>
        <p class="epilogue-text">
          {{ currentCampaignData.description }}
        </p>
      </section>
      <div class="divider"></div>
      <campaign-participants-card :campaignData="currentCampaignData" />
    </section>
  </div>
</template>
<script>
import { ref, onMounted, watch, computed } from "vue";
import useUtilities from "@/composables/useUtilities";
import CampaignRewardCard from "@/components/SingleCampaignPage/CampaignRewardCard";
import CampaignActionCard from "@/components/SingleCampaignPage/CampaignActionCard";
import CampaignCountdownCard from "@/components/SingleCampaignPage/CampaignCountdownCard";
import CampaignParticipantsCard from "@/components/SingleCampaignPage/CampaignParticipantsCard";

export default {
  components: {
    CampaignRewardCard,
    CampaignActionCard,
    CampaignCountdownCard,
    CampaignParticipantsCard,
  },
  props: {
    campaignData: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const { copyToClipboard } = useUtilities();

    const currentCampaignData = ref(props.campaignData);
    const linkCopied = ref(false);

    const copyLink = (val) => {
      copyToClipboard(val);
      linkCopied.value = true;
      setTimeout(() => {
        linkCopied.value = false;
      }, 2000);
    };

    const condenseAddress = (address, lengthLeft, lengthRight) => {
      if (address) {
        const addressLeftPart = address.substring(0, lengthLeft);
        const addressRightPart = address.substring(42 - lengthRight, 42);
        return `${addressLeftPart}...${addressRightPart}`;
      }
    };

    const campaignUrl = computed(() => {
      return (
        window.location.origin + "/campaign/" + currentCampaignData.value.id
      );
    });

    const trackTwitterShare = () => {
      if (process.env.VUE_APP_ENV !== "production") return;
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "campaign_shared_twitter",
      });
    };

    const twitterShare = ref({
      title: "Check out this campaign on AirBro: ",
      description: "",
      url: campaignUrl,
      quote: "",
      hashtags: ["airbro"],
      twitterUser: "",
    });

    const networks = ref([
      {
        network: "twitter",
        name: "Twitter",
        icon: require(`@/Common/Icons/twitterShare.svg`),
        shareIcon: require(`@/Common/Icons/twitterShare.svg`),
        shareIconHover: require(`@/Common/Icons/twitterShareHover.svg`),
      },
    ]);

    watch(
      () => props.campaignData,
      (newVal, oldVal) => {
        oldVal;
        currentCampaignData.value = newVal;
      }
    );

    onMounted(async () => {});
    return {
      props,
      networks,
      copyLink,
      linkCopied,
      campaignUrl,
      twitterShare,
      condenseAddress,
      copyToClipboard,
      trackTwitterShare,
      currentCampaignData,
    };
  },
};
</script>
<style lang="scss" scoped src="@/Common/Styles/campaignPageInfo.scss"></style>
