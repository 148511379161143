<template>
  <div class="active-campaings-wrapper" id="campaigns-list">
    <div class="custom-container">
      <div class="campaigns-table-wrapper">
        <div class="tab-campaigns-buttons-wrap">
          <app-button
            :text="width > 900 ? 'ALL CAMPAIGNS' : 'ALL'"
            class="campaign-tab-button"
            :class="{ active: componentName === 'allCampaigns' }"
            @click="changeComponent('allCampaigns')"
          />
          <app-button
            text="ACTIVE"
            class="campaign-tab-button"
            :class="{ active: componentName === 'activeCampaigns' }"
            @click="changeComponent('activeCampaigns')"
          />
          <app-button
            text="UPCOMING"
            class="campaign-tab-button"
            :class="{ active: componentName === 'upcomingCampaigns' }"
            @click="changeComponent('upcomingCampaigns')"
          />
          <app-button
            text="CLOSED"
            class="campaign-tab-button"
            :class="{ active: componentName === 'closedCampaigns' }"
            @click="changeComponent('closedCampaigns')"
          />
        </div>

        <template v-if="width >= 1350">
          <campaigns-table
            v-if="componentName === 'allCampaigns'"
            :campaignData="data"
            :status="'pending,active,ended'"
          />
          <campaigns-table
            v-if="componentName === 'upcomingCampaigns'"
            :campaignData="data"
            :status="'pending'"
          />
          <campaigns-table
            v-if="componentName === 'activeCampaigns'"
            :campaignData="data"
            :status="'active'"
          />
          <campaigns-table
            v-if="componentName === 'closedCampaigns'"
            :campaignData="data"
            :status="'ended'"
          />
        </template>
        <template v-else>
          <campaign-cards-list
            v-if="componentName === 'allCampaigns'"
            :status="'pending,active,ended'"
          />
          <campaign-cards-list
            v-if="componentName === 'upcomingCampaigns'"
            :status="'pending'"
          />
          <campaign-cards-list
            v-if="componentName === 'activeCampaigns'"
            :status="'active'"
          />
          <campaign-cards-list
            v-if="componentName === 'closedCampaigns'"
            :status="'ended'"
          />
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import { onMounted, computed, onUnmounted, ref } from "vue";
import { useStore } from "vuex";
import AppButton from "@/elements/AppButton";
import useCampaign from "@/composables/useCampaign";
import CampaignsTable from "@/components/CampaignsTable";
import CampaignCardsList from "@/components/CampaignCardsList";

export default {
  components: {
    AppButton,
    CampaignsTable,
    CampaignCardsList,
  },
  setup() {
    const store = useStore();
    const { componentName } = useCampaign(store);
    const changeComponent = (name) => {
      store.dispatch("campaign/commitByKey", {
        componentTableName: name,
      });
    };

    const onWidthChange = () => (windowWidth.value = window.innerWidth);

    const width = computed(() => windowWidth.value);

    let windowWidth = ref(window.innerWidth);

    onMounted(async () => {
      store.dispatch("campaign/commitByKey", {
        componentTableName: "allCampaigns",
      });
      window.addEventListener("resize", onWidthChange);
    });

    onUnmounted(() => window.removeEventListener("resize", onWidthChange));
    return {
      width,
      componentName,
      changeComponent,
    };
  },
};
</script>
<style lang="scss" scoped src="@/Common/Styles/activeCampaigns.scss"></style>
