import * as Yup from "yup";

const SUPPORTED_FORMATS = [
  "image/jpg",
  "image/jpeg",
  "image/gif",
  "image/png",
  "image/gif",
  "video/mp4",
  "video/webm",
  "video/mpeg",
  "audio/mp3",
  "audio/webm",
  "audio/mpeg",
  "audio/wav",
  "application/pdf",
];
const MAX_FILE_SIZE = 20971520;

export const mintAirBroSchema = Yup.object().shape({
  RewardName: Yup.string().required("Collection Name is required"),
  RewardSymbol: Yup.string().required("Collection Symbol is required"),
  Description: Yup.string(),
  SelectedFile: Yup.mixed()
    .nullable()
    .required()
    .test(
      "FILE_SIZE",
      "The File Size should be less then 20MB",
      (value) => value.size <= MAX_FILE_SIZE
    )
    .test(
      "FILE_FORMAT",
      "Unsupported file, try again",
      (value) => value && SUPPORTED_FORMATS.includes(value.type)
    ),
});
