<template>
  <div>
    <div class="add-requirements-wrapper">
      <p>UTM LINK</p>
    </div>
    <div class="utm-link-wrapper">
      <p class="utm-text">
        Create UTM link for tracking activity of your campaign.
      </p>
      <div class="row">
        <div class="col-md-12">
          <app-input
            type="text"
            v-model="websiteURL"
            name="WebsiteUrl"
            labelClass="label"
            labelText="Website URL"
            inputClass="form-box-input"
            placeholder="eg. https://example.com"
            @change="changeUtmWebsiteURL(websiteURL)"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <app-input
            type="text"
            v-model="utmCampaignID"
            name="UtmCampaignId"
            labelClass="label"
            labelText="Campaign ID"
            inputClass="form-box-input"
            placeholder="Enter Your Campaign ID"
            @change="changeUtmCampaignID(utmCampaignID)"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <app-input
            type="text"
            v-model="utmCampaignSource"
            name="UtmCampaignSource"
            labelClass="label"
            labelText="Campaign Source"
            inputClass="form-box-input"
            placeholder="Enter your Campaign Source"
            @change="changeUtmCampaignSource(utmCampaignSource)"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <app-input
            type="text"
            v-model="utmCampaignMedium"
            name="UtmCampaignMedium"
            labelClass="label"
            labelText="Campaign Medium"
            inputClass="form-box-input"
            placeholder="Enter Your Campaign Medium"
            @change="changeUtmCampaignMedium(utmCampaignMedium)"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <app-input
            type="text"
            v-model="utmCampaignName"
            name="UtmCampaignName"
            labelClass="label"
            labelText="Campaign Name"
            inputClass="form-box-input"
            placeholder="Enter Campaign Name"
            @change="changeUtmCampaignName(utmCampaignName)"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <app-input
            type="text"
            v-model="utmCampaignTerm"
            name="UtmCampaignTerm"
            labelClass="label"
            labelText="Campaign Term"
            inputClass="form-box-input"
            placeholder="Enter Campaign Term"
            @change="changeUtmCampaignTerm(utmCampaignTerm)"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref } from "vue";
import { useStore } from "vuex";
import AppInput from "@/elements/AppInput";
import usePostContentRequirements from "@/composables/usePostContentRequirements";
export default {
  components: {
    AppInput,
  },
  setup() {
    const store = useStore();
    const {
      changeUtmWebsiteURL,
      changeUtmCampaignID,
      changeUtmCampaignSource,
      changeUtmCampaignMedium,
      changeUtmCampaignName,
      changeUtmCampaignTerm,
    } = usePostContentRequirements(store);
    const websiteURL = ref("");
    const utmCampaignID = ref("");
    const utmCampaignSource = ref("");
    const utmCampaignMedium = ref("");
    const utmCampaignName = ref("");
    const utmCampaignTerm = ref("");
    return {
      websiteURL,
      utmCampaignID,
      utmCampaignSource,
      utmCampaignMedium,
      utmCampaignName,
      utmCampaignTerm,
      changeUtmWebsiteURL,
      changeUtmCampaignID,
      changeUtmCampaignSource,
      changeUtmCampaignMedium,
      changeUtmCampaignName,
      changeUtmCampaignTerm,
    };
  },
};
</script>
<style lang="scss" scoped src="@/Common/Styles/utmLink.scss"></style>
