export const ADDRESSES = {
  137: {
    // MintingContractAddress: process.env.VUE_APP_MINTING_CONTRACT_ADDRESS,
    AirBroFactoryContractAddress:
      process.env.VUE_APP_AIR_BRO_CAMPAIGN_FACTORY_CONTRACT_ADDRESS,
    AirBroRegistryContractAddress:
      process.env.VUE_APP_AIR_BRO_REGISTRY_CONTRACT_ADDRESS,
  },
  80001: {
    // MintingContractAddress: process.env.VUE_APP_MINTING_CONTRACT_ADDRESS_MUMBAI,
    AirBroFactoryContractAddress:
      process.env.VUE_APP_AIR_BRO_CAMPAIGN_FACTORY_CONTRACT_ADDRESS_MUMBAI,
    AirBroRegistryContractAddress:
      process.env.VUE_APP_AIR_BRO_REGISTRY_CONTRACT_ADDRESS_MUMBAI,
    NewErc1155DropCampaign:
      process.env.VUE_APP_NEW_ERC1155_DROP_CAMPAIGN_ADDRESS_MUMBAI,
    NewSb1155DropCampaign:
      process.env.VUE_APP_NEW_SB1155_DROP_CAMPAIGN_ADDRESS_MUMBAI,
    ExistingErc20DropCampaign:
      process.env.VUE_APP_EXISTING_ERC20_DROP_CAMPAIGN_ADDRESS_MUMBAI,
  },
};
