<template>
  <section class="creator-cta">
    <div class="purple-gradient"></div>
    <div class="custom-container">
      <h2 class="epilogue-title-l">Start Building Community</h2>
      <p class="epilogue-subtitle gray-base">
        Anyone can create a campaign for anything they want to promote or spread
        awareness about.
      </p>
      <section class="info-cards">
        <div v-for="(card, index) in cards" class="info-card" :key="index">
          <div class="line"></div>
          <div class="body">
            <div class="number epilogue-subtitle-alt">
              {{ card.number }}
            </div>
            <div class="title epilogue-large">
              {{ card.title }}
            </div>
            <div class="description epilogue-text-dark">
              {{ card.description }}
            </div>
          </div>
        </div>
      </section>
      <app-button
        text="CREATE CAMPAIGN"
        buttonClass="primary-button-white"
        type="button"
        @click="openLink('/create-twitter-campaign')"
      />
    </div>
  </section>
</template>
<script>
import { onMounted, ref } from "vue";
import AppButton from "@/elements/AppButton";

export default {
  name: "CreatorCta",
  components: {
    AppButton,
  },

  setup() {
    const cards = ref([
      {
        number: "01",
        title: "Create",
        description:
          "Fuel Your Community's Growth with the Power of AirBro Campaigns",
      },
      {
        number: "02",
        title: "Establish",
        description:
          "Establish Your NFT Legacy with a Thriving Community of Supporters ",
      },
      {
        number: "03",
        title: "Explode",
        description:
          "Unleash the Power of PFP and Supercharge Your Community's Growth",
      },
    ]);

    const openLink = (url) => {
      window.open(url, "_blank");
    };

    onMounted(async () => {});
    return {
      cards,
      openLink,
    };
  },
};
</script>
<style lang="scss" scoped src="@/Common/Styles/airBroHome.scss"></style>
