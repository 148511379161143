<template>
  <div class="custom-pfp-wrapper">
    <div class="custom-pfp-file-input">
      <div class="file-input-wrapper">
        <app-file-input
          v-model="localSelectedFileValue"
          name="SelectedFilePfp"
          :pfp="true"
          accept="image/png,image/jpeg,image/gif,image/webp"
        />
      </div>
      <div class="custom-pfp-explanation-text">
        <div>
          <img
            src="@/Common/Icons/infoIcon.svg"
            alt="image-regular-twitter-pfp"
          />
        </div>
        <div class="custom-pfp-text">
          <p>
            Your participants will have to hold exactly the same image you
            define here as a regular profile picture on their Twitter account in
            order to receive a reward.
          </p>
        </div>
      </div>
    </div>
    <div class="custom-pfp-data-input-wrapper">
      <div class="number-of-days-input-wrapper">
        <app-input
          type="text"
          v-model="campaignDaysToHoldPFPCustom"
          name="CampaignDaysToHoldPFPCustom"
          inputClass="form-box-input"
          labelClass="label"
          labelText="Duration"
          placeholder="Enter number of days"
          @change="changeDaysToHoldPFPCustom(campaignDaysToHoldPFPCustom)"
        />
      </div>
      <div class="custom-pfp-explanation-text folowers">
        <div>
          <img
            src="@/Common/Icons/infoIcon.svg"
            alt="image-regular-twitter-pfp"
          />
        </div>
        <div class="custom-pfp-text">
          <p>
            Define how long participants will have to hold the image as their
            Profile Picture.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watch } from "vue";
import { useStore } from "vuex";
import AppInput from "@/elements/AppInput";
import AppFileInput from "@/elements/AppFileInput";
import useCustomPfpRequirements from "@/composables/useCustomPfpRequirements";
export default {
  components: {
    AppInput,
    AppFileInput,
  },
  setup() {
    const store = useStore();
    const { changeDaysToHoldPFPCustom } = useCustomPfpRequirements(store);

    const localSelectedFileValue = ref({});
    const numberOfFolowers = ref("");
    const accountDateCreation = ref("");
    const requirementNftAddress = ref("");
    const campaignDaysToHoldPFPCustom = ref("");

    watch(localSelectedFileValue, (newValue) => {
      if (newValue) {
        store.dispatch("customPfpRequirements/commitByKey", {
          selectedFileImage: newValue,
        });
      }
    });

    return {
      numberOfFolowers,
      accountDateCreation,
      requirementNftAddress,
      localSelectedFileValue,
      changeDaysToHoldPFPCustom,
      campaignDaysToHoldPFPCustom,
    };
  },
};
</script>
<style lang="scss" scoped src="@/Common/Styles/customPFP.scss"></style>
