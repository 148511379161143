<template>
  <div class="home-page-wrapper">
    <div class="custom-container">
      <div class="home-page">
        <div class="hero">
          <h1 class="betterbrush-title-xl">
            Grow
            <span class="white d-inline-block"> and </span>
            Reward
          </h1>
          <h2 class="epilogue-title-l">Web3 Communities</h2>
          <p class="epilogue-text-m mt-4">
            Creators can reward and engage their community while participants
            can earn rewards and recognition for their contributions. Together,
            we drive community growth and engagement to new heights.
          </p>
          <air-bro-counters />
        </div>
        <div class="hero-image">
          <div>
            <img src="@/Common/Icons/homePhone.svg" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AirBroCounters from "@/components/AirBroCounters.vue";

export default {
  components: {
    AirBroCounters,
  },
  setup() {
    const openCalendly = (url) => {
      window.open(url, "_blank");
    };
    return {
      openCalendly,
    };
  },
};
</script>
<style lang="scss" scoped src="@/Common/Styles/airBroDescription.scss"></style>
