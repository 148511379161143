import Moralis from "@/plugins/moralis";

const initialState = () => ({
  errors: [],
  nftList: [],
  nftListTotal: "",
  tableNftItems: [],
  rewardAddress: "",
  claimDuration: "",
  addresesToReward: [],
  giftCollectionName: "",
  giftCollectionSymbol: "",
  collectionAddressToSend: "",
  tableAddressesToRewardList: [],
  addresesToRewardTotalItems: [],
});
const state = initialState();

const getters = {
  getByKey: (state) => (key) => state[key],
  getErrors(state) {
    return state.errors;
  },
};

const mutations = {
  commitByKey(state, object) {
    Object.keys(object).forEach((key) => (state[key] = object[key]));
  },
  pushError(state, error) {
    state.errors.push(error);
  },
  clearErrors(state) {
    state.errors.splice(0);
  },
  setNftList(state, nftList) {
    state.nftList = nftList;
  },
  setGiftCollectionName(state, giftCollectionName) {
    state.giftCollectionName = giftCollectionName;
  },
  setGiftCollectionSymbol(state, giftCollectionSymbol) {
    state.giftCollectionSymbol = giftCollectionSymbol;
  },
  setNftListTotal(state, nftListTotal) {
    state.nftListTotal = nftListTotal;
  },
  setTableNftItems(state, tableNftItems) {
    state.tableNftItems.push(tableNftItems);
  },
  setAddressesToReward(state, addresesToReward) {
    state.addresesToReward.push(addresesToReward);
  },
  setTableAddressesToRewardList(state, tableAddressesToRewardList) {
    state.tableAddressesToRewardList.push(tableAddressesToRewardList);
  },
  setAddresesToRewardTotalItems(state, addresesToRewardTotalItems) {
    state.addresesToRewardTotalItems.push(addresesToRewardTotalItems);
  },
  setCollectionAddressToSend(state, collectionAddressToSend) {
    state.collectionAddressToSend = collectionAddressToSend;
  },
  resetState(state) {
    Object.assign(state, initialState());
  },
};

const actions = {
  commitByKey({ commit }, object) {
    commit("commitByKey", object);
  },
  resetState({ commit }) {
    commit("resetState");
  },
  async fetchNft({ commit }, config) {
    commit("setCollectionAddressToSend", config.collectionAddressToSend);
    try {
      const response = await Moralis.Web3API.account.getNFTs(config.options);
      commit("setNftList", response.result);

      const fixUrl = (url) => {
        if (url.startsWith("ipfs")) {
          return (
            "https://ipfs.moralis.io:2053/ipfs/" +
            url.split("ipfs://ipfs/").splice(-1)
          );
        } else {
          return url + "?format=json";
        }
      };
      const truncateString = (str, num) => {
        if (str.length > num) {
          return str.slice(0, num) + "...";
        } else {
          return str;
        }
      };
      let arrayOfClollectionaddres = response.result.filter(
        (e) => e.token_address === config.collectionAddressToSend
      );

      commit("setNftListTotal", arrayOfClollectionaddres.length);
      commit("setGiftCollectionName", arrayOfClollectionaddres[0].name);
      commit("setGiftCollectionSymbol", arrayOfClollectionaddres[0].symbol);

      arrayOfClollectionaddres.forEach((nft) => {
        let url = fixUrl(nft.token_uri);
        fetch(url)
          .then((res) => res.json())
          .then((data) => {
            commit("setTableNftItems", {
              Item_name: data.name,
              Collection_name: nft.name,
              NFT_ID: truncateString(nft.token_id, 5),
            });
          });
      });
      return response;
    } catch (error) {
      commit("pushError", error);
    }
  },
  async fetchAddresesToReward({ commit }, options) {
    try {
      const response = await Moralis.Web3API.token.getAllTokenIds(options);
      commit("setAddressesToReward", response.result[0].token_address);
      commit("setAddresesToRewardTotalItems", response.total);
      commit("setTableAddressesToRewardList", {
        Collection_Name: response.result[0].name,
        Collection_Address: response.result[0].token_address,
      });
      return response;
    } catch (error) {
      commit("pushError", error);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
