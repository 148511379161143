import { ethers } from "ethers";
import Web3Modal from "web3modal";
import { ADDRESSES } from "@/contracts/addresses.js";
import AVAILABLE_CHAINS from "@/contracts/availableChains.json";
import NETWORKS from "@/contracts/networks.json";
import erc20Abi from "../contracts/abis/erc20Abi.json";
import claimingAbi from "../contracts/claimingAbi.json";
import mintingAbi from "../contracts/abis/mintingAbi.json";
import newTokenDropAbi from "../contracts/abis/tokenDrop.json";
import airBroFactoryAbi from "../contracts/abis/airbroFactory.json";
import airdropRegistry from "../contracts/abis/airdropRegistry.json";
import existingTokenDropAbi from "../contracts/abis/existingTokenDrop.json";
import existing1155NftDropAbi from "../contracts/abis/existing1155NftDrop.json";
import airbroCampaignFactoryAbi from "../contracts/abis/airbroCampaignFactory.json";
import existingERC20DropCampaignAbi from "../contracts/abis/existingERC20DropCampaign.json";
import newERC1155DropCampaignAbi from "../contracts/abis/newERC1155DropCampaign.json";
import newSB1155DropCampaignAbi from "../contracts/abis/newSB1155DropCampaign.json";
import WalletConnectProvider from "@walletconnect/web3-provider";

const { VUE_APP_ENV } = process.env;

const env =
  VUE_APP_ENV === "dev"
    ? "dev"
    : VUE_APP_ENV === "staging"
    ? "staging"
    : "production";

// const CHAINS = {
//   1: "mainnet",
//   3: "ropsten",
//   137: "polygon",
//   80001: "polygon testnet",
// };

const CHAINS = AVAILABLE_CHAINS[env];
class EthereumService {
  constructor(chainId = 1) {
    this.signer = {};
    this.provider = {};
    this.chainId = chainId;
    this.erc20Abi = erc20Abi;
    this.networkProvider = {};
    this.providerOptions = {
      walletconnect: {
        package: WalletConnectProvider,
        options: {
          rpc: {
            137: process.env.VUE_APP_POLYGON_RPC,
          },
          infuraId: process.env.VUE_APP_INFURA_ID,
        },
      },
    };
    this.networkParams = NETWORKS[env];
    this.airDropContractAddress = "";
    this.mintingContractAddress = "";
    this.airDropRegistryContractAddress = "";
    this.mintingAbi = mintingAbi["abi"];
    this.claimingAbi = claimingAbi["abi"];
    this.newTokenDropAbi = newTokenDropAbi["abi"];
    this.airBroFactoryAbi = airBroFactoryAbi["abi"];
    this.airdropRegistryAbi = airdropRegistry["abi"];
    this.existingTokenAbi = existingTokenDropAbi["abi"];
    this.existingNftTokenAbi = existing1155NftDropAbi["abi"];
    this.airbroCampaignFactoryAbi = airbroCampaignFactoryAbi["abi"];
    this.existingERC20DropCampaignAbi = existingERC20DropCampaignAbi["abi"];
    this.newERC1155DropCampaignAbi = newERC1155DropCampaignAbi["abi"];
    this.newSB1155DropCampaignAbi = newSB1155DropCampaignAbi["abi"];
    this.web3Modal = new Web3Modal({
      cacheProvider: true,
      providerOptions: this.providerOptions,
      theme: "dark",
    });
  }
  parseChainId = (id) => {
    return CHAINS[id] || "Selected network is not supported.";
  };

  setContractData = async () => {
    const networkId = (await this.getNetwork()).chainId;
    if (networkId) {
      this.airDropContractAddress =
        ADDRESSES[networkId]["AirBroFactoryContractAddress"];
      this.airDropRegistryContractAddress =
        ADDRESSES[networkId]["AirBroRegistryContractAddress"];
      this.mintingContractAddress =
        ADDRESSES[networkId]["MintingContractAddress"];
    }
  };

  async getSigner() {
    this.networkProvider = await this.web3Modal.connect();
    this.provider = await new ethers.providers.Web3Provider(
      this.networkProvider
    );
    return await this.provider.getSigner();
  }

  async connectToProvider() {
    this.networkProvider = await this.web3Modal.connect();
    // if (!(await this.isValidNetwork())) {
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: env !== "dev" ? "0x89" : "0x13881" }],
      });
    } catch (error) {
      console.log("Selected network is not supported.");
      await this.addPolygonNetwork();
      await this.switchToPolygonNetwork();
    }
    // }
    return await this.setContractData();
  }
  async isValidNetwork() {
    const network = await this.getNetwork();
    return Boolean(CHAINS[network.chainId]);
  }
  async addPolygonNetwork() {
    return await window.ethereum.request({
      method: "wallet_addEthereumChain",
      params: [this.networkParams[env !== "dev" ? 137 : 80001]],
    });
  }

  async switchToPolygonNetwork() {
    try {
      return await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: env !== "dev" ? "0x89" : "0x13881" }],
      });
    } catch (error) {
      await this.addPolygonNetwork();
      await this.switchToPolygonNetwork();
    }
  }
  async checkCachedProvider() {
    if (this.web3Modal.cachedProvider) {
      this.networkProvider = await this.web3Modal.connect();
      this.provider = new ethers.providers.Web3Provider(this.networkProvider);
      this.signer = this.provider.getSigner();
      return this.networkProvider;
    }
    return (this.networkProvider = {});
  }

  async onChainChanged(handleChainChange) {
    await this.checkCachedProvider();

    if (Object.keys(this.networkProvider).length > 0) {
      this.networkProvider.on("chainChanged", async (info) => {
        await handleChainChange(this.parseChainId(parseInt(info, 16)));
        try {
          await window.ethereum.request({
            method: "wallet_switchEthereumChain",
            params: [{ chainId: env !== "dev" ? "0x89" : "0x13881" }],
          });
          return await this.setContractData();
        } catch (error) {
          console.log("Selected network is not supported.");
        }
      });
    }
  }

  async onAccountChanged(handleAccountChange) {
    await this.checkCachedProvider();
    if (Object.keys(this.networkProvider).length > 0) {
      this.networkProvider.on(
        "accountsChanged",
        async (accounts) => await handleAccountChange(accounts[0])
      );
    }
  }

  async isProviderConnected() {
    await this.checkCachedProvider();
    if (Object.keys(this.networkProvider).length > 0) {
      const accounts = await this.provider.listAccounts();
      return accounts.length > 0;
    }
    return false;
  }

  async getNetwork() {
    await this.checkCachedProvider();
    if (Object.keys(this.networkProvider).length > 0) {
      const provider = new ethers.providers.Web3Provider(this.networkProvider);
      return await provider.getNetwork();
    }
    return {};
  }

  async getNetworkName() {
    const network = await this.getNetwork();
    return CHAINS[network.chainId] || "Unsupported network";
  }

  async disconnectProvider() {
    await this.web3Modal.clearCachedProvider();
    location.reload();
  }

  async fetchAddress() {
    await this.checkCachedProvider();
    if (Object.keys(this.networkProvider).length > 0) {
      const accounts = await this.provider.listAccounts();
      return accounts[0];
    }
    throw new Error("No network provider found!");
  }

  async fetchBalance(address) {
    await this.checkCachedProvider();
    const balances = {};
    if (Object.keys(this.networkProvider).length > 0) {
      const balance = await this.provider.getBalance(address);
      balances["default"] = ethers.utils.formatEther(balance);
      return balances;
    }
    throw new Error("No network provider found!");
  }

  async signNonce(nonce) {
    await this.checkCachedProvider();
    if (Object.keys(this.networkProvider).length > 0) {
      return this.signer.signMessage(nonce);
    }
    throw new Error("No network provider found!");
  }

  async dropNftsToNftHolders(
    rewardedNftCollection,
    newNftCollectionName,
    newNftCollectionSymbol,
    newNftSupply,
    baseUri
  ) {
    await this.checkCachedProvider();
    if (Object.keys(this.networkProvider).length > 0) {
      const contract = new ethers.Contract(
        this.airDropContractAddress,
        this.airBroFactoryAbi,
        this.signer
      );

      try {
        const tx = await contract.dropNftsToNftHolders(
          rewardedNftCollection,
          newNftCollectionName,
          newNftCollectionSymbol,
          newNftSupply,
          baseUri
        );
        await tx.wait();
        return tx;
      } catch (error) {
        if (error.reason === "repriced") {
          const replacement = await this.provider.getTransaction(
            error.replacement.hash
          );
          await replacement.wait();
          return replacement;
        }
        return {
          hash: "rejected",
        };
      }
    }
    throw new Error("No network provider found!");
  }

  async dropNewTokensToNftHolders(
    rewardedNftCollection,
    tokenName,
    tokenSymbol,
    tokensPerClaimNew,
    airdropDuration
  ) {
    await this.checkCachedProvider();
    if (Object.keys(this.networkProvider).length > 0) {
      const contract = new ethers.Contract(
        this.airDropContractAddress,
        this.airBroFactoryAbi,
        this.signer
      );
      try {
        const tx = await contract.dropNewTokensToNftHolders(
          rewardedNftCollection,
          tokenName,
          tokenSymbol,
          tokensPerClaimNew,
          airdropDuration
        );
        await tx.wait();
        return tx;
      } catch (error) {
        if (error.reason === "repriced") {
          const replacement = await this.provider.getTransaction(
            error.replacement.hash
          );
          await replacement.wait();
          return replacement;
        }
        return {
          hash: "rejected",
        };
      }
    }
    throw new Error("No network provider found!");
  }

  async dropExisting1155NftsToNftHolders(
    rewardedNftCollection,
    reward1155Nft,
    tokensPerClaim,
    tokenId,
    totalAirdropAmount,
    airdropDuration
  ) {
    await this.checkCachedProvider();
    if (Object.keys(this.networkProvider).length > 0) {
      const contract = new ethers.Contract(
        this.airDropContractAddress,
        this.airBroFactoryAbi,
        this.signer
      );
      const nftTokenContract = new ethers.Contract(
        reward1155Nft,
        this.mintingAbi,
        this.signer
      );

      let tx = null;
      let fundContractNFT = null;
      let approveNftToken = null;

      try {
        tx = await contract.dropExisting1155NftsToNftHolders(
          rewardedNftCollection,
          reward1155Nft,
          tokensPerClaim,
          tokenId,
          totalAirdropAmount,
          airdropDuration
        );
        const res = await tx.wait();
        if (res) {
          const airdropNFTsContract = new ethers.Contract(
            res.events[1].args["airdropContract"],
            this.claimingAbi,
            this.signer
          );
          approveNftToken = await nftTokenContract.setApprovalForAll(
            res.events[1].args["airdropContract"],
            true
          );
          await approveNftToken.wait();
          if (approveNftToken) {
            fundContractNFT = await airdropNFTsContract.fundAirdrop();
            await fundContractNFT.wait();
            return fundContractNFT;
          }
          return approveNftToken;
        }
        return tx;
      } catch (error) {
        if (error.reason === "repriced") {
          const replacement = await this.provider.getTransaction(
            error.replacement.hash
          );
          await replacement.wait();
          return replacement;
        }
        return {
          hash: "rejected",
        };
      }
    }
    throw new Error("No network provider found!");
  }

  async dropExistingTokensToNftHolders(
    rewardedNftCollection,
    tokensPerClaimExisting,
    rewardToken,
    totalAirdropAmount,
    airdropDuration
  ) {
    await this.checkCachedProvider();
    if (Object.keys(this.networkProvider).length > 0) {
      const contract = new ethers.Contract(
        this.airDropContractAddress,
        this.airBroFactoryAbi,
        this.signer
      );
      const existingTokenContract = new ethers.Contract(
        rewardToken,
        this.erc20Abi,
        this.signer
      );

      let tx = null;
      let approveTokenTx = null;
      let fundContractTx = null;

      try {
        tx = await contract.dropExistingTokensToNftHolders(
          rewardedNftCollection,
          tokensPerClaimExisting,
          rewardToken,
          totalAirdropAmount,
          ethers.utils.parseEther(airdropDuration.toString())
        );

        const res = await tx.wait();
        if (res) {
          const existingTokenDropContract = new ethers.Contract(
            res.events[1].args["airdropContract"],
            this.existingTokenAbi,
            this.signer
          );
          approveTokenTx = await existingTokenContract.approve(
            res.events[1].args["airdropContract"],
            totalAirdropAmount
          );
          await approveTokenTx.wait();

          if (approveTokenTx) {
            fundContractTx = await existingTokenDropContract.fundAirdrop();
            await fundContractTx.wait();
            return fundContractTx;
          }
          return approveTokenTx;
        }
        return tx;
      } catch (error) {
        if (error.reason === "repriced") {
          const replacement = await this.provider.getTransaction(
            error.replacement.hash
          );
          await replacement.wait();
          return replacement;
        }
        return {
          hash: "rejected",
        };
      }
    }
    throw new Error("No network provider found!");
  }

  async calculateGasPrice(provider) {
    return 1.3 * (await provider.getGasPrice());
  }

  switchClaimABI(type) {
    if (type === "ERC1155") {
      return this.existingNftTokenAbi;
    }
    return this.existingTokenAbi;
  }

  async batchClaim(tokenIds, contractAddress, type = "existingToken") {
    await this.checkCachedProvider();
    if (Object.keys(this.networkProvider).length > 0) {
      const contract = new ethers.Contract(
        contractAddress,
        this.switchClaimABI(type),
        this.signer
      );
      try {
        const tx = await contract.batchClaim(tokenIds);
        await tx.wait();
        return tx;
      } catch (error) {
        if (error.reason === "repriced") {
          const replacement = await this.provider.getTransaction(
            error.replacement.hash
          );
          await replacement.wait();
          return replacement;
        }
        return {
          hash: "rejected",
        };
      }
    }
    throw new Error("No network provider found!");
  }

  async claimFee() {
    await this.checkCachedProvider();
    if (Object.keys(this.networkProvider).length > 0) {
      const contract = new ethers.Contract(
        this.airDropContractAddress,
        this.airbroCampaignFactoryAbi,
        this.signer
      );

      let tx = null;
      try {
        tx = await contract.claimFee();
        return tx;
      } catch (error) {
        if (error.reason === "repriced") {
          const replacement = await this.provider.getTransaction(
            error.replacement.hash
          );
          await replacement.wait();
          return replacement;
        }
        return {
          hash: "rejected",
        };
      }
    }
  }

  switchClaimData(type) {
    if (type === "ERC1155") {
      return {
        abi: this.newERC1155DropCampaignAbi,
      };
    }
    if (type === "SB1155") {
      return {
        abi: this.newSB1155DropCampaignAbi,
      };
    }
    if (type === "ERC20") {
      return {
        abi: this.existingERC20DropCampaignAbi,
      };
    }
  }
  async claim(merkleProof, type, contractAddress, address) {
    await this.checkCachedProvider();
    const contractData = this.switchClaimData(type);
    if (Object.keys(this.networkProvider).length > 0) {
      const contract = new ethers.Contract(
        contractAddress,
        contractData.abi,
        this.signer
      );
      const claimFeeValue = await this.claimFee();
      let tx = null;
      const gasLimitCalc = 150000 + merkleProof.length * 10000;
      try {
        tx = await contract.claim(merkleProof, address, {
          gasLimit: gasLimitCalc,
          value: claimFeeValue,
        });
        return tx;
      } catch (error) {
        if (error.reason === "repriced") {
          const replacement = await this.provider.getTransaction(
            error.replacement.hash
          );
          await replacement.wait();
          return replacement;
        }
        return {
          hash: "rejected",
        };
      }
    }
    throw new Error("No network provider found!");
  }

  async allAirbroAirdrops(index) {
    await this.checkCachedProvider();

    const contract = new ethers.Contract(
      this.airDropContractAddress,
      this.airBroFactoryAbi,
      this.signer
    );
    try {
      return await contract.airdrops(index);
    } catch (error) {
      console.log(error);
    }
  }

  async totalAirdropsCount() {
    await this.checkCachedProvider();

    const contract = new ethers.Contract(
      this.airDropContractAddress,
      this.airBroFactoryAbi,
      this.signer
    );
    try {
      return await contract.totalAirdropsCount();
    } catch (error) {
      return error;
    }
  }

  async rewardedNft(contractAddress) {
    await this.checkCachedProvider();

    const contract = new ethers.Contract(
      contractAddress,
      this.claimingAbi,
      this.provider
    );
    return await contract.rewardedNft();
  }

  async getAirdropAmount(contractAddress) {
    await this.checkCachedProvider();

    const contract = new ethers.Contract(
      contractAddress,
      this.claimingAbi,
      this.signer
    );
    return await contract.getAirdropAmount();
  }

  async getAirdropType(contractAddress) {
    await this.checkCachedProvider();

    const contract = new ethers.Contract(
      contractAddress,
      this.claimingAbi,
      this.provider
    );
    return await contract.getAirdropType();
  }
  async rewardToken(contractAddress) {
    await this.checkCachedProvider();

    const contract = new ethers.Contract(
      contractAddress,
      this.claimingAbi,
      this.provider
    );
    return await contract.rewardToken();
  }
  async tokensPerClaim(contractAddress) {
    await this.checkCachedProvider();

    const contract = new ethers.Contract(
      contractAddress,
      this.claimingAbi,
      this.provider
    );
    return await contract.tokensPerClaim();
  }

  //TWITTER CAMPAIGNS CONTARCT INTERACTIONS//

  //EXISTING TOKEN//
  async createExistingERC20DropCampaign(rewardToken, tokenSupply) {
    await this.checkCachedProvider();
    if (Object.keys(this.networkProvider).length > 0) {
      const contract = new ethers.Contract(
        this.airDropContractAddress,
        this.airbroCampaignFactoryAbi,
        this.signer
      );

      let tx = null;

      try {
        tx = await contract.createExistingERC20DropCampaign(
          rewardToken,
          tokenSupply
        );
        return tx;
      } catch (error) {
        if (error.reason === "repriced") {
          const replacement = await this.provider.getTransaction(
            error.replacement.hash
          );
          await replacement.wait();
          return replacement;
        }
        return {
          hash: "rejected",
        };
      }
    }
    throw new Error("No network provider found!");
  }

  async approveExistingERC20(rewardToken, address, tokenSupply) {
    await this.checkCachedProvider();
    if (Object.keys(this.networkProvider).length > 0) {
      const existingERC20Contract = new ethers.Contract(
        rewardToken,
        this.erc20Abi,
        this.signer
      );
      let approveTokenTx = null;
      try {
        approveTokenTx = await existingERC20Contract.approve(
          address,
          tokenSupply
        );
        return await approveTokenTx.wait();
      } catch (error) {
        if (error.reason === "repriced") {
          const replacement = await this.provider.getTransaction(
            error.replacement.hash
          );
          await replacement.wait();
          return replacement;
        }
        return {
          hash: "rejected",
        };
      }
    }
    throw new Error("No network provider found!");
  }

  async fundExistingERC20Contarct(address) {
    await this.checkCachedProvider();
    if (Object.keys(this.networkProvider).length > 0) {
      const existingERC20Contarct = new ethers.Contract(
        address,
        this.existingERC20DropCampaignAbi,
        this.signer
      );
      let fundContractTx = null;
      try {
        fundContractTx = await existingERC20Contarct.fundAirdrop();
        await fundContractTx.wait();
        return fundContractTx;
      } catch (error) {
        if (error.reason === "repriced") {
          const replacement = await this.provider.getTransaction(
            error.replacement.hash
          );
          await replacement.wait();
          return replacement;
        }
        return {
          hash: "rejected",
        };
      }
    }
    throw new Error("No network provider found!");
  }

  //NEW-ERC1155//
  async createNewERC1155DropCampaign(name, symbol, uri) {
    await this.checkCachedProvider();
    if (Object.keys(this.networkProvider).length > 0) {
      const contract = new ethers.Contract(
        this.airDropContractAddress,
        this.airbroCampaignFactoryAbi,
        this.signer
      );

      let tx = null;

      try {
        tx = await contract.createNewERC1155DropCampaign(name, symbol, uri, {
          gasLimit: 600000,
        });
        return tx;
      } catch (error) {
        console.log(error);
        if (error.reason === "repriced") {
          const replacement = await this.provider.getTransaction(
            error.replacement.hash
          );
          await replacement.wait();
          return replacement;
        }
        return {
          hash: "rejected",
        };
      }
    }
    throw new Error("No network provider found!");
  }

  //NEW-SB-ERC1155//
  async createNewSB1155DropCampaign(name, symbol, uri) {
    await this.checkCachedProvider();
    if (Object.keys(this.networkProvider).length > 0) {
      const contract = new ethers.Contract(
        this.airDropContractAddress,
        this.airbroCampaignFactoryAbi,
        this.signer
      );

      let tx = null;
      try {
        tx = await contract.createNewSB1155DropCampaign(name, symbol, uri, {
          gasLimit: 400000,
        });
        return tx;
      } catch (error) {
        if (error.reason === "repriced") {
          const replacement = await this.provider.getTransaction(
            error.replacement.hash
          );
          await replacement.wait();
          return replacement;
        }
        return {
          hash: "rejected",
        };
      }
    }
    throw new Error("No network provider found!");
  }
}

export default EthereumService;
