import * as Yup from "yup";
import { ethers } from "ethers";

const email = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

export const signUpCreatorSchema = Yup.object().shape({
  CreatorName: Yup.string().required("Name is required field"),
  CreatorEmail: Yup.string()
    .required("Email is a required field")
    .matches(email, {
      message: "Please enter a valid email address",
    }),
  CreatorWalletAddress: Yup.string()
    .required("Wallet address is a required field")
    .nullable()
    .test(
      "ADDRESS VALID",
      "Wallet address is not valid",
      (address) =>
        ethers.utils.isAddress(address) ||
        address === undefined ||
        address === ""
    ),
});
