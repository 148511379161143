<template>
  <menu-bar />
  <router-view />
  <air-bro-footer />
</template>

<script>
import { onMounted, ref, computed, onUnmounted } from "vue";
import { useStore } from "vuex";
import { ethereumService } from "./main";
import useAuth from "@/composables/useAuth";
import MenuBar from "@/components/MenuBar";
import AirBroFooter from "@/components/AirBroFooter";

export default {
  components: {
    MenuBar,
    AirBroFooter,
  },
  setup() {
    const store = useStore();

    const breakPoint = ref(1000);

    let windowWidth = ref(window.innerWidth);

    const onWidthChange = () => (windowWidth.value = window.innerWidth);

    const type = computed(() => {
      if (windowWidth.value < 550) return "xs";
      if (windowWidth.value >= 550 && windowWidth.value < 1200) return "md";
      if (windowWidth.value >= 1200) return "lg";
      return null; // This is an unreachable line, simply to keep eslint happy.
    });

    const width = computed(() => windowWidth.value);

    onMounted(async () => {
      const { handleAccountChange, handleChainChange, fetchMe } = useAuth(
        store,
        ethereumService
      );

      await fetchMe();
      await ethereumService.setContractData();
      await store.dispatch("users/fetchNetworkName");
      await ethereumService.onChainChanged(handleChainChange);
      await ethereumService.onAccountChanged(handleAccountChange);
      window.addEventListener("resize", onWidthChange);
    });

    onUnmounted(() => window.removeEventListener("resize", onWidthChange));

    return {
      width,
      type,
      breakPoint,
    };
  },
};
</script>
