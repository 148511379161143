<template>
  <div class="minting-wrapper">
    <div class="nft-data-wrapper">
      <div class="nft-wrapper">
        <div class="file-input-wrapper">
          <app-file-input
            v-if="!videoClassActive"
            v-model="localSelectedFileValue"
            name="SelectedFile"
            :nft="true"
            accept="image/png,image/jpeg,image/gif,image/webp,video/mp4,video/webm,audio/mp3,audio/webm,audio/mpeg,application/pdf"
          />
          <div class="generated-video" v-else>
            <div class="inner">
              <video autoplay muted loop width="206">
                <source
                  src="../../Common/Icons/template-nft-video.mp4"
                  type="video/mp4"
                />
              </video>
            </div>
            <div class="campaign-name-preview">
              <span class="name-dutarion-text campaign-name-headline"
                >Campaign Name:</span
              >
              <p class="duration-date-name campaign-name">
                {{ getCampaignName }}
              </p>
            </div>
            <div class="campaign-dutration-preview">
              <span class="name-dutarion-text">Campaign Duration:</span>
              <span class="duration-date-name"
                >{{ formatDateGeneratedNft(getCampaignStartDate) }} -
                {{ formatDateGeneratedNft(getCampaignEndDate) }}</span
              >
            </div>
          </div>
        </div>
        <div class="nft-reward-explanation-text">
          <div>
            <img
              src="@/Common/Icons/image-regular-twitter-pfp.svg"
              alt="image-regular-twitter-pfp"
            />
          </div>
          <div class="nft-reward-text">
            <p>
              Set the details of your NFT. After your campaign ends, eligible
              participants will be able to mint it as a reward.
            </p>
            <p>
              This template contains your campaign information and represents a
              standardized “PoSE - Poof of Social Engagement” NFT that your
              participants will receive.
            </p>
          </div>
        </div>
      </div>
      <div class="nft-input-wrapper">
        <div class="row">
          <div class="col-md-6">
            <app-input
              type="text"
              v-model="rewardNameValue"
              name="RewardName"
              inputClass="form-box-input"
              labelClass="label"
              labelText="Reward NFT Name"
              placeholder="Enter Collection Name"
              @change="changeRewardName"
            />
          </div>
          <div class="col-md-6">
            <app-input
              type="text"
              v-model="symbolNameValue"
              name="RewardSymbol"
              inputClass="form-box-input"
              labelClass="label"
              labelText="Reward NFT Symbol"
              placeholder="Collection Symbol (example: CLCTN)"
              @change="changeRewardSymbol()"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <app-textarea
              type="text"
              v-model="localDescriptionValue"
              labelClass="label"
              labelText="Reward Description"
              name="Description"
              textAreaClass="form-box-textarea"
              placeholder="Enter your NFT Reward Description (optional)"
              cols="1"
              rows="4"
              @change="changeLocalDescription"
            />
          </div>
        </div>
        <div class="number-of-copies solubound">
          <div>
            <input
              type="checkbox"
              v-model="isSoulboundNftLocal"
              true-value="true"
              false-value="false"
              name="SoulboundNft"
              class="checkbox-input"
              labelClass="label"
            />
          </div>
          <span class="capmaign-input-label">
            Make this NFT non-transferrable in the future
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { onMounted } from "vue";
import AppInput from "@/elements/AppInput";
import AppTextarea from "@/elements/AppTextarea";
import AppFileInput from "@/elements/AppFileInput";
import useCampaign from "@/composables/useCampaign";
import useUtilities from "@/composables/useUtilities";
import useMintAirBro from "@/composables/useMintAirBro";

export default {
  components: {
    AppInput,
    AppTextarea,
    AppFileInput,
  },
  setup() {
    const store = useStore();
    const {
      localDescription,
      localSelectedFile,
      isSoulboundNft,
      isSoulboundNftLocal,
      changeRewardName,
      localDescriptionValue,
      localSelectedFileValue,
      changeLocalDescription,
      changeRewardSymbol,
      rewardNameValue,
      symbolNameValue,
      videoClassActive,
      generateVideoNftToggle,
    } = useMintAirBro(store);

    const { formatDateGeneratedNft } = useUtilities();

    const { getCampaignStartDate, getCampaignEndDate, getCampaignName } =
      useCampaign(store);

    onMounted(() => {
      store.dispatch("moralis/resetState");
      store.dispatch("sendExistingToken/resetState");
    });

    return {
      isSoulboundNft,
      getCampaignName,
      rewardNameValue,
      changeRewardName,
      localDescription,
      videoClassActive,
      symbolNameValue,
      localSelectedFile,
      changeRewardSymbol,
      getCampaignEndDate,
      isSoulboundNftLocal,
      getCampaignStartDate,
      localDescriptionValue,
      localSelectedFileValue,
      changeLocalDescription,
      generateVideoNftToggle,
      formatDateGeneratedNft,
    };
  },
};
</script>

<style lang="scss" scoped src="@/Common/Styles/mintAirBro.scss"></style>
