import api from "../../services/api";

const initialState = () => ({
  errors: [],
  project: false,
  nftCollection: false,
  brand: false,
  event: false,
  creatorName: "",
  creatorEmail: "",
  creatorWalletAddress: "",
  pendingCreatorsList: [],
  signUpCreatorData: null,
  buttonDisabled: false,
});
const state = initialState();

const getters = {
  getByKey: (state) => (key) => state[key],
};
const mutations = {
  commitByKey(state, object) {
    Object.keys(object).forEach((key) => (state[key] = object[key]));
  },
  setPendingCreators(state, pendingCreatorsList) {
    state.pendingCreatorsList = pendingCreatorsList;
  },
  setSignUpCreatorData(state, signUpCreatorData) {
    state.signUpCreatorData = signUpCreatorData;
  },
  pushError(state, error) {
    state.errors.push(error);
  },
  clearErrors(state) {
    state.errors.splice(0);
  },
  resetState(state) {
    Object.assign(state, initialState());
  },
};
const actions = {
  resetState({ commit }) {
    commit("resetState");
  },
  commitByKey({ commit }, object) {
    commit("commitByKey", object);
  },
  async signUpCreator({ commit }, data) {
    try {
      const response = await api.signUpCreator(data);
      commit("setSignUpCreatorData", response.data);
      return response;
    } catch (error) {
      commit("pushError", error);
    }
  },
  async pendingCreators({ commit }) {
    try {
      const response = await api.pendingCreators();
      commit("setPendingCreators", response.data);
    } catch (error) {
      commit("pushError", error);
    }
  },
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
