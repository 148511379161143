<template>
  <base-form :handleSubmit="joinCampaign" :schema="joinCampaignSchema">
    <div class="join-components-wrapper">
      <div class="join-components-text">
        <div>
          <span>JOIN AND BECOME BRO...</span>
        </div>
        <div>
          <img src="@/Common/Icons/threeDashesIcon.svg" alt="Requirements" />
        </div>
      </div>
      <wallet-type-requirements />
      <social-media-type-reqiurements />
      <action-type-requirements
        v-if="singleCamapignData.proofType !== 'post'"
      />
      <post-action-type-of-requirements v-else />
      <div
        v-if="
          singleCamapignData.status === 'ended' &&
          singleCamapignData.avaliableFlag !== 'claim' &&
          singleCamapignData.avaliableFlag !== 'claimed'
        "
        class="ended-text"
      >
        <span>This campaign Ended</span>
      </div>
      <div
        class="submit-button-wrapper"
        v-if="
          singleCamapignData.status !== 'ended' &&
          singleCamapignData.avaliableFlag !== 'claim' &&
          singleCamapignData.avaliableFlag !== 'claimed'
        "
      >
        <div
          class="text-join-button"
          v-if="singleCamapignData.avaliableFlag !== 'joined'"
        >
          <img src="@/Common/Icons/completeJoinIcon.svg" alt="completeJoin" />
          <span>Complete steps above to Join the Campaign</span>
        </div>
        <div v-else>
          <img src="@/Common/Icons/brosIcon.svg" alt="completeJoin" />
          <span
            >{{ singleCampaignApplicantPageData.totalCount }}
            {{
              singleCampaignApplicantPageData.totalCount > 1 ? "Bros" : "Bro"
            }}
            Joined</span
          >
        </div>

        <app-button
          v-if="singleCamapignData.avaliableFlag !== 'joined'"
          type="submit"
          text="Join"
          buttonClass="medium-button-on-dark"
          :disabled="isJoinAvailable"
          :isLoader="joiningSpinner"
        />
      </div>
    </div>
  </base-form>
  <div class="claim-share-buttons">
    <ShareNetwork
      @click="trackJoinedTwitterShare()"
      v-for="network in networks"
      :network="network.network"
      :key="network.network"
      :url="url"
      :title="sharing.title"
      :description="sharing.description"
      :quote="sharing.quote"
      :hashtags="sharing.hashtags"
    >
      <app-button
        v-if="singleCamapignData.avaliableFlag === 'joined'"
        text="Tweet About This"
        buttonClass="medium-button-on-dark tweet-button"
      />
    </ShareNetwork>
    <div
      v-if="
        singleCamapignData.avaliableFlag === 'claim' ||
        singleCamapignData.avaliableFlag === 'claimed'
      "
    >
      <div
        class="reward-clamed"
        v-if="
          !singleCamapignData.merkleRootSet &&
          singleCamapignData.avaliableFlag === 'claim'
        "
      >
        <span>Network busy. Claiming will be unlocked shortly.</span>
      </div>
      <div
        class="reward-clamed"
        v-if="isClaimed || singleCamapignData.avaliableFlag === 'claimed'"
      >
        <span>Reward has been claimed</span>
      </div>
      <div v-else class="claim-button">
        <app-button
          text="Claim"
          buttonClass="medium-button-on-dark"
          @click="handleClaimReward"
          :isLoader="claimingSpinner"
          :class="[disableButton ? 'claiming' : '']"
          :disabled="!singleCamapignData.merkleRootSet"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { ref, computed } from "vue";
import { useStore } from "vuex";
import { ethereumService, eventBus } from "@/main";
import useAuth from "@/composables/useAuth";
import BaseForm from "@/components/BaseForm";
import AppButton from "@/elements/AppButton.vue";
import useCampaign from "@/composables/useCampaign";
import useClaiming from "@/composables/useClaiming";
import { createToaster } from "@meforma/vue-toaster";
import { joinCampaignSchema } from "@/validationSchemas/joinCampaignSchema";
import WalletTypeRequirements from "@/components/SingleCampaignPage/JoinCampaignRequirementSteps/WalletTypeRequirements";
import SocialMediaTypeReqiurements from "@/components/SingleCampaignPage/JoinCampaignRequirementSteps/SocialMediaTypeReqiurements";
import ActionTypeRequirements from "@/components/SingleCampaignPage/JoinCampaignRequirementSteps//ActionTypeRequirements.vue";
import PostActionTypeOfRequirements from "@/components/SingleCampaignPage/JoinCampaignRequirementSteps//PostActionTypeOfRequirements.vue";

export default {
  components: {
    WalletTypeRequirements,
    SocialMediaTypeReqiurements,
    ActionTypeRequirements,
    PostActionTypeOfRequirements,
    AppButton,
    BaseForm,
  },
  setup() {
    const store = useStore();
    const { address, applyToTwitterCampaign, isLoggedIn, walletAddressInput } =
      useAuth(store, ethereumService);
    const {
      singleCamapignData,
      socialProfileData,
      singleCampaignApplicantPageData,
      getCampaignByIdLogged,
      getCampaignById,
      errors,
    } = useCampaign(store);
    const { getMerkleProof, getClaimableCamapigns, claimGaslessReward } =
      useClaiming(store);

    const isJoinAvailable = computed(() => {
      if (socialProfileData.value.imageUrl) {
        return false;
      } else if (socialProfileData.value.postUrl) {
        return false;
      } else {
        return true;
      }
    });

    const isJoined = ref(false);
    const claimingSpinner = ref(false);
    const joiningSpinner = ref(false);
    const disableButton = ref(false);
    const isClaimed = ref(false);
    const sharing = ref({
      title: "Check out this campaign on AirBro: ",
      description: "",
      quote: "",
      hashtags: ["airbro"],
      twitterUser: "",
    });
    const networks = ref([
      {
        network: "twitter",
        name: "Twitter",
      },
    ]);
    const campaignId = window.location.pathname.split("/")[2];
    const url = ref(window.location.origin + "/campaign/" + campaignId);

    const trackJoinedTwitterShare = () => {
      if (process.env.VUE_APP_ENV !== "production") return;
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "joined_campaign_shared_twitter",
      });
    };

    const trackRewardClaimed = () => {
      if (process.env.VUE_APP_ENV !== "production") return;
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "reward_claimed",
      });
    };
    const trackRewardGaslessClaimed = () => {
      if (process.env.VUE_APP_ENV !== "production") return;
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "reward_gasless_claimed",
      });
    };
    const trackJoinWalletPaste = () => {
      if (process.env.VUE_APP_ENV !== "production") return;
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "join_wallet_paste",
      });
    };
    const trackJoinWalletConnect = () => {
      if (process.env.VUE_APP_ENV !== "production") return;
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "join_wallet_connect",
      });
    };

    const joinCampaign = async () => {
      joiningSpinner.value = true;
      try {
        const result = await applyToTwitterCampaign(
          singleCamapignData.value.proofType
        );
        if (result.data.userId) {
          isJoined.value = true;
          joiningSpinner.value = false;
          if (isLoggedIn.value) {
            await getCampaignByIdLogged(singleCamapignData.value.id);
          } else {
            await getCampaignById(singleCamapignData.value.id);
          }
        }
        walletAddressInput.value !== null || walletAddressInput.value !== ""
          ? trackJoinWalletPaste()
          : trackJoinWalletConnect();
        eventBus.emit("resetCampaignsInfo", {
          ...singleCamapignData.value,
          applicantsCount: singleCamapignData.value.applicantsCount + 1,
          avaliableFlag: "joined",
        });
      } catch (error) {
        const toaster = createToaster({ position: "bottom", duration: 3000 });
        toaster.error(errors.value[0].response.data.message);
        joiningSpinner.value = false;
        await store.dispatch("campaign/commitByKey", {
          alreadyJoinedCampaign: true,
        });
        return;
      }
    };
    const claimReward = async () => {
      claimingSpinner.value = true;
      disableButton.value = true;
      const merkleProof = await getMerkleProof();
      const type = computed(() => {
        if (singleCamapignData.value.type === "token") return "ERC20";
        if (
          singleCamapignData.value.type === "nft" &&
          !singleCamapignData.value.nonTranferableNft
        )
          return "ERC1155";
        if (
          singleCamapignData.value.type === "nft" &&
          singleCamapignData.value.nonTranferableNft
        )
          return "SB1155";
        return null;
      });
      if (
        merkleProof.length > 0 ||
        (merkleProof.length === 0 &&
          singleCamapignData.value.applicantsCount === 1)
      ) {
        const tx = await ethereumService.claim(
          merkleProof,
          type.value,
          singleCamapignData.value.contractAddress,
          address.value
        );
        if (tx.hash === "rejected") {
          claimingSpinner.value = false;
          disableButton.value = false;
        }
        const res = await tx.wait();
        claimingSpinner.value = false;
        isClaimed.value = true;
        eventBus.emit("resetCampaignsClaim", {
          ...singleCamapignData.value,
          avaliableFlag: "claimed",
        });
        await getClaimableCamapigns();
        trackRewardClaimed();
        return res;
      } else {
        const toaster = createToaster({ position: "bottom", duration: 3000 });
        toaster.error(`Something went wrong!`);
        claimingSpinner.value = false;
        isClaimed.value = false;
        disableButton.value = false;
      }
    };
    const gaslessClaimReward = async () => {
      claimingSpinner.value = true;
      disableButton.value = true;
      try {
        const tx = await claimGaslessReward();
        claimingSpinner.value = false;
        isClaimed.value = true;
        eventBus.emit("resetCampaignsClaim", {
          ...singleCamapignData.value,
          avaliableFlag: "claimed",
        });
        await getClaimableCamapigns();
        trackRewardGaslessClaimed();
        return tx;
      } catch (error) {
        const toaster = createToaster({ position: "bottom", duration: 3000 });
        toaster.error(`Something ewent wrong!`);
        disableButton.value = false;
        claimingSpinner.value = false;
        console.log(error);
      }
    };

    const handleClaimReward = async () => {
      singleCamapignData.value.gaslessClaim
        ? await gaslessClaimReward()
        : await claimReward();
    };
    return {
      url,
      sharing,
      networks,
      isJoined,
      isClaimed,
      joinCampaign,
      disableButton,
      joiningSpinner,
      isJoinAvailable,
      claimingSpinner,
      trackJoinedTwitterShare,
      handleClaimReward,
      socialProfileData,
      singleCamapignData,
      joinCampaignSchema,
      singleCampaignApplicantPageData,
    };
  },
};
</script>
<style
  lang="scss"
  scoped
  src="@/Common/Styles/joinCampaignReqWrapper.scss"
></style>
