import * as Yup from "yup";
import { ethers } from "ethers";

const today = new Date();
today.setHours(24, 0, 0, 0);

export const postContentSchema = Yup.object().shape({
  PostContentCampaignDescription: Yup.string()
    .required("Content is required")
    .max(280, "Content can't be longer then 280 characters"),
  NumberOfFolowers: Yup.number()
    .integer("Should not have decimals")
    .typeError("Must be a number")
    .nullable()
    .positive("Must be greater than 0")
    .transform((_, val) => (val !== "" ? Number(val) : null)),
  AccountDateCreation: Yup.date()
    .max(today, "Date can't be after today")
    .nullable()
    .transform((curr, orig) => (orig === "" ? null : curr)),
  OwnershipNftAddress: Yup.string()
    .nullable()
    .test(
      "ADDRESS VALID",
      "Nft collection address is not valid",
      (address) =>
        ethers.utils.isAddress(address) ||
        address === undefined ||
        address === ""
    ),
});
