<template>
  <air-bro-description />
  <div class="">
    <active-campaigns />
  </div>
  <creator-cta />
  <participant-cta />
  <testimonials-carousel />
  <book-demo />
  <div class="">
    <apply-for-campaigns />
  </div>
</template>

<script>
import { useStore } from "vuex";
import useAuth from "@/composables/useAuth";
import AirBroDescription from "@/components/AirBroDescription.vue";
import ActiveCampaigns from "@/components/CreateTwitterCampaign/ActiveCampaigns";
import CreatorCta from "@/components/HomePage/CreatorCta";
import ParticipantCta from "@/components/HomePage/ParticipantCta";
import TestimonialsCarousel from "@/components/HomePage/TestimonialsCarousel";
import BookDemo from "@/components/HomePage/BookDemo";

import ApplyForCampaigns from "@/components/CreateTwitterCampaign/ApplyForCampaigns";

export default {
  name: "AirBroHome",
  components: {
    AirBroDescription,
    ActiveCampaigns,
    CreatorCta,
    ParticipantCta,
    TestimonialsCarousel,
    BookDemo,
    ApplyForCampaigns,
  },
  setup() {
    const store = useStore();
    const { isLoggedIn } = useAuth(store);
    return {
      isLoggedIn,
    };
  },
};
</script>
<style lang="scss" scoped src="@/Common/Styles/airBroHome.scss"></style>
