<template>
  <div
    class="connect-wallet-req"
    :class="{
      disable: singleCamapignData.status === 'ended',
    }"
  >
    <div class="connect-wallet-headline">
      <div>
        <div class="wallet-connected" v-if="isLoggedIn">
          <p class="connect">1. Wallet Connected</p>
          <p class="paste">1. Wallet Pasted</p>
          <img
            v-if="
              singleCamapignData?.tokenReqName &&
              nftsForContractForWallet.length === 0
            "
            src="@/Common/Icons/notDoneIcon.svg"
            alt="notDone"
          />
          <img v-else src="@/Common/Icons/doneIcon.gif" alt="done" />
        </div>
        <div class="wallet-not-connected" v-else>
          <p class="connect">1. Connect Wallet</p>
          <p class="paste">1. Paste Wallet</p>
        </div>
      </div>
      <span class="cw-message" v-if="!isLoggedIn"
        >Connect Wallet or Paste in your Wallet Address.</span
      >
      <div class="address-name-wrapper" v-else>
        <span>{{ reduceAddress(5, 4) }}</span>
        <span class="network-name"> {{ networkName }}</span>
      </div>
    </div>
    <div class="wallet-conditions" v-if="singleCamapignData?.tokenRequirements">
      <div class="conditions-headline">
        <img src="@/Common/Icons/conditionsIcon.svg" alt="ConditionsIcon" />
        <span>Conditions</span>
      </div>
      <div class="require-condition">
        <ul>
          <li>
            Own
            <span
              :class="{
                conditionFaild:
                  nftsForContractForWallet.length === 0 && isLoggedIn,
              }"
              >{{ singleCamapignData?.tokenReqName }}</span
            >
            NFT
          </li>
        </ul>
      </div>
    </div>
    <div class="connect-wallet-address-wrapper" v-if="!isLoggedIn">
      <app-button
        text="CONNECT"
        buttonClass="small-button"
        @click.prevent="logIn()"
      />
      <div class="paste-wrapper">
        <span>or</span>
        <app-input
          type="text"
          name="WalletAddress"
          v-model="walletAddress"
          inputClass="requrements-page-box-input"
          labelText=""
          placeholder="Paste address here"
          @change="changeInputWalletAddress(walletAddress)"
        />
      </div>
    </div>
    <div class="change-action" v-else>
      <span @click="disconnect(true)">change</span>
    </div>
  </div>
</template>
<script>
import { ref } from "vue";
import { useStore } from "vuex";
import { ethereumService } from "@/main";
import AppInput from "@/elements/AppInput";
import AppButton from "@/elements/AppButton";
import useAuth from "@/composables/useAuth";
import useCampaign from "@/composables/useCampaign";
import useClaiming from "@/composables/useClaiming";
export default {
  components: {
    AppInput,
    AppButton,
  },
  setup() {
    const store = useStore();
    const { loginOrSignUp, isLoggedIn, address, networkName, disconnect } =
      useAuth(store, ethereumService);
    const {
      singleCamapignData,
      nftsForContractForWallet,
      fetchNFTsForContract,
      changeInputWalletAddress,
    } = useCampaign(store);
    const { fetchNftUserAddress, getClaimableCount } = useClaiming(store);

    const walletAddress = ref("");

    const reduceAddress = (lengthLeft, lengthRight) => {
      if (address.value) {
        const addressLeftPart = address.value.substring(0, lengthLeft);
        const addressRightPart = address.value.substring(42 - lengthRight, 42);
        return `${addressLeftPart}...${addressRightPart}`;
      }
    };
    const trackConnectWallet = () => {
      if (process.env.VUE_APP_ENV !== "production") return;
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "wallet_connected",
      });
    };
    const logIn = async () => {
      await loginOrSignUp();
      if (isLoggedIn.value) {
        const options = {
          chain: networkName?.value,
          address: address.value,
        };
        await fetchNftUserAddress(options);
        await store.dispatch("users/fetchAddress");
        await getClaimableCount();
        if (singleCamapignData.value.tokenRequirements) {
          const optionsNFTsForContract = {
            chain: networkName?.value,
            address: address.value,
            token_address: singleCamapignData.value.tokenRequirements,
          };
          fetchNFTsForContract(optionsNFTsForContract);
        }
        store.dispatch("users/commitByKey", {
          walletAddressInput: address.value,
        });
        trackConnectWallet();
      }
    };
    return {
      logIn,
      address,
      disconnect,
      isLoggedIn,
      networkName,
      walletAddress,
      reduceAddress,
      singleCamapignData,
      nftsForContractForWallet,
      changeInputWalletAddress,
    };
  },
};
</script>
<style
  lang="scss"
  scoped
  src="@/Common/Styles/walletTypeRequirements.scss"
></style>
