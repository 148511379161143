<template>
  <div class="testimonials-carousel-wrapper">
    <div class="custom-container">
      <div class="heading-section">
        <div class="epilogue-text-dark text-capitalize">Creator stories</div>
        <h2 class="epilogue-title">
          Recommended by Fast Growing Web3 Projects
        </h2>
      </div>
    </div>
    <div class="carousel-section">
      <flickity ref="flickity" :options="flickityOptions" class="gallery">
        <div
          v-for="testimonial in testimonials"
          class="gallery-cell"
          v-bind:key="testimonial.id"
          :style="[
            { width: width < 900 ? '90%' : '680px' },
            { height: width < 900 ? '500px' : '350px' },
          ]"
        >
          <div class="logo">
            <a :href="testimonial.projectUrl" target="_blank">
              <img :src="testimonial.logo" :alt="testimonial.projectName" />
            </a>
          </div>
          <div
            class="quote-body epilogue-text-dark"
            :id="'quote-' + testimonial.id"
          ></div>
          <span class="author epilogue-smaller dark-gray-base">
            <template
              v-if="testimonial.name !== '' || testimonial.title !== ''"
            >
              <span class="name">{{ testimonial.name }}</span
              >, {{ testimonial.title }}
            </template>
          </span>
        </div>
      </flickity>
    </div>
  </div>
</template>
<script>
import { ref, onMounted, onUnmounted, computed } from "vue";
import Flickity from "vue-flickity";

export default {
  name: "TestimonialsCarousel",
  components: {
    Flickity,
  },
  setup() {
    const flickityOptions = ref({
      wrapAround: true,
      cellAlign: "center",
    });
    const testimonials = ref([
      {
        id: 0,
        logo: require("@/Common/Icons/Testimonials/web3DelightLogo.svg"),
        projectName: "Web3Delight Conference",
        projectUrl: "https://www.web3delight.com/",
        quote:
          "AirBro is a slam dunk promotion tool for any team completely swamped with putting together a conference. <br>It's very easy to use, especially with their team's support. <br><br>It helped us cross-promote our Astro Armadillos NFT collection and the Web3Delight conference all at once.",
        name: "",
        title: "",
      },
      {
        id: 1,
        logo: require("@/Common/Icons/Testimonials/SpletTechLogo.svg"),
        projectName: "Splet Tech Conference",
        projectUrl: "https://heapcon.io/",
        quote:
          "We were amazed at the response we got from the AirBro campaign despite not being a web3-centric event. <br><br>Rewarding participants with a workshop pass proved to be a success and having the AirBro team at the event really helped spread the message. <br><br>Looking forward to using AirBro more.",
        name: "",
        title: "",
      },
      {
        id: 2,
        logo: require("@/Common/Icons/Testimonials/mvpwLogo.svg"),
        projectName: "MVP Workshop",
        projectUrl: "https://mvpworkshop.co/",
        quote:
          "We commemorated the company's 5 year anniversary in styl3! <br><br>Our goal was always to become a regional pioneer in the web3 ecosystem, so having an AirBro campaign to showcase that, went hand in hand. The NFTs our employees earned are a great reminder of the journey we are on.",
        name: "",
        title: "",
      },
      {
        id: 3,
        logo: require("@/Common/Icons/Testimonials/heapconLogo.svg"),
        projectName: "Heapcon Tech Conference",
        projectUrl: "https://heapcon.io/",
        quote:
          "Heapcon is a veteran in the IT conferencing space that always looks forward to showcasing new tech. <br>Naturally, AirBro was an obvious fit. <br><br>While giving out spots for our conference next year, we loved how the campaign was received on social, both in our and the AirBro ecosystem.",
        name: "",
        title: "",
      },
    ]);

    const onWidthChange = () => (windowWidth.value = window.innerWidth);

    const width = computed(() => windowWidth.value);

    let windowWidth = ref(window.innerWidth);

    onMounted(() => {
      const handleQuoteBodyFormatting = () => {
        for (let i = 0; i < testimonials.value.length; i++) {
          const element = document.getElementById(
            "quote-" + testimonials.value[i].id
          );
          element.innerHTML = testimonials.value[i].quote;
        }
      };
      handleQuoteBodyFormatting();
      window.addEventListener("resize", onWidthChange);
    });

    onUnmounted(() => window.removeEventListener("resize", onWidthChange));

    return {
      width,
      testimonials,
      flickityOptions,
    };
  },
};
</script>
<style
  lang="scss"
  scoped
  src="@/Common/Styles/testimonialsCarousel.scss"
></style>
