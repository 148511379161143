<template>
  <div>
    <label :for="id" :class="labelClass">{{ labelText }}</label>
    <select
      :value="inputValue"
      @change="(event) => $emit('update:modelValue', event.target.value)"
      :class="[selectClass, errorMessage ? 'error-message-border' : '']"
      :name="name"
      required
    >
      <option value="" disabled selected hidden>{{ placeholder }}</option>
      <option
        v-for="(option, index) in options"
        :value="JSON.stringify(option)"
        :key="index"
        :id="id"
      >
        {{ option.label }}
      </option>
    </select>
    <p class="input-error-message" v-show="errorMessage || meta.valid">
      {{ errorMessage || successMessage }}
    </p>
  </div>
</template>
<script>
import { eventBus } from "@/main";
import { toRefs, watch } from "vue";
import { useField } from "vee-validate";
export default {
  props: {
    options: {
      type: Object,
      required: true,
    },
    modelValue: {
      type: String,
      required: false,
    },
    id: {
      type: String,
    },
    name: {
      type: String,
      default: "",
    },
    labelText: {
      type: String,
    },
    selectClass: {
      type: String,
      default: "",
    },
    labelClass: {
      type: String,
      default: "",
    },
    optionValue: {
      type: String,
      default: "value",
    },
    placeholder: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const {
      value: inputValue,
      errorMessage,
      meta,
      resetField,
    } = useField(props.name, undefined, {
      initialValue: props.value,
    });
    eventBus.on("resetErrors", () => {
      resetField();
    });
    let { modelValue } = toRefs(props);
    watch(
      () => modelValue,
      (modelValue) => {
        inputValue.value = modelValue.value;
      },
      { deep: true }
    );
    return {
      errorMessage,
      inputValue,
      meta,
    };
  },
};
</script>
