<template>
  <div class="countdown-timer-full">
    <ul>
      <li>
        <span
          class="number epilogue-title"
          :class="{ highlight: timer.days > 0 }"
        >
          {{ formatToDoubleDigits(timer.days) }}
        </span>
        <span class="type epilogue-text" :class="{ highlight: timer.days > 0 }">
          days
        </span>
      </li>
      <li class="two-dots-colon">:</li>
      <li>
        <span
          class="number epilogue-title"
          :class="{ highlight: timer.hours > 0 && timer.days === 0 }"
        >
          {{ formatToDoubleDigits(timer.hours) }}
        </span>
        <span
          class="type epilogue-text"
          :class="{ highlight: timer.hours > 0 && timer.days === 0 }"
        >
          hours
        </span>
      </li>
      <li class="two-dots-colon">:</li>
      <li>
        <span
          class="number epilogue-title"
          :class="{ highlight: timer.minutes > 0 && timer.hours === 0 }"
        >
          {{ formatToDoubleDigits(timer.minutes) }}
        </span>
        <span
          class="type epilogue-text"
          :class="{ highlight: timer.minutes > 0 && timer.hours === 0 }"
        >
          min
        </span>
      </li>
      <li class="two-dots-colon">:</li>
      <li>
        <span
          class="number epilogue-title"
          :class="{ highlight: timer.seconds > 0 && timer.minutes === 0 }"
        >
          {{ formatToDoubleDigits(timer.seconds) }}
        </span>
        <span
          class="type epilogue-text"
          :class="{ highlight: timer.seconds > 0 && timer.minutes === 0 }"
        >
          sec
        </span>
      </li>
    </ul>
  </div>
</template>
<script>
import { watchEffect, watch, onMounted, ref } from "vue";
import { useTimer } from "vue-timer-hook";
export default {
  name: "TimerFull",
  props: {
    time: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const timer = ref(useTimer(new Date(props.time)));

    const formatToDoubleDigits = (number) => {
      let valAsString = number.toString();
      if (valAsString.length === 1) {
        return "0" + number;
      }
      return number;
    };

    watch(
      () => props.time,
      (newVal, oldVal) => {
        oldVal;
        timer.value = useTimer(new Date(newVal));
      }
    );

    onMounted(() => {
      watchEffect(async () => {
        if (timer.value.isExpired) {
          console.warn("IsExpired");
        }
      });
    });
    return {
      timer,
      formatToDoubleDigits,
    };
  },
};
</script>
<style lang="scss" scoped src="@/Common/Styles/timerFull.scss"></style>
