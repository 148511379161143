<template>
  <div class="campaign-reward">
    <app-accordion
      :isOpen="isOpen"
      :toggleAccordion="toggleAccordion"
      id="reward"
    >
      <template v-slot:title>
        <div class="campaign-reward-wrapper" :class="{ accordionOpen: isOpen }">
          <div class="illustration-wrapper">
            <img
              src="@/Common/Icons/rewardIllustration.svg"
              alt="rewardIllustration"
              class="campaign-reward-illustration"
              v-if="isOpen"
            />
            <img
              src="@/Common/Icons/rewardIllustrationSmallPen.svg"
              alt="rewardIllustrationSmallPen"
              class="campaign-reward-illustration"
              v-else
            />
          </div>
          <div class="headline-text-wrapper">
            <span
              class="campaign-reward-headline"
              :class="{ campaignHeadlineAccordionOpen: isOpen }"
              >Reward</span
            >
            <span class="camapign-reward-undertext"
              >Set up a reward that your participants will receive.</span
            >
          </div>
          <div class="arrow-wrapper">
            <img
              src="@/Common/Icons/accordionArrowDown.svg"
              alt="arrowUp"
              v-if="!isOpen"
            />
            <img
              src="@/Common/Icons/accordionArrowUp.svg"
              alt="arrowDown"
              v-else
            />
          </div>
        </div>
      </template>
      <template v-slot:content>
        <div class="campaign-reward-content">
          <div class="tab-reward-buttons-wrap">
            <app-button
              text="CREATE A NEW NFT"
              class="campaign-reward-tab-button create-new-nft"
              :class="{
                active: getCampaignRewardComponentName === 'MintAirBro',
              }"
              @click.prevent="changeComponent('MintAirBro', 'nft')"
            />
            <app-button
              text="FUNGIBLE TOKEN"
              class="campaign-reward-tab-button fungible-token"
              :class="{
                active: getCampaignRewardComponentName === 'SendExistingToken',
              }"
              @click.prevent="changeComponent('SendExistingToken', 'token')"
            />
          </div>
          <component :is="getCampaignRewardComponentName" />
        </div>
      </template>
    </app-accordion>
  </div>
</template>
<script>
import { useStore } from "vuex";
import { ref } from "vue";
import AppButton from "@/elements/AppButton";
import useCampaign from "@/composables/useCampaign";
import AppAccordion from "@/elements/AppAccordion.vue";
import MintAirBro from "@/components/PrepareTokensToSendTabs/MintAirBro";
import SendExistingToken from "@/components/PrepareTokensToSendTabs/SendExistingToken";
export default {
  components: {
    AppButton,
    MintAirBro,
    AppAccordion,
    SendExistingToken,
  },
  setup() {
    const store = useStore();
    const { getCampaignRewardComponentName } = useCampaign(store);
    const isOpen = ref(false);
    const changeComponent = (name, type) => {
      store.dispatch("campaign/commitByKey", {
        campaignRewardComponentName: name,
      });
      store.dispatch("campaign/commitByKey", {
        tokenType: type,
      });
    };
    const toggleAccordion = () => {
      isOpen.value = !isOpen.value;
    };
    return {
      isOpen,
      getCampaignRewardComponentName,
      changeComponent,
      toggleAccordion,
    };
  },
};
</script>
<style lang="scss" scoped src="@/Common/Styles/campaignReward.scss"></style>
