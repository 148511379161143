<template>
  <div>
    <div class="requirements-bar">
      <div
        @click="openRequirementsModal()"
        class="join button epilogue-smaller dark-gray-base"
      >
        JOIN
      </div>
      <ShareNetwork
        @click="trackTwitterShare()"
        v-for="network in networks"
        :network="network.network"
        :key="network.network"
        :url="twitterShare.url"
        :title="twitterShare.title"
        :description="twitterShare.description"
        :quote="twitterShare.quote"
        :hashtags="twitterShare.hashtags"
      >
        <div class="share button epilogue-smaller">SHARE</div>
      </ShareNetwork>
    </div>
    <div v-if="isModalActive" class="requirements-modal">
      <div class="header">
        <p>TO JOIN AND BECOME A BRO</p>
        <img
          @click="isModalActive = false"
          src="@/Common/Icons/CampaignSinglePage/x.svg"
          alt="Requirement Modal Close"
          class="icon"
        />
      </div>
      <div class="body">
        <join-campaign-req-wrapper />
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref, computed, watch } from "vue";
import JoinCampaignReqWrapper from "@/components/SingleCampaignPage/JoinCampaignRequirementSteps/JoinCampaignReqWrapper.vue";

export default {
  name: "RequirementsBar",
  components: {
    JoinCampaignReqWrapper,
  },
  props: {
    campaignID: {
      type: Number,
      default: () => {},
    },
  },
  setup(props) {
    const currentCampaignID = ref(props.campaignID);

    const isModalActive = ref(false);

    const trackTwitterShare = () => {
      if (process.env.VUE_APP_ENV !== "production") return;
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "campaign_shared_twitter",
      });
    };

    const campaignUrl = computed(() => {
      return window.location.origin + "/campaign/" + currentCampaignID.value;
    });

    const twitterShare = ref({
      title: "Check out this campaign on AirBro: ",
      description: "",
      url: campaignUrl,
      quote: "",
      hashtags: ["airbro"],
      twitterUser: "",
    });

    const networks = ref([
      {
        network: "twitter",
        name: "Twitter",
        icon: require(`@/Common/Icons/twitterShare.svg`),
        shareIcon: require(`@/Common/Icons/twitterShare.svg`),
        shareIconHover: require(`@/Common/Icons/twitterShareHover.svg`),
      },
    ]);

    const openRequirementsModal = () => {
      isModalActive.value = true;
      window.scrollTo(0, 0);
    };

    watch(
      () => props.campaignID,
      async (newVal, oldVal) => {
        oldVal;
        if (newVal !== oldVal) {
          currentCampaignID.value = newVal;
        }
      }
    );

    onMounted(async () => {});
    return {
      networks,
      twitterShare,
      isModalActive,
      trackTwitterShare,
      openRequirementsModal,
    };
  },
};
</script>
<style lang="scss" scoped src="@/Common/Styles/requirementsBar.scss"></style>
