import AirBroHome from "@/views/AirBroHome.vue";
import HowItWorksView from "@/views/HowItWorksView.vue";
import TwitterCampaign from "@/views/TwitterCampaign.vue";
import InstagramCampaign from "@/views/InstagramCampaign.vue";
import SignUp from "@/views/SignUp";
import BackendView from "@/views/BackendView";
import SingleCampaignView from "@/views/SingleCampaignView";
import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "AirBroHome",
    component: AirBroHome,
  },
  {
    path: "/create-twitter-campaign",
    name: "CreateTwitterCampaign",
    component: TwitterCampaign,
  },
  {
    path: "/create-instagram-campaign",
    name: "CreateInstagramCampaign",
    component: InstagramCampaign,
  },
  {
    path: "/how-it-works",
    name: "HowItWorksView",
    component: HowItWorksView,
  },
  {
    path: "/control-panel",
    name: "Backend",
    component: BackendView,
  },
  {
    path: "/sign-up",
    name: "BecomeCreator",
    component: SignUp,
  },
  {
    path: "/campaign/:id",
    name: "SingleCampaign",
    component: SingleCampaignView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  // eslint-disable-next-line no-unused-vars
  scrollBehavior(to, from, savedPosition) {
    return { top: 0 };
  },
});

router.beforeEach((to, from, next) => {
  // Ristrict backedn route for users
  if (to.name === "Backend") {
    const vuex = JSON.parse(localStorage.getItem("vuex"));
    if (vuex.users.isLoggedIn === true && vuex.users.role === "ADMIN") {
      return next();
    }
    return next({ name: "AirBroHome" });
  }
  // Instagram camapign apply functionality after instagram redirect back to app
  const camapignId = localStorage.getItem("campaignId");
  const instagramApply = localStorage.getItem("instagramApplyFlag");
  if (
    camapignId &&
    instagramApply === "true" &&
    (to.query.error_reason || to.query.code)
  ) {
    localStorage.setItem("campaignInstagramCode", to.query.code);
    if (to.query.error_reason) {
      localStorage.setItem("campaignInstagramError", to.query.error_reason);
    }
    next({ name: "AirBroHome", query: { campaign: camapignId } });
  }
  return next();
});

export default router;
