import { computed } from "vue";

const useAditionalRequirements = (store) => {
  const numberOfFolowers = computed(() =>
    store.getters["aditionalRequirements/getByKey"]("numberOfFolowers")
  );

  const accountDateCreation = computed(() =>
    store.getters["aditionalRequirements/getByKey"]("accountDateCreation")
  );
  const ownershipNftAddress = computed(() =>
    store.getters["aditionalRequirements/getByKey"]("ownershipNftAddress")
  );

  const changeNumberOfFolowers = (value) => {
    store.dispatch("aditionalRequirements/commitByKey", {
      numberOfFolowers: value,
    });
  };
  const changeAccountDateCreation = (value) => {
    store.dispatch("aditionalRequirements/commitByKey", {
      accountDateCreation: value,
    });
  };
  const changeOwnershipNftAddress = (value) => {
    store.dispatch("aditionalRequirements/commitByKey", {
      ownershipNftAddress: value,
    });
  };

  return {
    numberOfFolowers,
    accountDateCreation,
    ownershipNftAddress,
    changeNumberOfFolowers,
    changeAccountDateCreation,
    changeOwnershipNftAddress,
  };
};
export default useAditionalRequirements;
