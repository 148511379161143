import { computed } from "vue";

const useNftPfpRequirements = (store) => {
  const rewardingNftColectionAddress = computed(() =>
    store.getters["nftPfpRequirements/getByKey"]("rewardingNftColectionAddress")
  );
  const selectedFileNftPfpImage = computed(() =>
    store.getters["nftPfpRequirements/getByKey"]("selectedFileImage")
  );

  const rewardingNftColectionMetadata = computed(() =>
    store.getters["nftPfpRequirements/getByKey"](
      "rewardingNftColectionMetadata"
    )
  );
  const campaignDaysToHoldPFP = computed(() =>
    store.getters["nftPfpRequirements/getByKey"]("campaignDaysToHoldPFP")
  );

  const fetchNftMetadata = (options) =>
    store.dispatch("nftPfpRequirements/fetchNftMetadata", options);

  const changeNftCollectionAddress = (value) => {
    store.dispatch("nftPfpRequirements/commitByKey", {
      rewardingNftColectionAddress: value,
    });
  };

  const changeDaysToHoldPFP = (value) => {
    store.dispatch("nftPfpRequirements/commitByKey", {
      campaignDaysToHoldPFP: value,
    });
  };

  return {
    fetchNftMetadata,
    changeNftCollectionAddress,
    changeDaysToHoldPFP,
    campaignDaysToHoldPFP,
    selectedFileNftPfpImage,
    rewardingNftColectionAddress,
    rewardingNftColectionMetadata,
  };
};
export default useNftPfpRequirements;
