<template>
  <div
    class="applicant-tooltip"
    :class="[
      { active: currentApplicant.flag === 'active' },
      { completed: currentApplicant.flag === 'completed' },
      { winner: currentApplicant.flag === 'winner' },
      { failed: currentApplicant.flag === 'failed' },
    ]"
  >
    <div class="flag">
      <img
        v-if="currentApplicant.flag === 'winner'"
        src="@/Common/Icons/CampaignSinglePage/winnerIcon.svg"
        alt="Winner Icon"
        class="applicant-icon"
      />
      <div v-else class="dot"></div>
      {{ currentApplicant.flag }}
    </div>
    <div class="applicant-profile">
      <img
        class="applicant-profile"
        v-if="
          currentApplicant.proofType !== 'post' &&
          currentApplicant.flag !== 'failed' &&
          currentApplicant.flag !== 'joined'
        "
        alt="Bro"
        :src="getImgUrl(currentApplicant.awardingCollImagePath)"
      />
      <div v-else class="applicant-profile placeholder-bg-1"></div>
    </div>
    <ul class="epilogue-text-alt">
      <li>
        <a
          :href="
            'https://polygonscan.com/address/' + currentApplicant.walletAddress
          "
          target="_blank"
        >
          <img
            v-if="currentApplicant.walletAddress"
            src="@/Common/Icons/CampaignSinglePage/walletIcon.svg"
            alt="Wallet Address"
            class="applicant-icon"
          />
          {{ condenseAddress(currentApplicant.walletAddress, 5, 4) }}
        </a>
      </li>
      <li>
        <a
          :href="'https://twitter.com/' + currentApplicant.twitterHandle"
          target="_blank"
        >
          <img
            v-if="applicant.twitterHandle"
            src="@/Common/Icons/CampaignSinglePage/twitterIcon.svg"
            alt="Twitter Handle"
            class="applicant-icon"
          />
          {{ addEllipsis(currentApplicant.twitterHandle) }}
        </a>
      </li>
    </ul>
  </div>
</template>
<script>
import { onMounted, watch, ref } from "vue";

export default {
  name: "ApplicantTooltip",
  components: {},
  props: {
    applicant: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const currentApplicant = ref(props.applicant);

    const getImgUrl = (imagePath) => {
      return process.env.VUE_APP_IMAGE_URL + imagePath;
    };

    const condenseAddress = (address, lengthLeft, lengthRight) => {
      if (address) {
        const addressLeftPart = address.substring(0, lengthLeft);
        const addressRightPart = address.substring(42 - lengthRight, 42);
        return `${addressLeftPart}...${addressRightPart}`;
      }
    };

    const addEllipsis = (handle) => {
      return handle.length > 14 ? handle.substring(0, 13) + "..." : handle;
    };

    watch(
      () => props.applicant,
      async (newVal, oldVal) => {
        oldVal;
        currentApplicant.value = newVal;
      }
    );

    onMounted(async () => {});
    return {
      getImgUrl,
      addEllipsis,
      condenseAddress,
      currentApplicant,
    };
  },
};
</script>
<style lang="scss" scoped src="@/Common/Styles/applicantTooltip.scss"></style>
