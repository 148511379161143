import { computed } from "vue";
import useAuth from "@/composables/useAuth";
import { ethereumService, eventBus } from "@/main";
import useCampaign from "@/composables/useCampaign";
import useUtilities from "@/composables/useUtilities";
import useMintAirBro from "@/composables/useMintAirBro";
import useSendExistingToken from "@/composables/useSendExistingToken";
import useNftPfpRequirements from "@/composables/useNftPfpRequirements";
import useCustomPfpRequirements from "@/composables/useCustomPfpRequirements";
import usePostContentRequirements from "@/composables/usePostContentRequirements";
import useAditionalRequirements from "@/composables/useAditionalRequirements";

const useCreateCampaignDrop = (store) => {
  const { networkName, userId, signerAppWallet } = useAuth(store);
  const socialNetwork = computed(() => {
    return store.getters["transactions/getByKey"]("socialNetwork");
  });
  const {
    convertFromWei,
    makeContarctAddressFromTopics,
    formatDateTime,
    formatDateGeneratedNft,
    makeFileObject,
  } = useUtilities();
  const {
    selectedFileCustomPfpImage,
    campaignDaysToHoldPFPCustom,
  } = useCustomPfpRequirements(store);
  const {
    rewardingNftColectionAddress,
   
    selectedFileNftPfpImage,
    rewardingNftColectionMetadata,
    fetchNftMetadata,
    campaignDaysToHoldPFP,
  } = useNftPfpRequirements(store);
  const {
    postContent,
    pickWinnersType,
    utmWebsiteURL,
    utmCampaignID,
    utmCampaignSource,
    utmCampaignMedium,
    utmCampaignName,
    utmCampaignTerm,
  } = usePostContentRequirements(store);
  const {
    txHashBackend,
    getTokenType,
    isGaslessClaim,
    getCampaignType,
    getCampaignName,
    getCampaignDescription,
    getCampaignStartDate,
    getCampaignEndDate,
    getNumberOfPrticipatns,
    getCampaignRewardComponentName,
    sendCampaignDataImages,
    sendCampaignData,
    buttonDisabled,
    getMaxNumberOfWinners,
    fetchNftMetadataReqCollection,
    reqCollectionNftMetadata,
  } = useCampaign(store);
  const {
    rewardName,
    symbolName,
    isSoulboundNft,
    localSelectedFile,
    localDescription,
    fileIPFSMetadata,
    storeToIPFS,
    generateNftVideo,
    generateNftVideoName,
    videoClassActive,
    storeToIPFSWalletConnect,
  } = useMintAirBro(store);
  const { selectedTokenAddress, totalAirdropAmount } =
    useSendExistingToken(store);

  const { numberOfFolowers, accountDateCreation, ownershipNftAddress } =
    useAditionalRequirements(store);

  const trackCampaignCreated = (id) => {
    if (process.env.VUE_APP_ENV !== "production") return;
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "campaign_created",
      campaignId: id,
    });
  };
  const generateVideoNft = async () => {
    let camapignDuration = [
      formatDateGeneratedNft(getCampaignStartDate.value),
      formatDateGeneratedNft(getCampaignEndDate.value),
    ].join("-");
    let data = {
      title: getCampaignName.value,
      date: camapignDuration,
    };
    await generateNftVideo(data);
    const videoUrl =
      process.env.VUE_APP_VIDEOS_URL + generateNftVideoName.value;
    const generatedVideo = await makeFileObject(
      videoUrl,
      generateNftVideoName.value
    );
    store.dispatch("mintAirBro/commitByKey", {
      localSelectedFile: generatedVideo,
    });
  };
  // REWARD BY TOKEN
  const rewardHoldersByExistingToken = async () => {
    const fd = new FormData();
    if (getCampaignType.value !== "post") {
      fd.append(
        "awarding",
        getCampaignType.value === "image"
          ? selectedFileCustomPfpImage.value
          : selectedFileNftPfpImage.value,
        getCampaignType.value === "image"
          ? selectedFileCustomPfpImage.value.name
          : selectedFileNftPfpImage.value.name
      );
    }
    if (getTokenType.value === "nft")
      fd.append("award", localSelectedFile.value, localSelectedFile.name);

    if (getCampaignType.value === "nft") {
      const options = {
        address: rewardingNftColectionAddress.value,
        chain: networkName.value,
      };
      await fetchNftMetadata(options);
    }
    if (ownershipNftAddress.value) {
      const options = {
        address: ownershipNftAddress.value,
        chain: networkName.value,
      };
      await fetchNftMetadataReqCollection(options);
    }
    const tx = await ethereumService.createExistingERC20DropCampaign(
      selectedTokenAddress.value.value,
      totalAirdropAmount.value
    );
    store.dispatch("campaign/commitByKey", {
      txHashBackend: tx.hash,
    });

    const data = {
      campaign: {
        name: getCampaignName.value,
        description: getCampaignDescription.value,
        gasless: isGaslessClaim.value,
        creatorId: userId.value,
        startDate: new Date(getCampaignStartDate.value).toISOString(),
        endDate: new Date(getCampaignEndDate.value).toISOString(),
        ...(getNumberOfPrticipatns.value && {
          maxApplicants: Number(getNumberOfPrticipatns.value),
        }),
        profileCreated: accountDateCreation.value
          ? new Date(formatDateTime(accountDateCreation.value)).toISOString()
          : null,
        ...(numberOfFolowers.value && {
          profileFollowers: Number(numberOfFolowers.value),
        }),
        socialNetwork: socialNetwork.value,
        ...(getMaxNumberOfWinners.value && {
          maxWinners: Number(getMaxNumberOfWinners.value),
        }),
        ...(ownershipNftAddress.value && {
          tokenRequirements: ownershipNftAddress.value,
        }),
        ...(ownershipNftAddress.value && {
          tokenReqName: reqCollectionNftMetadata.value.name,
        }),
        ...(getCampaignType.value === "post" && {
          sharingPost: {
            title: postContent.value,
            ...(getMaxNumberOfWinners.value && {
              pickWinners: pickWinnersType.value,
            }),
          },
        }),
        ...(utmWebsiteURL.value &&
          utmCampaignSource.value && {
            utmLinkParams: {
              url: utmWebsiteURL.value,
              campaignId: utmCampaignID.value,
              campaignSource: utmCampaignSource.value,
              campaignMedium: utmCampaignMedium.value,
              campaignName: utmCampaignName.value,
              campaignTerm: utmCampaignTerm.value,
            },
          }),
        ...(getCampaignType.value !== "post" && {
          holdDuration:
            getCampaignType.value === "image"
              ? Number(campaignDaysToHoldPFPCustom.value)
              : Number(campaignDaysToHoldPFP.value),
        }),
      },
      airdrop: {
        type: getTokenType.value,
        proofType: getCampaignType.value,
        supply: Number(convertFromWei(totalAirdropAmount.value)),
        ...(getCampaignType.value === "nft" && {
          awardingCollectionAddress: rewardingNftColectionAddress.value,
        }),
        ...(getCampaignType.value === "nft" && {
          awardingCollectionName: rewardingNftColectionMetadata.value.name,
        }),
        awardName: selectedTokenAddress.value.label || rewardName.value,
        awardDescription: localDescription.value,
        awardTokenAddress: selectedTokenAddress.value.value || "",
        nonTranferableNft: isSoulboundNft.value,
        creatorId: userId.value,
        transactionHash: txHashBackend.value,
      },
    };
    let campaignId;
    if (tx.hash !== "rejected") {
      const campaignData = await sendCampaignData(data);
      campaignId = campaignData.airDrop.campaignId;
      if (getCampaignType.value !== "post")
        await sendCampaignDataImages(campaignData.airDrop.campaignId, fd);
      store.dispatch("transactions/commitByKey", {
        currentCampaignData: campaignData,
      });
    } else {
      store.dispatch("campaign/commitByKey", {
        buttonDisabled: false,
      });
    }
    const res = await tx.wait();
    if (res) {
      const campaignContractAddress = makeContarctAddressFromTopics(
        res.logs[0].topics[1],
        26
      );
      const approveTokenTx = await ethereumService.approveExistingERC20(
        selectedTokenAddress.value.value,
        campaignContractAddress,
        totalAirdropAmount.value
      );
      if (approveTokenTx) {
        await ethereumService.fundExistingERC20Contarct(
          campaignContractAddress
        );
      }
    }
    resetState();
    if (tx.hash !== "rejected") {
      trackCampaignCreated(campaignId);
    }
    buttonDisabled.value = false;
    return res;
  };

  //REWARD BY NFT
  const rewardHoldersByNFT = async () => {
    if (videoClassActive.value) await generateVideoNft();
    const ipsfFile = new File(
      [localSelectedFile.value],
      "airbro" + new Date().getTime()
    );
    if (signerAppWallet.value === "metamask") {
      await storeToIPFS(ipsfFile);
    }
    if (signerAppWallet.value === "walletConnect") {
      await storeToIPFSWalletConnect(ipsfFile);
    }
    const fd = new FormData();
    if (getCampaignType.value !== "post") {
      fd.append(
        "awarding",
        getCampaignType.value === "image"
          ? selectedFileCustomPfpImage.value
          : selectedFileNftPfpImage.value,
        getCampaignType.value === "image"
          ? selectedFileCustomPfpImage.value.name
          : selectedFileNftPfpImage.value.name
      );
    }
    if (getTokenType.value == "nft" && !videoClassActive.value)
      fd.append("award", localSelectedFile.value, localSelectedFile.name);

    if (getCampaignType.value === "nft") {
      const options = {
        address: rewardingNftColectionAddress.value,
        chain: networkName.value,
      };
      await fetchNftMetadata(options);
    }
    if (ownershipNftAddress.value) {
      const options = {
        address: ownershipNftAddress.value,
        chain: networkName.value,
      };
      await fetchNftMetadataReqCollection(options);
    }

    const tx = await ethereumService.createNewERC1155DropCampaign(
      rewardName.value,
      symbolName.value,
      fileIPFSMetadata.value
    );

    store.dispatch("campaign/commitByKey", {
      txHashBackend: tx.hash,
    });

    const data = {
      campaign: {
        name: getCampaignName.value,
        description: getCampaignDescription.value,
        gasless: isGaslessClaim.value,
        creatorId: userId.value,
        startDate: new Date(getCampaignStartDate.value).toISOString(),
        endDate: new Date(getCampaignEndDate.value).toISOString(),
        ...(getNumberOfPrticipatns.value && {
          maxApplicants: Number(getNumberOfPrticipatns.value),
        }),
        profileCreated: accountDateCreation.value
          ? new Date(formatDateTime(accountDateCreation.value)).toISOString()
          : null,
        ...(numberOfFolowers.value && {
          profileFollowers: Number(numberOfFolowers.value),
        }),
        socialNetwork: socialNetwork.value,
        ...(getMaxNumberOfWinners.value && {
          maxWinners: Number(getMaxNumberOfWinners.value),
        }),
        ...(ownershipNftAddress.value && {
          tokenRequirements: ownershipNftAddress.value,
        }),
        ...(ownershipNftAddress.value && {
          tokenReqName: reqCollectionNftMetadata.value.name,
        }),
        ...(getCampaignType.value === "post" && {
          sharingPost: {
            title: postContent.value,
            ...(getMaxNumberOfWinners.value && {
              pickWinners: pickWinnersType.value,
            }),
          },
        }),
        ...(utmWebsiteURL.value &&
          utmCampaignSource.value && {
            utmLinkParams: {
              url: utmWebsiteURL.value,
              campaignId: utmCampaignID.value,
              campaignSource: utmCampaignSource.value,
              campaignMedium: utmCampaignMedium.value,
              campaignName: utmCampaignName.value,
              campaignTerm: utmCampaignTerm.value,
            },
          }),
        ...(getCampaignType.value !== "post" && {
          holdDuration:
            getCampaignType.value === "image"
              ? Number(campaignDaysToHoldPFPCustom.value)
              : Number(campaignDaysToHoldPFP.value),
        }),
      },
      airdrop: {
        type: getTokenType.value,
        proofType: getCampaignType.value,
        ...(getCampaignType.value === "nft" && {
          awardingCollectionAddress: rewardingNftColectionAddress.value,
        }),
        ...(getCampaignType.value === "nft" && {
          awardingCollectionName: rewardingNftColectionMetadata.value.name,
        }),
        awardName: selectedTokenAddress.value.label || rewardName.value,
        awardDescription: localDescription.value,
        nonTranferableNft: isSoulboundNft.value,
        creatorId: userId.value,
        transactionHash: txHashBackend.value,
      },
    };
    let campaignId;
    if (tx.hash !== "rejected") {
      const campaignData = await sendCampaignData(data);
      campaignId = campaignData.airDrop.campaignId;

      await sendCampaignDataImages(campaignData.airDrop.campaignId, fd);
      store.dispatch("transactions/commitByKey", {
        currentCampaignData: campaignData,
      });
    } else {
      store.dispatch("campaign/commitByKey", {
        buttonDisabled: false,
      });
    }
    const res = await tx.wait();

    resetState();
    if (tx.hash !== "rejected") {
      trackCampaignCreated(campaignId);
    }
    buttonDisabled.value = false;
    return res;
  };

  //REWARD BY SOULBOUND NFT
  const rewardHoldersBySoulboundNFT = async () => {
    if (videoClassActive.value) await generateVideoNft();
    const ipsfFile = new File(
      [localSelectedFile.value],
      "airbro" + new Date().getTime()
    );
    if (signerAppWallet.value === "metamask") {
      await storeToIPFS(ipsfFile);
    }
    if (signerAppWallet.value === "walletConnect") {
      await storeToIPFSWalletConnect(ipsfFile);
    }

    const fd = new FormData();
    if (getCampaignType.value !== "post") {
      fd.append(
        "awarding",
        getCampaignType.value === "image"
          ? selectedFileCustomPfpImage.value
          : selectedFileNftPfpImage.value,
        getCampaignType.value === "image"
          ? selectedFileCustomPfpImage.value.name
          : selectedFileNftPfpImage.value.name
      );
    }
    if (getTokenType.value === "nft" && !videoClassActive.value)
      fd.append("award", localSelectedFile.value, localSelectedFile.name);

    if (getCampaignType.value === "nft") {
      const options = {
        address: rewardingNftColectionAddress.value,
        chain: networkName.value,
      };
      await fetchNftMetadata(options);
    }
    if (ownershipNftAddress.value) {
      const options = {
        address: ownershipNftAddress.value,
        chain: networkName.value,
      };
      await fetchNftMetadataReqCollection(options);
    }
    const tx = await ethereumService.createNewSB1155DropCampaign(
      rewardName.value,
      symbolName.value,
      fileIPFSMetadata.value
    );
    store.dispatch("campaign/commitByKey", {
      txHashBackend: tx.hash,
    });

    const data = {
      campaign: {
        name: getCampaignName.value,
        description: getCampaignDescription.value,
        gasless: isGaslessClaim.value,
        creatorId: userId.value,
        startDate: new Date(getCampaignStartDate.value).toISOString(),
        endDate: new Date(getCampaignEndDate.value).toISOString(),
        ...(getNumberOfPrticipatns.value && {
          maxApplicants: Number(getNumberOfPrticipatns.value),
        }),
        profileCreated: accountDateCreation.value
          ? new Date(formatDateTime(accountDateCreation.value)).toISOString()
          : null,
        ...(numberOfFolowers.value && {
          profileFollowers: Number(numberOfFolowers.value),
        }),
        socialNetwork: socialNetwork.value,
        ...(getMaxNumberOfWinners.value && {
          maxWinners: Number(getMaxNumberOfWinners.value),
        }),
        ...(ownershipNftAddress.value && {
          tokenRequirements: ownershipNftAddress.value,
        }),
        ...(ownershipNftAddress.value && {
          tokenReqName: reqCollectionNftMetadata.value.name,
        }),
        ...(getCampaignType.value === "post" && {
          sharingPost: {
            title: postContent.value,
            ...(getMaxNumberOfWinners.value && {
              pickWinners: pickWinnersType.value,
            }),
          },
        }),
        ...(utmWebsiteURL.value &&
          utmCampaignSource.value && {
            utmLinkParams: {
              url: utmWebsiteURL.value,
              campaignId: utmCampaignID.value,
              campaignSource: utmCampaignSource.value,
              campaignMedium: utmCampaignMedium.value,
              campaignName: utmCampaignName.value,
              campaignTerm: utmCampaignTerm.value,
            },
          }),
        ...(getCampaignType.value !== "post" && {
          holdDuration:
            getCampaignType.value === "image"
              ? Number(campaignDaysToHoldPFPCustom.value)
              : Number(campaignDaysToHoldPFP.value),
        }),
      },
      airdrop: {
        type: getTokenType.value,
        proofType: getCampaignType.value,
        ...(getCampaignType.value === "nft" && {
          awardingCollectionAddress: rewardingNftColectionAddress.value,
        }),
        ...(getCampaignType.value === "nft" && {
          awardingCollectionName: rewardingNftColectionMetadata.value.name,
        }),
        awardName: selectedTokenAddress.value.label || rewardName.value,
        awardDescription: localDescription.value,
        nonTranferableNft: isSoulboundNft.value,
        creatorId: userId.value,
        transactionHash: txHashBackend.value,
      },
    };
    let campaignId;
    if (tx.hash !== "rejected") {
      const campaignData = await sendCampaignData(data);
      campaignId = campaignData.airDrop.campaignId;
      await sendCampaignDataImages(campaignData.airDrop.campaignId, fd);
      store.dispatch("transactions/commitByKey", {
        currentCampaignData: campaignData,
      });
    } else {
      store.dispatch("campaign/commitByKey", {
        buttonDisabled: false,
      });
    }
    const res = await tx.wait();
    resetState();
    if (tx.hash !== "rejected") {
      trackCampaignCreated(campaignId);
    }
    buttonDisabled.value = false;
    return res;
  };

  const resetState = () => {
    eventBus.emit("clearFile:SelectedFile");
    eventBus.emit("clearFile:SelectedFilePfp");
    eventBus.emit("clearFile:SelectedFileNftPfp");
    store.dispatch("mintAirBro/resetState");
    store.dispatch("moralis/resetState");
    store.dispatch("campaign/resetState");
    store.dispatch("customPfpRequirements/resetState");
    store.dispatch("nftPfpRequirements/resetState");
    store.dispatch("sendExistingToken/resetState");
    eventBus.emit("resetErrors");
    store.dispatch("campaign/commitByKey", {
      buttonDisabled: false,
    });
    store.dispatch("transactions/commitByKey", {
      isCampaignShareModalActive: true,
    });
  };

  const handleSubmit = () => {
    store.dispatch("campaign/commitByKey", {
      buttonDisabled: true,
    });
    if (getCampaignRewardComponentName.value === "MintAirBro") {
      if (isSoulboundNft.value) {
        return rewardHoldersBySoulboundNFT();
      }
      return rewardHoldersByNFT();
    }
    return rewardHoldersByExistingToken();
  };

  return {
    handleSubmit,
    rewardHoldersByNFT,
    rewardHoldersBySoulboundNFT,
    rewardHoldersByExistingToken,
  };
};

export default useCreateCampaignDrop;
