import { ethereumService } from "@/main";

const initialState = () => ({
  errors: [],
  currentCampaignData: {},
  isCampaignShareModalActive: false,
  isTarnsationSuccess: false,
  isTransactionFinished: true,
  txHash: "",
  tokenToSendComponent: "",
  socialNetwork: "",
});
const state = initialState();

const getters = {
  getByKey: (state) => (key) => state[key],
};
const mutations = {
  commitByKey(state, object) {
    Object.keys(object).forEach((key) => (state[key] = object[key]));
  },
  setByKey({ commit }, obj) {
    commit("setByKey", obj);
  },
  pushError(state, error) {
    state.errors.push(error);
  },
  clearErrors(state) {
    state.errors.splice(0);
  },
  setIsTransactionFinished(state, isTransactionFinished) {
    state.isTransactionFinished = isTransactionFinished;
  },
  setIsTarnsationSuccess(state, isTarnsationSuccess) {
    state.isTarnsationSuccess = isTarnsationSuccess;
  },
  setTxHash(state, txHash) {
    state.txHash = txHash;
  },
  resetState(state) {
    Object.assign(state, initialState());
  },
};
const actions = {
  resetState({ commit }) {
    commit("resetState");
  },
  commitByKey({ commit }, object) {
    commit("commitByKey", object);
  },

  async rewardNftToNftHolders(
    { commit },
    {
      rewardedNftCollection,
      newNftCollectionName,
      newNftCollectionSymbol,
      newNftSupply,
      baseUri,
    }
  ) {
    try {
      commit("setIsTransactionFinished", false);
      const txHash = await ethereumService.dropNftsToNftHolders(
        rewardedNftCollection,
        newNftCollectionName,
        newNftCollectionSymbol,
        newNftSupply,
        baseUri
      );
      commit("setTxHash", txHash.hash);
      return txHash;
    } catch (error) {
      commit("pushError", error);
    }
  },

  async dropNewTokensToNftHolders(
    { commit },
    { rewardedNftCollection, newTokenName, newTokenSymbol, tokensPerClaimNew }
  ) {
    try {
      commit("setIsTransactionFinished", false);
      const txHash = await ethereumService.dropNewTokensToNftHolders(
        rewardedNftCollection,
        newTokenName,
        newTokenSymbol,
        tokensPerClaimNew
      );
      commit("setTxHash", txHash.hash);
      return txHash;
    } catch (error) {
      commit("pushError", error);
    }
  },

  async dropExistingTokensToNftHolders(
    { commit },
    {
      rewardedNftCollection,
      tokensPerClaimExisting,
      rewardToken,
      totalAirdropAmount,
    }
  ) {
    try {
      commit("setIsTransactionFinished", false);
      const txHash = await ethereumService.dropExistingTokensToNftHolders(
        rewardedNftCollection,
        tokensPerClaimExisting,
        rewardToken,
        totalAirdropAmount
      );
      commit("setTxHash", txHash.hash);
      return txHash;
    } catch (error) {
      commit("pushError", error);
    }
  },
  async dropExisting1155NftsToNftHolders(
    { commit },
    {
      rewardedNftCollection,
      reward1155Nft,
      tokensPerClaim,
      tokenId,
      totalAirdropAmount,
    }
  ) {
    try {
      commit("setIsTransactionFinished", false);
      const txHash = await ethereumService.dropExisting1155NftsToNftHolders(
        rewardedNftCollection,
        reward1155Nft,
        tokensPerClaim,
        tokenId,
        totalAirdropAmount
      );
      commit("setTxHash", txHash.hash);
      return txHash;
    } catch (error) {
      commit("pushError", error);
    }
  },
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
