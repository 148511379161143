<template>
  <div class="book-demo-wrapper">
    <div class="gray-gradient"></div>
    <div class="custom-container">
      <section>
        <div class="image-wrapper">
          <img
            class="image"
            src="@/Common/Icons/HomePage/broWithCoffee.svg"
            alt="Bro With Coffee"
            style="width: 300px"
          />
        </div>
        <div class="info-card">
          <h2 class="epilogue-title">
            100% FREE <span class="betterbrush-title blue-base">+</span> We’ll
            do it for you
          </h2>
          <p class="epilogue-text-dark">
            Schedule a 15-min demo and find out how to increase brand awareness
            with community rewards.
          </p>
          <app-button
            text="BOOK A DEMO"
            buttonClass="primary-button-on-light"
            type="button"
            @click="openLink('https://calendly.com/airbro/30min')"
          />
        </div>
      </section>
    </div>
  </div>
</template>
<script>
import AppButton from "@/elements/AppButton";

export default {
  name: "BookDemo",
  components: {
    AppButton,
  },

  setup() {
    const openLink = (url) => {
      window.open(url, "_blank");
    };

    return {
      openLink,
    };
  },
};
</script>
<style lang="scss" scoped src="@/Common/Styles/airBroHome.scss"></style>
