<template>
  <modal :modalActive="modalActive" :modalClass="'creating-campaign-modal'">
    <div class="creating-campaign-loader">
      <img src="@/Common/Icons/AirBroLoaderPurple.gif" alt="Loader" />
    </div>
    <div class="info-modal-text">
      <p class="campaign-creation-text">Campaign is being Created ...</p>
      <p class="please-wait-text">Please wait</p>
    </div>
  </modal>
</template>
<script>
import Modal from "@/components/Modal";
export default {
  components: {
    Modal,
  },
  setup() {
    return {};
  },
};
</script>
