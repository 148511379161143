<template>
  <section class="reward-card">
    <a
      :href="'https://polygonscan.com/address/' + campaignData.contractAddress"
      class="network"
      target="_blank"
    >
      <div class="image-tooltip-wrapper">
        <img
          src="@/Common/Icons/CampaignSinglePage/networkIcon.svg"
          alt="Network"
          class="network-icon"
        />
        <div class="go-to-polygon-tooltip">
          <p class="polygon-text">POLYGON</p>
        </div>
      </div>
    </a>
    <div class="card">
      <div class="card-image">
        <div v-if="campaignData.type === 'nft'" class="hexagon wrapper">
          <video
            controls
            autoplay
            muted
            width="180"
            height="180"
            class="hexagon reward"
            v-if="checkFileMimeType(campaignData.awardMimeType, 'video')"
            :src="getImgUrl(campaignData.awardPath)"
          ></video>
          <div
            class="hexagon reward audio-wrap"
            v-if="checkFileMimeType(campaignData.awardMimeType, 'audio')"
          >
            <audio
              autoplay
              muted
              controls
              preload="auto"
              :src="getImgUrl(campaignData.awardPath)"
            ></audio>
          </div>
          <img
            class="hexagon reward"
            v-if="checkFileMimeType(campaignData.awardMimeType, 'image')"
            :src="getImgUrl(campaignData.awardPath)"
            alt="NFT Reward"
          />
        </div>
        <div v-else-if="campaignData.type === 'token'" class="circle wrapper">
          <img
            class="circle reward"
            :src="getTokenImgUrl(campaignData.awardName)"
            alt="Token Reward"
          />
        </div>
      </div>
      <div class="card-header">
        <h3 class="epilogue-text-m-dark">
          <template v-if="campaignData.type === 'nft'">NFT Reward</template>
          <template v-else-if="campaignData.type === 'token'"
            >Token Reward</template
          >
        </h3>
        <h2 class="epilogue-subtitle-alt">
          <template v-if="campaignData.type === 'nft'">{{
            campaignData.awardName
          }}</template>
          <template v-else-if="campaignData.type === 'token'"
            >{{ campaignData.supply }}
            {{ campaignData.awardName }} Tokens</template
          >
        </h2>
        <div class="tags">
          <span class="tag epilogue-xs">
            <img
              src="@/Common/Icons/CampaignSinglePage/box.svg"
              alt="Everyone Wins"
              class="tag-icon"
            />
            <template v-if="campaignData.maxWinners === 0"
              >Everyone Wins</template
            >
            <template v-else>
              {{ campaignData.maxWinners }} Winner<template
                v-if="campaignData.maxWinners > 1"
                >s</template
              ></template
            >
          </span>
          <span v-if="campaignData.maxApplicants > 0" class="tag epilogue-xs">
            <img
              src="@/Common/Icons/CampaignSinglePage/maxParticipantsIcon.svg"
              alt="Max Applicants"
              class="tag-icon"
            />
            {{ campaignData.maxApplicants }} Participant<template
              v-if="campaignData.maxApplicants > 1"
              >s</template
            >
            Max
          </span>
          <span v-if="campaignData.gaslessClaim" class="tag epilogue-xs">
            <img
              src="@/Common/Icons/CampaignSinglePage/gaslessClaim.svg"
              alt="Gasless Claim"
              class="tag-icon"
            />
            Gasless Claim
          </span>
          <span v-if="campaignData.nonTranferableNft" class="tag epilogue-xs">
            <img
              src="@/Common/Icons/CampaignSinglePage/nonTransferrable.svg"
              alt="Non-transferrable NFT"
              class="tag-icon"
            />
            Non-transferrable
          </span>
        </div>
      </div>
      <div class="card-body">
        <span
          v-if="!rewardCardExpand"
          class="expand-button epilogue-link"
          @click="toggleRewardCardExpand"
          >more info</span
        >
        <div v-else class="description">
          <p
            v-if="campaignData.awardDescription !== ''"
            class="epilogue-text-alt"
          >
            {{ campaignData.awardDescription }}
          </p>
          <p v-else class="epilogue-text-alt">No Description Provided</p>
          <span
            class="expand-button epilogue-link"
            @click="toggleRewardCardExpand"
            >less info</span
          >
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { ref, onMounted } from "vue";
import useUtilities from "@/composables/useUtilities";

export default {
  name: "CampaignRewardCard",
  components: {},
  props: {
    campaignData: {
      type: Object,
      default: () => {},
    },
  },
  setup() {
    const rewardCardExpand = ref(false);
    const { checkFileMimeType } = useUtilities();

    const toggleRewardCardExpand = () => {
      rewardCardExpand.value = !rewardCardExpand.value;
    };

    const getImgUrl = (imagePath) => {
      return process.env.VUE_APP_IMAGE_URL + imagePath;
    };

    const getTokenImgUrl = (tokenName) => {
      switch (tokenName) {
        case "USDC":
          return require(`@/Common/Icons/Tokens/usdcToken.svg`);
        case "USDT":
          return require(`@/Common/Icons/Tokens/usdtToken.svg`);
        case "MATIC":
          return require(`@/Common/Icons/Tokens/polygonToken.svg`);
        case "BNB":
          return require(`@/Common/Icons/Tokens/bnbToken.svg`);
        case "DAI":
          return require(`@/Common/Icons/Tokens/daiToken.svg`);
        default:
          return require(`@/Common/Icons/Tokens/polygonToken.svg`);
      }
    };

    onMounted(async () => {});
    return {
      getImgUrl,
      getTokenImgUrl,
      rewardCardExpand,
      checkFileMimeType,
      toggleRewardCardExpand,
    };
  },
};
</script>
<style lang="scss" scoped src="@/Common/Styles/rewardCard.scss"></style>
