<template>
  <div>
    <div class="post-content-explanation-text">
      <div>
        <img
          src="@/Common/Icons/infoIcon.svg"
          alt="image-regular-twitter-pfp"
        />
      </div>
      <div class="post-content-text">
        <p>
          In the field below add content that participants will have to tweet in
          order to be eligible for a reward.
        </p>
      </div>
    </div>
    <div class="post-content-wrapper">
      <div class="content-wrapper">
        <app-textarea
          type="text"
          v-model="postContent"
          labelClass="label"
          labelText="Text Required"
          name="PostContentCampaignDescription"
          textAreaClass="form-box-textarea"
          placeholder="Enter text that your participants need to post in order to receive reward... "
          cols="1"
          rows="6"
          @change="handleChangePostContent(postContent)"
        />
        <div class="pick-winners" v-if="getMaxNumberOfWinners">
          <div class="post-content-explanation-text">
            <div>
              <img
                src="@/Common/Icons/infoIcon.svg"
                alt="image-regular-twitter-pfp"
              />
            </div>
            <div class="post-content-text">
              <p>
                {{ explanationText === "random" ? random : mostLiked }}
              </p>
            </div>
          </div>
          <div class="tab-winners-buttons-wrap">
            <div>
              <app-button
                text="RANDOM"
                class="post-content-winners-tab-button random"
                :class="{
                  active: explanationText === 'random',
                }"
                @click.prevent="changeText('random')"
              />
            </div>
            <div>
              <app-button
                text="MOST LIKED"
                class="post-content-winners-tab-button most-liked"
                :class="{
                  active: explanationText === 'mostLiked',
                }"
                @click.prevent="changeText('mostLiked')"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { useStore } from "vuex";
// import moment from "moment";
import AppButton from "@/elements/AppButton";
import AppTextarea from "@/elements/AppTextarea";
import useCampaign from "@/composables/useCampaign";
import usePostContentRequirements from "@/composables/usePostContentRequirements";

export default {
  components: {
    AppButton,
    AppTextarea,
  },
  setup() {
    const store = useStore();
    const { getMaxNumberOfWinners } = useCampaign(store);
    const { changePostContent } = usePostContentRequirements(store);

    const postContent = ref("");
    const numberOfFolowers = ref("");
    const accountDateCreation = ref("");
    const requirementNftAddress = ref("");
    const explanationText = ref("random");
    const websiteURL = ref("");
    const utmCampaignID = ref("");
    const utmCampaignSource = ref("");
    const utmCampaignMedium = ref("");
    const utmCampaignName = ref("");
    const utmCampaignTerm = ref("");

    // const campaignEndDate = ref("");

    const random = ref(
      "Pick the winners Randomly, if the max number of winners is set, we will randomly pick them out"
    );
    const mostLiked = ref(
      "Winners will be the only participants who get the most liked tweet"
    );

    const handleChangePostContent = (postContent) => {
      changePostContent(postContent);
      // if (getCampaignStartDate.value !== "") {
      //   campaignEndDate.value = moment(getCampaignStartDate.value)
      //     .add(postVerificationTimeInMinutes.value, "minutes")
      //     .format("YYYY-MM-DD HH:mm");
      //   changeCampaignEndDate(campaignEndDate.value);
      // }
    };

    const changeText = (type) => {
      explanationText.value = type;
      store.dispatch("postContentRequirements/commitByKey", {
        pickWinnersType: explanationText.value,
      });
    };

    return {
      random,
      mostLiked,
      changeText,
      websiteURL,
      postContent,
      utmCampaignID,
      explanationText,
      utmCampaignTerm,
      utmCampaignName,
      numberOfFolowers,
      changePostContent,
      utmCampaignSource,
      utmCampaignMedium,
      accountDateCreation,
      getMaxNumberOfWinners,
      requirementNftAddress,
      handleChangePostContent,
    };
  },
};
</script>
<style lang="scss" scoped src="@/Common/Styles/postContent.scss"></style>
