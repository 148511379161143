import Moralis from "@/plugins/moralis";

const initialState = () => ({
  rewardingNftColectionAddress: "",
  rewardingNftColectionMetadata: {},
  selectedFileImage: {},
  campaignDaysToHoldPFP: "",
  errors: [],
});
const state = initialState();

const getters = {
  getByKey: (state) => (key) => state[key],
};

const mutations = {
  commitByKey(state, object) {
    Object.keys(object).forEach((key) => (state[key] = object[key]));
  },
  setRewardingNftColectionMetadata(state, rewardingNftColectionMetadata) {
    state.rewardingNftColectionMetadata = rewardingNftColectionMetadata;
  },
  pushError(state, error) {
    state.errors.push(error);
  },
  clearErrors(state) {
    state.errors.splice(0);
  },
  resetState(state) {
    Object.assign(state, initialState());
  },
};

const actions = {
  resetState({ commit }) {
    commit("resetState");
  },
  commitByKey({ commit }, object) {
    commit("commitByKey", object);
  },
  async fetchNftMetadata({ commit }, options) {
    try {
      const response = await Moralis.Web3API.token.getNFTMetadata(options);
      commit("setRewardingNftColectionMetadata", response);
      return response;
    } catch (error) {
      commit("pushError", error);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
