// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAzNdk9agDLa2HueCiZlIq_hem2Zv8pK9M",
  authDomain: "vue-twitter-82cda.firebaseapp.com",
  projectId: "vue-twitter-82cda",
  storageBucket: "vue-twitter-82cda.appspot.com",
  messagingSenderId: "458207122951",
  appId: "1:458207122951:web:9139374cf60f68a9237ca2",
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

export default firebaseApp;
