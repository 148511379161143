import { computed, ref, watch } from "vue";

const useCampaign = (store) => {
  const isGaslessClaimLocal = ref("false");

  const modalMessage = computed(() =>
    store.getters["campaign/getByKey"]("modalMessage")
  );
  const modalMessageClass = computed(() =>
    store.getters["campaign/getByKey"]("modalMessageClass")
  );
  const buttonDisabled = computed(() =>
    store.getters["campaign/getByKey"]("buttonDisabled")
  );
  const getCampaignName = computed(() =>
    store.getters["campaign/getByKey"]("campaignName")
  );
  const componentName = computed(() =>
    store.getters["campaign/getByKey"]("componentTableName")
  );

  const getResponse = computed(() =>
    store.getters["campaign/getByKey"]("response")
  );

  const getCampaignType = computed(() =>
    store.getters["campaign/getByKey"]("campaignType")
  );

  const getTokenType = computed(() =>
    store.getters["campaign/getByKey"]("tokenType")
  );

  const isGaslessClaim = computed(
    () => store.getters["campaign/getIsGaslessClaim"]
  );

  const txHashBackend = computed(() =>
    store.getters["campaign/getByKey"]("txHashBackend")
  );

  const getCampaignStartDate = computed(() =>
    store.getters["campaign/getByKey"]("campaignStartDate")
  );
  const errors = computed(() => store.getters["campaign/getByKey"]("errors"));

  const getCampaignEndDate = computed(() =>
    store.getters["campaign/getByKey"]("campaignEndDate")
  );

  const getCampaignDescription = computed(() =>
    store.getters["campaign/getByKey"]("campaignDescription")
  );

  const getCampaignRegistrationPeriod = computed(() =>
    store.getters["campaign/getByKey"]("campaignRegistrationPeriod")
  );

  const getNumberOfPrticipatns = computed(() =>
    store.getters["campaign/getByKey"]("numberOfPrticipatns")
  );

  const getMaxNumberOfWinners = computed(() =>
    store.getters["campaign/getByKey"]("maxNumberOfWinners")
  );

  const getCampaignRequirementsComponentName = computed(() =>
    store.getters["campaign/getByKey"]("campaignRequirementsComponentName")
  );

  const getCampaignRewardComponentName = computed(() =>
    store.getters["campaign/getByKey"]("campaignRewardComponentName")
  );

  const componentKey = computed(() =>
    store.getters["campaign/getByKey"]("componentKey")
  );
  const campaignData = computed(() =>
    store.getters["campaign/getByKey"]("campaignData")
  );
  const alreadyJoinedCampaign = computed(() =>
    store.getters["campaign/getByKey"]("alreadyJoinedCampaign")
  );
  const singleCamapignData = computed(() =>
    store.getters["campaign/getByKey"]("singleCamapignData")
  );
  const reqCollectionNftMetadata = computed(() =>
    store.getters["campaign/getByKey"]("reqCollectionNftMetadata")
  );
  const nftsForContractForWallet = computed(() =>
    store.getters["campaign/getByKey"]("nftsForContractForWallet")
  );
  const socialProfileData = computed(() =>
    store.getters["campaign/getByKey"]("socialProfileData")
  );

  const singleCampaignApplicantPageData = computed(() =>
    store.getters["campaign/getByKey"]("singleCampaignApplicantPageData")
  );

  const sendCampaignData = (data) =>
    store.dispatch("campaign/sendCampaignData", data);

  const sendCampaignDataImages = (id, formData) =>
    store.dispatch("campaign/sendCampaignDataImages", { id, formData });

  const changeCampaignName = (value) => {
    store.dispatch("campaign/commitByKey", {
      campaignName: value,
    });
  };
  const setModalMessage = (value) => {
    store.dispatch("campaign/commitByKey", {
      modalMessage: value,
    });
  };
  const setModalMessageClass = (value) => {
    store.dispatch("campaign/commitByKey", {
      modalMessageClass: value,
    });
  };
  const changeCampaignStartDate = (value) => {
    store.dispatch("campaign/commitByKey", {
      campaignStartDate: value,
    });
  };

  const changeCampaignEndDate = (value) => {
    store.dispatch("campaign/commitByKey", {
      campaignEndDate: value,
    });
  };
  const changeCampaignDescription = (value) => {
    store.dispatch("campaign/commitByKey", {
      campaignDescription: value,
    });
  };
  const changeCampaignNumberOfParticipats = (value) => {
    store.dispatch("campaign/commitByKey", {
      numberOfPrticipatns: value,
    });
  };
  const changeCampaignMaxNumberOfWinners = (value) => {
    store.dispatch("campaign/commitByKey", {
      maxNumberOfWinners: value,
    });
  };
  const changeInputWalletAddress = (value) => {
    store.dispatch("users/commitByKey", {
      walletAddressInput: value,
    });
  };
  const getSocialProfileData = async (data) => {
    return await store.dispatch("campaign/getSocialProfileData", data);
  };

  const getCampaignStats = async () => {
    return await store.dispatch("campaign/getCampaignStats");
  };
  const getCampaignById = async (id) => {
    return await store.dispatch("campaign/getCampaignById", id);
  };
  const getCampaignByIdLogged = async (id) => {
    return await store.dispatch("campaign/getCampaignByIdLogged", id);
  };
  const getApplicantsByCampaignId = async (id, params) => {
    return await store.dispatch("campaign/getApplicantsByCampaignId", {
      id,
      params,
    });
  };
  const getCampaignWinners = async () => {
    const Id = store.getters["campaign/getByKey"]("campaignId");
    return await store.dispatch("campaign/getCampaignWinners", Id);
  };

  watch(isGaslessClaimLocal, (newValue) => {
    if (newValue) {
      store.dispatch("campaign/commitByKey", {
        isGaslessClaim: isGaslessClaimLocal.value,
      });
    }
  });
  watch(isGaslessClaim, (newValue) => {
    if (!newValue) {
      isGaslessClaimLocal.value = "false";
    }
  });

  const fetchNftMetadataReqCollection = (options) =>
    store.dispatch("campaign/fetchNftMetadataReqCollection", options);

  const fetchNFTsForContract = (options) =>
    store.dispatch("campaign/fetchNFTsForContract", options);

  return {
    getResponse,
    errors,
    campaignData,
    getTokenType,
    componentKey,
    modalMessage,
    buttonDisabled,
    isGaslessClaim,
    setModalMessage,
    componentName,
    txHashBackend,
    sendCampaignData,
    getCampaignStats,
    getCampaignType,
    getCampaignName,
    getCampaignById,
    modalMessageClass,
    singleCamapignData,
    changeCampaignName,
    getCampaignEndDate,
    getCampaignWinners,
    isGaslessClaimLocal,
    setModalMessageClass,
    getCampaignStartDate,
    alreadyJoinedCampaign,
    getMaxNumberOfWinners,
    getCampaignByIdLogged,
    changeCampaignEndDate,
    sendCampaignDataImages,
    getCampaignDescription,
    getNumberOfPrticipatns,
    changeCampaignStartDate,
    changeInputWalletAddress,
    getApplicantsByCampaignId,
    changeCampaignDescription,
    singleCampaignApplicantPageData,
    getCampaignRegistrationPeriod,
    getCampaignRewardComponentName,
    changeCampaignNumberOfParticipats,
    changeCampaignMaxNumberOfWinners,
    getCampaignRequirementsComponentName,
    fetchNftMetadataReqCollection,
    reqCollectionNftMetadata,
    fetchNFTsForContract,
    nftsForContractForWallet,
    socialProfileData,
    getSocialProfileData,
  };
};

export default useCampaign;
