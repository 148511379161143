import api from "@/services/api";
import Moralis from "@/plugins/moralis";

const initialState = () => ({
  errors: [],
  userNfts: [],
  calimableCampaigns: {},
  calimableCount: 0,
});
const state = initialState();

const getters = {
  getByKey: (state) => (key) => state[key],
};

const mutations = {
  commitByKey(state, object) {
    Object.keys(object).forEach((key) => (state[key] = object[key]));
  },
  setUserNfts(state, userNfts) {
    state.userNfts = userNfts;
  },
  setCalimableCampaigns(state, calimableCampaigns) {
    state.calimableCampaigns = calimableCampaigns;
  },
  setCalimableCount(state, calimableCount) {
    state.calimableCount = calimableCount;
  },
  pushError(state, error) {
    state.errors.push(error);
  },
  clearErrors(state) {
    state.errors.splice(0);
  },
  resetState(state) {
    Object.assign(state, initialState());
  },
};
const actions = {
  resetState({ commit }) {
    commit("resetState");
  },

  commitByKey({ commit }, object) {
    commit("commitByKey", object);
  },

  async fetchNftUserAddress({ commit }, options) {
    try {
      const response = await Moralis.Web3API.account.getNFTs(options);
      commit("setUserNfts", response.result);
      return response;
    } catch (error) {
      commit("pushError", error);
    }
  },
  async getClaimableCamapigns({ commit }) {
    try {
      const response = await api.getClaimableCamapigns();
      commit("setCalimableCampaigns", response.data);
      return response;
    } catch (error) {
      commit("pushError", error);
    }
  },
  async getClaimableCount({ commit }) {
    try {
      const response = await api.getClaimableCount();
      commit("setCalimableCount", response.data);
      return response;
    } catch (error) {
      commit("pushError", error);
    }
  },
  async getMerkleProof({ commit }, id) {
    try {
      const result = await api.getMerkleProof(id);
      return result.data.proof;
    } catch (error) {
      commit("pushError", error);
    }
  },
  async gaslessClaim({ commit }, id) {
    try {
      const response = await api.claimGaslessReward(id);
      return response;
    } catch (error) {
      commit("pushError", error);
    }
  },
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
