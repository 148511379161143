<template>
  <how-it-works />
</template>
<script>
import HowItWorks from "@/components/HowItWorks";
export default {
  components: {
    HowItWorks,
  },
};
</script>
