<template>
  <div class="mobile-unavailable">
    <img
      src="@/Common/Icons/Logo/airbroLogo.svg"
      alt="AirBro Logo"
      class="airbro-logo"
    />
    <h2 class="betterbrush-title green-base">Hey Bro</h2>
    <div>
      <p class="epilogue-text">
        Sorry, the Campaign Creation Form<br />
        is only available on Desktop...
      </p>

    </div>
  </div>
</template>
<script>
import { onMounted } from "vue";

export default {
  name: "MobileUnavailble",

  setup() {
    onMounted(async () => {});
    return {};
  },
};
</script>
<style lang="scss" scoped src="@/Common/Styles/mobileUnavailable.scss"></style>
