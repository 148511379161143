<template>
  <div class="apply-wrapper">
    <div class="custom-container">
      <div class="apply-wrapper-inside">
        <div class="form-wrapper">
          <div class="apply-explanation">
            <h2 class="epilogue-title-l"><span>Join</span> us on Twitter</h2>
          </div>
          <div class="button">
            <a href="https://twitter.com/AirBr0">
              <img
                src="@/Common/Icons/HomePage/twitterPurple.svg"
                alt="Twitter"
              />
              <span>TWITTER</span>
            </a>
          </div>
        </div>
        <div class="apply-star-wrapper">
          <img src="@/Common/Icons/starIcon.svg" alt="Very Cool Star Visual" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref } from "vue";
import { useStore } from "vuex";
import useAuth from "@/composables/useAuth";
import { subscribeSchema } from "@/validationSchemas/subscribeSchema";
export default {
  setup() {
    const email = ref("");
    const message = ref("");
    const store = useStore();
    const { changeUserEmail } = useAuth(store);
    const messageClass = ref("");
    const trackNewsletterSignUp = () => {
      if (process.env.VUE_APP_ENV !== "production") return;
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "newsletter_signup",
      });
    };

    const openLink = (url) => {
      window.open(url, "_blank");
      trackDiscordJoin();
    };

    const trackDiscordJoin = () => {
      if (process.env.VUE_APP_ENV !== "production") return;
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "discord_join",
      });
    };
    const subcsribeSignIn = async () => {
      const response = await store.dispatch("users/userSubscribe", email.value);
      setTimeout(() => (message.value = ""), 5000);
      if (response?.status === 201) {
        messageClass.value = "success";
        trackNewsletterSignUp();
        return (message.value =
          "Thank you, you have been subscribed successfully.");
      }
      messageClass.value = "error";
      return (message.value =
        "Sorry, this e-mail has already been registered.");
    };
    return {
      email,
      message,
      openLink,
      messageClass,
      changeUserEmail,
      subcsribeSignIn,
      subscribeSchema,
      trackDiscordJoin,
    };
  },
};
</script>
<style lang="scss" scoped src="@/Common/Styles/applyForCampaigns.scss"></style>
