import Moralis from "@/plugins/moralis";
import api from "../../services/api";

const initialState = () => ({
  errors: [],
  symbolName: "",
  tokensPerClaim: 1,
  txHashTokenId: "",
  uploadedImage: {},
  airdropDuration: 0,
  rewardName: "",
  collectionName: "",
  numberOfCopies: "",
  localDescription: "",
  uploadedMetadata: {},
  localSelectedFile: {},
  fileIPFSMetadata: "",
  fileIPFSImagePath: "",
  isSoulboundNft: "false",
  totalAirdropAmount: "",
  isTransactionFinished: true,
  cid: "",
  generateNftVideoName: "",
  videoClassActive: false,
});
const state = initialState();

const getters = {
  getByKey: (state) => (key) => state[key],
  getIsSoulboundNft: (state) =>
    state.isSoulboundNft === "false" ? false : true,
};

const mutations = {
  commitByKey(state, object) {
    Object.keys(object).forEach((key) => (state[key] = object[key]));
  },
  setUploadedImage(state, uploadedImage) {
    state.uploadedImage = uploadedImage;
  },
  setUploadedMetadata(state, uploadedMetadata) {
    state.uploadedMetadata = uploadedMetadata;
  },
  setTxHashTokenId(state, txHashTokenId) {
    state.txHashTokenId = txHashTokenId;
  },
  setIsTransactionFinished(state, isTransactionFinished) {
    state.isTransactionFinished = isTransactionFinished;
  },
  setFileIPFSMetadata(state, fileIPFSMetadata) {
    state.fileIPFSMetadata = fileIPFSMetadata;
  },
  setFileIPFSImagePath(state, fileIPFSImagePath) {
    state.fileIPFSImagePath = fileIPFSImagePath;
  },
  setGenerateNftVideoName(state, generateNftVideoName) {
    state.generateNftVideoName = generateNftVideoName;
  },
  pushError(state, error) {
    state.errors.push(error);
  },
  clearErrors(state) {
    state.errors.splice(0);
  },
  resetState(state) {
    Object.assign(state, initialState());
  },
};

const actions = {
  resetState({ commit }) {
    commit("resetState");
  },
  commitByKey({ commit }, object) {
    commit("commitByKey", object);
  },

  async uploadImage({ commit }, data) {
    commit("setIsTransactionFinished", false);
    const file = new Moralis.File(data.name, data);
    const response = await file.saveIPFS({ useMasterKey: true });
    commit("setFileIPFSImagePath", file.ipfs());
    commit("setUploadedImage", response);
    return file.hash();
  },

  async uploadMetadata({ commit }, metadata) {
    const file = new Moralis.File("file.json", {
      base64: window.btoa(JSON.stringify(metadata)),
    });
    const response = await file.saveIPFS({ useMasterKey: true });
    commit("setUploadedMetadata", response);
    commit("setFileIPFSMetadata", file.ipfs());
    return file.hash();
  },
  async uploadImageCloud({ commit }, data) {
    commit("setIsTransactionFinished", false);
    const toBase64 = (file) =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsBinaryString(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    const image = window.btoa(await toBase64(data));
    const ipfsImagePath = await Moralis.Cloud.run("ipfsbinary", { image });
    commit("setUploadedImage", ipfsImagePath);
    return ipfsImagePath;
  },
  async uploadMetadataCloud({ commit }, metadata) {
    const ipfsJsonPath = await Moralis.Cloud.run("ipfsjson", { metadata });
    commit("setUploadedMetadata", ipfsJsonPath.path);
    commit("setFileIPFSMetadata", ipfsJsonPath.path);
    return ipfsJsonPath;
  },
  async generateNftVideo({ commit }, data) {
    try {
      const response = await api.generateNftVideo(data);
      commit("setGenerateNftVideoName", response.data);
      return response;
    } catch (error) {
      commit("pushError", error);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
