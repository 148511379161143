<template>
  <div>
    <sign-up-form />
  </div>
</template>
<script>
import SignUpForm from "@/components/BecomeCreator/SignUpForm";
export default {
  components: {
    SignUpForm,
  },
  setup() {
    return {};
  },
};
</script>
