<template>
  <div>
    <span
      v-if="timer.days.value !== 0"
      class="days"
      :class="{ zero: timer.days.value === 0 }"
      >{{ timer.days }}</span
    >
    <span v-if="timer.days.value !== 0" class="time-text">{{
      timer.days.value === 1 ? " day" : " days"
    }}</span>
    <span
      v-if="timer.days.value === 0"
      class="hours"
      :class="{ zero: timer.hours.value === 0 }"
      >{{ timer.hours.value > 9 ? timer.hours : "0" + timer.hours.value }}</span
    >
    <span v-if="timer.days.value === 0" class="time-text">h</span>
    <span v-if="timer.days.value === 0" class="text">:</span>
    <span v-if="timer.days.value === 0" class="min">{{
      timer.minutes.value > 9 ? timer.minutes : "0" + timer.minutes.value
    }}</span
    ><span v-if="timer.days.value === 0" class="time-text">m</span>
  </div>
</template>
<script>
import { watchEffect, onMounted } from "vue";
import { useTimer } from "vue-timer-hook";
export default {
  name: "TimerMobile",
  props: {
    time: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const timer = useTimer(new Date(props.time));
    onMounted(() => {
      watchEffect(async () => {
        if (timer.isExpired.value) {
          console.warn("IsExpired");
        }
      });
    });
    return {
      timer,
    };
  },
};
</script>
<style lang="scss" scoped src="@/Common/Styles/timer.scss"></style>
