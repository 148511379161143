<template>
  <div class="menu-bar-wrapper" :class="{ light: isCreatorForm }">
    <div class="custom-container">
      <div class="menu-bar">
        <router-link to="/" class="logo-wrap">
          <img
            src="@/Common/Icons/airBroLogoReskin.svg"
            alt="AirBro Logo"
            class="airbro-logo"
          />
          <img
            src="@/Common/Icons/airBroLogoReskinInverse.svg"
            alt="AirBro Logo"
            class="airbro-logo-light"
          />
          <img
            src="@/Common/Icons/Logo/AirBro_logo_mobile_w.svg"
            alt="AirBro Logo"
            class="airbro-logo-mobile"
          />
        </router-link>
        <div class="navbar-links-to">
          <div>
            <router-link
              to="/create-twitter-campaign"
              class="nav-tab create-campaign-cta cc-menu"
            >
              Create Campaign
            </router-link>
          </div>
          <router-link to="/how-it-works" class="nav-tab">
            How it works
          </router-link>
        </div>
        <div class="connect-wrapper">
          <app-button
            v-if="!address || !isLoggedIn"
            buttonClass="connect-metamask"
            text="CONNECT WALLET"
            @click="logIn()"
          />
          <user-address-and-balance v-else />
        </div>
        <div class="burger-menu" @click="toggleIsMobileMenuActive">
          <img
            v-if="!isMobileMenuActive"
            src="@/Common/Icons/burgerMenuFull.svg"
            alt="Mobile Menu"
            class="burger-menu-mobile"
          />
          <img
            v-if="isMobileMenuActive"
            src="@/Common/Icons/burgerMenuOutline.svg"
            alt="Mobile Menu"
            class="burger-menu-mobile"
          />
        </div>
        <div
          v-if="isMobileMenuActive"
          v-click-outside="onClickOutside"
          class="navbar-links-to mobile"
        >
          <div class="nav-tabs">
            <router-link
              to="/"
              class="nav-tab"
              @click="toggleIsMobileMenuActive"
            >
              Home
            </router-link>
            <router-link
              to="/how-it-works"
              class="nav-tab"
              @click="toggleIsMobileMenuActive"
            >
              How it works
            </router-link>
          </div>
          <div class="divider"></div>
        </div>
      </div>
      <div class="divider"></div>
    </div>
  </div>
</template>

<script>
// onUnmounted
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { ethereumService } from "@/main";
import useAuth from "@/composables/useAuth";
import AppButton from "@/elements/AppButton";
import { ref, onMounted, computed } from "vue";
import useCampaign from "@/composables/useCampaign";
import useClaiming from "@/composables/useClaiming";
import UserAddressAndBalance from "@/components/UserAddressAndBalance";

export default {
  name: "MenuBar",
  components: {
    AppButton,
    UserAddressAndBalance,
  },
  setup() {
    const store = useStore();
    const route = useRouter();
    const menuFlag = ref(false);
    const isMobileMenuActive = ref(false);
    const isTosterActive = ref(true);
    const toggleIsMobileMenuActive = () => {
      isMobileMenuActive.value = !isMobileMenuActive.value;
    };
    const onClickOutside = () => {
      isMobileMenuActive.value = false;
    };
    const { singleCamapignData, fetchNFTsForContract } = useCampaign(store);
    const { calimableCount, fetchNftUserAddress, getClaimableCount } =
      useClaiming(store);

    const { loginOrSignUp, networkName, address, isLoggedIn, role } = useAuth(
      store,
      ethereumService
    );
    const trackConnectWallet = () => {
      if (process.env.VUE_APP_ENV !== "production") return;
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "wallet_connected",
      });
    };
    const logIn = async () => {
      await loginOrSignUp();
      if (isLoggedIn.value) {
        const options = {
          chain: networkName?.value,
          address: address.value,
        };
        await fetchNftUserAddress(options);
        if (singleCamapignData.value.tokenRequirements) {
          const optionsNFTsForContract = {
            chain: networkName?.value,
            address: address.value,
            token_address: singleCamapignData.value.tokenRequirements,
          };
          fetchNFTsForContract(optionsNFTsForContract);
        }
        // await updateUserTokens();
        await store.dispatch("users/fetchAddress");
        await getClaimableCount();
        store.dispatch("users/commitByKey", {
          walletAddressInput: address.value,
        });
        trackConnectWallet();
      }
    };
    const changeMenuFlag = () => {
      menuFlag.value = !menuFlag.value;
    };

    const path = computed(() => route.currentRoute.value.fullPath);
    const creatorFormUrl = "/create-twitter-campaign";

    const isCreatorForm = computed(() => {
      if (path.value === creatorFormUrl) {
        return true;
      }
      return false;
    });

    onMounted(async () => {
      if (isLoggedIn.value) {
        const options = {
          chain: networkName?.value,
          address: address.value,
        };
        await fetchNftUserAddress(options);
        await getClaimableCount();
      }

      let userAgent = navigator.userAgent;
      let browserName;

      if ((navigator.brave && (await navigator.brave.isBrave())) || false) {
        browserName = "brave";
      } else if (userAgent.match(/firefox|fxios/i)) {
        browserName = "firefox";
      } else if (userAgent.match(/safari/i)) {
        browserName = "safari";
      } else if (userAgent.match(/opr\//i)) {
        browserName = "opera";
      } else if (userAgent.match(/edg/i)) {
        browserName = "edge";
      } else if (userAgent.match(/chrome|chromium|crios/i)) {
        browserName = "chrome";
      } else {
        browserName = "Metamask browser or other";
      }

      if (browserName == "Metamask browser or other") {
        isTosterActive.value = false;
      }
    });

    const isRoleAdminOrCreator = computed(() => {
      if (isLoggedIn && (role.value === "ADMIN" || role.value === "CREATOR"))
        return true;
      return false;
    });
    return {
      toggleIsMobileMenuActive,
      isRoleAdminOrCreator,
      isMobileMenuActive,
      calimableCount,
      onClickOutside,
      changeMenuFlag,
      isTosterActive,
      creatorFormUrl,
      loginOrSignUp,
      isCreatorForm,
      isLoggedIn,
      menuFlag,
      address,
      route,
      logIn,
      path,
    };
  },
};
</script>
<style lang="scss" scoped src="@/Common/Styles/menuBar.scss"></style>
