<template>
  <div
    class="connect-twitter-req"
    :class="{
      disable:
        !walletAddressInput ||
        errorMessage ||
        singleCamapignData.status === 'ended',
    }"
  >
    <div class="connect-twitter-headline">
      <div>
        <div v-if="twHandle" class="twitter-connected">
          <p>2. Twitter Connected</p>
          <img
            v-if="!twitterFolowersComaparation && twFollowersCount"
            src="@/Common/Icons/notDoneIcon.svg"
            alt="notDone"
          />
          <img v-else src="@/Common/Icons/doneIcon.gif" alt="done" />
        </div>
        <div v-else>
          <p>2. Connect Twitter</p>
        </div>
      </div>
      <span v-if="!twHandle">Connect your Twitter profile.</span>
      <div class="twitter-name-wrapper" v-else>
        <span class="twitter-handle">@{{ twHandle }}</span>
      </div>
    </div>
    <div
      class="twitter-requirements"
      v-if="
        singleCamapignData?.profileCreated ||
        singleCamapignData?.profileFollowers
      "
    >
      <div class="twitter-requirements-headline">
        <img src="@/Common/Icons/requirementsIcon.svg" alt="RequirementsIcon" />
        <span>Requirements</span>
      </div>
      <div class="require-condition">
        <ul>
          <li v-if="singleCamapignData?.profileFollowers">
            Have
            <span
              :class="{
                conditionFaild:
                  !twitterFolowersComaparation && twFollowersCount,
              }"
              >{{ singleCamapignData?.profileFollowers }}</span
            >
            followers
          </li>
          <li v-if="singleCamapignData?.profileCreated">
            Profile created before
            <span
              :class="{ conditionFaild: !dateComapration && twCreatedAt }"
              >{{ formatDate(singleCamapignData?.profileCreated) }}</span
            >
          </li>
        </ul>
      </div>
    </div>
    <div class="connect-twitter-account-wrapper" v-if="!twHandle">
      <app-button
        text="CONNECT"
        buttonClass="small-button"
        @click.prevent="loginTwitter"
        :disabled="
          !walletAddressInput ||
          (errorMessage.length > 0 && errorMessage !== 'undefined') ||
          singleCamapignData.status === 'ended'
        "
      />
    </div>
    <div class="change-action" v-else>
      <span @click="resetTwitterAccount">change</span>
    </div>
  </div>
</template>
<script>
import { computed, ref } from "vue";
import { eventBus } from "@/main";
import { useStore } from "vuex";
import { initializeApp } from "firebase/app";
import firebaseConfig from "../../../firebaseConfig";
import AppButton from "@/elements/AppButton";
import useAuth from "@/composables/useAuth";
import useCampaign from "@/composables/useCampaign";
import useUtilities from "@/composables/useUtilities";
import { getAuth, signInWithPopup, TwitterAuthProvider } from "firebase/auth";
export default {
  components: {
    AppButton,
  },
  setup() {
    const store = useStore();
    const auth = getAuth();
    const provider = new TwitterAuthProvider();
    const { singleCamapignData } = useCampaign(store);
    const { formatDate, comapreDates } = useUtilities();
    const { twFollowersCount, twCreatedAt, twHandle, walletAddressInput } =
      useAuth(store);

    const errorMessage = ref("");
    eventBus.on("errorMessage", (val) => {
      errorMessage.value = val;
    });

    const loginTwitter = async () => {
      const result = await signInWithPopup(auth, provider);
      const created_at = new Date(
        JSON.parse(result._tokenResponse.rawUserInfo).created_at
      );
      await store.dispatch("users/commitByKey", {
        userEmail: result.user.email,
        twName: result.user.displayName,
        twEmailVerified: result.user.emailVerified,
        twHandle: result._tokenResponse.screenName,
        twPhotoUrl: result.user.photoURL,
        twCreatedAt: created_at.toISOString(),
        twFollowersCount: JSON.parse(result._tokenResponse.rawUserInfo)
          .followers_count,
      });
    };
    const dateComapration = computed(() => {
      return comapreDates(
        singleCamapignData.value.profileCreated,
        twCreatedAt.value
      )
        ? true
        : false;
    });
    const twitterFolowersComaparation = computed(() => {
      return twFollowersCount.value >= singleCamapignData.value.profileFollowers
        ? true
        : false;
    });
    const resetTwitterAccount = async () => {
      await store.dispatch("users/commitByKey", {
        userEmail: "",
        twName: "",
        twEmailVerified: "",
        twHandle: "",
        twPhotoUrl: "",
        twCreatedAt: "",
        twFollowersCount: "",
      });
    };
    return {
      twHandle,
      formatDate,
      twCreatedAt,
      loginTwitter,
      errorMessage,
      initializeApp,
      firebaseConfig,
      dateComapration,
      twFollowersCount,
      walletAddressInput,
      singleCamapignData,
      resetTwitterAccount,
      twitterFolowersComaparation,
    };
  },
};
</script>
<style
  lang="scss"
  scoped
  src="@/Common/Styles/socialMediaTypeRequirements.scss"
></style>
