<template>
  <div style="max-width: 900px; margin: 0 auto">
    <div class="accordion" role="tablist" style="margin-top: 80px">
      <b-card no-body class="mb-1">
        <b-card-header header-tag="header" class="p-1" role="tab">
          <b-button block v-b-toggle.accordion-1 variant="info"
            >Running cron for scraping images</b-button
          >
        </b-card-header>
        <b-collapse id="accordion-1" accordion="my-accordion" role="tabpanel">
          <b-card-body>
            <div class="row section" style="align-items: flex-end">
              <div class="col-md-12">
                <app-button
                  @click="cronScrapingImages"
                  text="Submit"
                  class="btn-backend"
                />
              </div>
            </div>
          </b-card-body>
        </b-collapse>
      </b-card>
      <b-card no-body class="mb-1">
        <b-card-header header-tag="header" class="p-1" role="tab">
          <b-button block v-b-toggle.accordion-2 variant="info"
            >Running cron for changing campaign status</b-button
          >
        </b-card-header>
        <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
          <b-card-body>
            <div class="row section" style="align-items: flex-end">
              <div class="col-md-6">
                <app-button
                  @click="cronCampaignStatus"
                  text="Submit"
                  class="btn-backend"
                />
              </div>
            </div>
          </b-card-body>
        </b-collapse>
      </b-card>
      <b-card no-body class="mb-1">
        <b-card-header header-tag="header" class="p-1" role="tab">
          <b-button block v-b-toggle.accordion-3 variant="info"
            >Changing start date</b-button
          >
        </b-card-header>
        <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
          <b-card-body>
            <div class="row section" style="align-items: flex-end">
              <div class="col-md-6">
                <app-input
                  type="text"
                  v-model="startDateCampaignId"
                  labelText="Campaign ID"
                  :name="startDateCampaignId"
                  inputClass="form-box-input"
                  labelClass="label"
                  placeholder="Paste campaign id"
                />
              </div>
              <div class="col-md-6">
                <app-input
                  type="text"
                  v-model="startDate"
                  labelText="Start Date"
                  :name="startDate"
                  inputClass="form-box-input"
                  labelClass="label"
                  placeholder="Paste start date in 2022-10-30 00:00:00 format"
                />
              </div>
              <div class="col-md-12">
                <app-button
                  @click="changeStartDate"
                  text="Submit"
                  class="btn-backend"
                />
              </div>
            </div>
          </b-card-body>
        </b-collapse>
      </b-card>
      <b-card no-body class="mb-1">
        <b-card-header header-tag="header" class="p-1" role="tab">
          <b-button block v-b-toggle.accordion-4 variant="info"
            >Changing end date</b-button
          >
        </b-card-header>
        <b-collapse id="accordion-4" accordion="my-accordion" role="tabpanel">
          <b-card-body>
            <div class="row section" style="align-items: flex-end">
              <div class="col-md-6">
                <app-input
                  type="text"
                  v-model="endDateCampaignId"
                  labelText="Campaign ID"
                  :name="endDateCampaignId"
                  inputClass="form-box-input"
                  labelClass="label"
                  placeholder="Paste campaign id"
                />
              </div>
              <div class="col-md-6">
                <app-input
                  type="text"
                  v-model="endDate"
                  labelText="End Date"
                  :name="endDate"
                  inputClass="form-box-input"
                  labelClass="label"
                  placeholder="Paste end date in 2022-10-30 00:00:00 format"
                />
              </div>
              <div class="col-md-12">
                <app-button
                  @click="changeEndDate"
                  text="Submit"
                  class="btn-backend"
                />
              </div>
            </div>
          </b-card-body>
        </b-collapse>
      </b-card>
      <b-card no-body class="mb-1">
        <b-card-header header-tag="header" class="p-1" role="tab">
          <b-button block v-b-toggle.accordion-5 variant="info"
            >Setting chainID on data contract</b-button
          >
        </b-card-header>
        <b-collapse id="accordion-5" accordion="my-accordion" role="tabpanel">
          <b-card-body>
            <div class="row section" style="align-items: flex-end">
              <div class="col-md-6">
                <app-input
                  type="text"
                  v-model="chainIdCampaignId"
                  labelText="Campaign ID"
                  :name="chainIdCampaignId"
                  inputClass="form-box-input"
                  labelClass="label"
                  placeholder="Paste campaign id"
                />
              </div>
              <div class="col-md-6">
                <app-button
                  @click="changeChainIdContract"
                  text="Submit"
                  class="btn-backend"
                />
              </div>
            </div>
          </b-card-body>
        </b-collapse>
      </b-card>
      <b-card no-body class="mb-1">
        <b-card-header header-tag="header" class="p-1" role="tab">
          <b-button block v-b-toggle.accordion-6 variant="info"
            >Changing campaign status</b-button
          >
        </b-card-header>
        <b-collapse id="accordion-6" accordion="my-accordion" role="tabpanel">
          <b-card-body>
            <div class="row section" style="align-items: flex-end">
              <div class="col-md-6">
                <app-input
                  type="text"
                  v-model="statusCampaignId"
                  labelText="Campaign ID"
                  :name="statusCampaignId"
                  inputClass="form-box-input"
                  labelClass="label"
                  placeholder="Paste campaign id"
                />
              </div>
              <div class="col-md-6">
                <app-input
                  type="text"
                  v-model="campaignStatus"
                  labelText="Campaign Status"
                  :name="campaignStatus"
                  inputClass="form-box-input"
                  labelClass="label"
                  placeholder="Type status (active,pending or ended)"
                />
              </div>
              <div class="col-md-12">
                <app-button
                  @click="changeCampaignStatus"
                  text="Submit"
                  class="btn-backend"
                />
              </div>
            </div>
          </b-card-body>
        </b-collapse>
      </b-card>
      <b-card no-body class="mb-1">
        <b-card-header header-tag="header" class="p-1" role="tab">
          <b-button block v-b-toggle.accordion-7 variant="info"
            >Hide campaign from frontend</b-button
          >
        </b-card-header>
        <b-collapse id="accordion-7" accordion="my-accordion" role="tabpanel">
          <b-card-body>
            <div class="row section" style="align-items: flex-end">
              <h2 style="color: white"></h2>
              <div class="col-md-6">
                <app-input
                  type="text"
                  v-model="hideCampaignId"
                  labelText="Campaign ID"
                  :name="hideCampaignId"
                  inputClass="form-box-input"
                  labelClass="label"
                  placeholder="Paste campaign id"
                />
              </div>
              <div class="col-md-6">
                <app-button
                  @click="hideCampaign"
                  text="Submit"
                  class="btn-backend"
                />
              </div>
            </div>
          </b-card-body>
        </b-collapse>
      </b-card>
      <b-card no-body class="mb-1">
        <b-card-header header-tag="header" class="p-1" role="tab">
          <b-button block v-b-toggle.accordion-8 variant="info"
            >Check Posts</b-button
          >
        </b-card-header>
        <b-collapse id="accordion-8" accordion="my-accordion" role="tabpanel">
          <b-card-body>
            <div class="row section" style="align-items: flex-end">
              <h2 style="color: white"></h2>
              <div class="col-md-6">
                <app-input
                  type="text"
                  v-model="postCampaignId"
                  labelText="Campaign ID"
                  :name="postCampaignId"
                  inputClass="form-box-input"
                  labelClass="label"
                  placeholder="Paste campaign id"
                />
              </div>
              <div class="col-md-6">
                <app-button
                  @click="checkPostCampaign"
                  text="Submit"
                  class="btn-backend"
                />
              </div>
            </div>
          </b-card-body>
        </b-collapse>
      </b-card>
      <b-card no-body class="mb-1">
        <b-card-header header-tag="header" class="p-1" role="tab">
          <b-button block v-b-toggle.accordion-9 variant="info"
            >Delete campaign from database</b-button
          >
        </b-card-header>
        <b-collapse id="accordion-9" accordion="my-accordion" role="tabpanel">
          <b-card-body>
            <div class="row section" style="align-items: flex-end">
              <h2 style="color: white"></h2>
              <div class="col-md-6">
                <app-input
                  type="text"
                  v-model="deleteCampaignId"
                  labelText="Campaign ID"
                  :name="deleteCampaignId"
                  inputClass="form-box-input"
                  labelClass="label"
                  placeholder="Paste campaign id"
                />
              </div>
              <div class="col-md-6">
                <app-button
                  @click="deleteCampaign"
                  text="Submit"
                  class="btn-backend"
                />
              </div>
            </div>
          </b-card-body>
        </b-collapse>
      </b-card>
      <b-card no-body class="mb-1">
        <b-card-header header-tag="header" class="p-1" role="tab">
          <b-button block v-b-toggle.accordion-10 variant="info"
            >Creator Pending List</b-button
          >
        </b-card-header>
        <b-collapse id="accordion-10" accordion="my-accordion" role="tabpanel">
          <b-card-body>
            <div v-if="pendingCreatorsList.length > 0">
              <div
                class="row section"
                style="align-items: flex-end"
                v-for="(pendingCreator, index) in pendingCreatorsList"
                :key="index"
              >
                <h2 style="color: white"></h2>
                <div class="col-md-8">
                  <div class="creator-list">
                    <p>
                      Name: <span>{{ pendingCreator.name }}</span>
                    </p>
                    <p>
                      Email: <span>{{ pendingCreator.email }}</span>
                    </p>
                    <p>
                      Wallet Address:
                      <span>{{ pendingCreator.walletAddress }}</span>
                    </p>
                    <div>
                      <p
                        v-if="
                          Object.keys(
                            JSON.parse(pendingCreator.promotingReason)
                          ).length > 0
                        "
                      >
                        Promote Reason:
                        <span
                          v-for="(reason, index) in promoteReason(
                            JSON.parse(pendingCreator.promotingReason)
                          )"
                          :key="index"
                          >{{
                            reason.toLowerCase().charAt(0).toUpperCase() +
                            reason.slice(1)
                          }},
                        </span>
                      </p>
                      <p v-else>
                        Promote Reason: <span>No promote reason!</span>
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-md-2">
                  <app-button
                    @click="acceptCreator(pendingCreator.id)"
                    text="Accept"
                    class="btn-backend-creator"
                  />
                </div>
                <div class="col-md-2">
                  <app-button
                    @click="declineCreator(pendingCreator.id)"
                    text="Decline"
                    class="btn-backend-creator"
                  />
                </div>
              </div>
            </div>
            <div v-else>
              <h2 style="color: white">No pending creators</h2>
            </div>
          </b-card-body>
        </b-collapse>
      </b-card>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { useStore } from "vuex";
import api from "../plugins/apiWrapper";
import AppInput from "@/elements/AppInput";
import AppButton from "@/elements/AppButton";
import useSignUpCampaignCreator from "@/composables/useSignUpCampaignCreator";

export default {
  name: "BackendView",
  components: {
    AppButton,
    AppInput,
  },
  props: {
    isDisabled: {
      type: Boolean,
      default: false,
    },
    campaignInfo: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const store = useStore();
    const endDate = ref("");
    const startDate = ref("");
    const endDateCampaignId = ref("");
    const campaignIdScraping = ref("");
    const startDateCampaignId = ref("");
    const campaignIdCronStatus = ref("");
    const chainIdCampaignId = ref("");
    const campaignStatus = ref("");
    const statusCampaignId = ref("");
    const hideCampaignId = ref("");
    const postCampaignId = ref("");
    const deleteCampaignId = ref("");

    const { pendingCreators, pendingCreatorsList, updateCreators } =
      useSignUpCampaignCreator(store);

    const cronScrapingImages = async () => {
      return api.post(`/control-panel/scraping-cronjob`);
    };

    const cronCampaignStatus = async () => {
      return api.post(`/control-panel/run-ending-campaign-cronjob`);
    };

    const changeStartDate = async () => {
      const data = {
        campaignId: startDateCampaignId.value,
        startDate: startDate.value,
      };
      return api.post(`/control-panel/update-campaign-start-date`, data);
    };

    const changeEndDate = async () => {
      const data = {
        campaignId: endDateCampaignId.value,
        endDate: endDate.value,
      };
      return api.post(`/control-panel/update-campaign-end-date`, data);
    };

    const changeChainIdContract = async () => {
      const data = {
        campaignId: chainIdCampaignId.value,
      };
      return api.post(`/control-panel/set-chain-id`, data);
    };
    const changeCampaignStatus = async () => {
      const data = {
        campaignId: statusCampaignId.value,
        campaignStatus: campaignStatus.value,
      };
      return api.post(`/control-panel/update-campaign-status`, data);
    };
    const hideCampaign = async () => {
      return api.delete(`/control-panel/hide-campaign/${hideCampaignId.value}`);
    };
    const deleteCampaign = async () => {
      return api.delete(
        `/control-panel/delete-campaign/${deleteCampaignId.value}`
      );
    };
    const checkPostCampaign = async () => {
      return api.post(`/control-panel/check-posts/${postCampaignId.value}`);
    };
    const acceptCreator = async (id) => {
      const data = {
        approve: [id],
        decline: [],
      };
      await updateCreators(data);
      await pendingCreators();
    };
    const declineCreator = async (id) => {
      const data = {
        approve: [],
        decline: [id],
      };
      await updateCreators(data);
      await pendingCreators();
    };
    const promoteReason = (obj) => {
      if (Object.keys(obj).length > 0) {
        return Object.keys(obj);
      }
    };
    onMounted(async () => {
      await pendingCreators();
    });
    return {
      endDate,
      startDate,
      changeEndDate,
      changeStartDate,
      cronScrapingImages,
      cronCampaignStatus,
      campaignIdScraping,
      campaignIdCronStatus,
      startDateCampaignId,
      changeChainIdContract,
      changeCampaignStatus,
      campaignStatus,
      hideCampaign,
      chainIdCampaignId,
      hideCampaignId,
      statusCampaignId,
      endDateCampaignId,
      postCampaignId,
      deleteCampaign,
      deleteCampaignId,
      checkPostCampaign,
      pendingCreatorsList,
      acceptCreator,
      declineCreator,
      promoteReason,
    };
  },
};
</script>
<style scoped>
button.sign-in-button {
  display: inherit;
  margin: 0 auto;
  padding: 20px;
  margin-top: 30px;
  margin-bottom: 30px;
}
.response {
  padding: 5px;
  text-align: center;
  color: #fff;
}
button.btn-backend {
  display: inherit;
  background: #afd939;
  color: #000;
  padding: 9px 32px;
  border-radius: 10px;
  border: none;
  margin: 0 auto;
  min-width: 320px;
  margin-top: 32px;
}
button.btn-backend-creator {
  display: inherit;
  background: #afd939;
  color: #000;
  padding: 9px 32px;
  border-radius: 10px;
  border: none;
  margin: 0 auto;
  min-width: 120px;
  margin-top: 32px;
}
.creator-list {
  display: flex;
  flex-direction: column;
  color: #ffffff;
}
.creator-list span {
  color: #d2f088;
}
.row.section {
  padding: 30px 0;
}
.card-body {
  background: #1a2842;
  border: none;
}

.collapse {
  border: none;
}

.card {
  border: 1px solid #afd939;
  overflow: hidden;
}

header.card-header {
  background: #afd939;
}

.card-header button.btn.btn-info {
  background: transparent;
  border: none;
  font-size: 20px;
  font-weight: 500;
  color: #1a2030;
}
.card-header button.btn.btn-info {
  width: 100%;
  text-align: left;
}
</style>
