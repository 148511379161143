import { computed } from "vue";

const useSendExistingToken = (store) => {
  const walletTokenBalances = computed(() =>
    store.getters["sendExistingToken/getByKey"]("walletTokenBalances")
  );
  const optionsWalletBalances = computed(() =>
    store.getters["sendExistingToken/getByKey"]("optionsWalletBalances")
  );
  const selectedTokenAddress = computed(() =>
    store.getters["sendExistingToken/getByKey"]("selectedTokenAddress")
  );

  const totalAirdropAmount = computed(() =>
    store.getters["sendExistingToken/getByKey"]("totalAirdropAmount")
  );

  const tokensPerClaimExisting = computed(() =>
    store.getters["sendExistingToken/getByKey"]("tokensPerClaim")
  );

  const fetchWalletBalances = (options) =>
    store.dispatch("sendExistingToken/fetchWalletBalances", options);

  return {
    fetchWalletBalances,
    walletTokenBalances,
    optionsWalletBalances,
    selectedTokenAddress,
    totalAirdropAmount,
    tokensPerClaimExisting,
  };
};

export default useSendExistingToken;
