import * as Yup from "yup";
import { ethers } from "ethers";

export const joinCampaignSchema = Yup.object().shape({
  WalletAddress: Yup.string()
    .nullable()
    .test(
      "ADDRESS VALID",
      "Wallet address is not valid",
      (address) =>
        ethers.utils.isAddress(address) ||
        address === undefined ||
        address === ""
    ),
});
