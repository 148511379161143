<template>
  <div class="container">
    <div class="sign-up-form-wrapper">
      <div class="sign-up">
        <span class="sign-up-text">Sign UP</span>
        <span class="sign-up-udertext">For Campaign Creator</span>
      </div>
      <div class="sign-up-headline-wrapper">
        <span class="sign-up-headline">Looking to promote</span>
        <div class="sign-up-border"></div>
      </div>
      <base-form :handleSubmit="signUp" :schema="signUpCreatorSchema">
        <div class="sign-up-checkbox-list">
          <div class="two-wrap">
            <div class="checkbox-wrapper">
              <input
                type="checkbox"
                v-model="project"
                name="Project"
                class="checkbox-input sign-up-form"
                labelClass="label"
                @change="changeCreatorProject(project)"
              />
              <span>Project</span>
            </div>
            <div class="checkbox-wrapper">
              <input
                type="checkbox"
                v-model="nftCollection"
                name="NftCollection"
                class="checkbox-input sign-up-form"
                labelClass="label"
                @change="changeCreatorNftcollection(nftCollection)"
              />
              <span>Nft Collection</span>
            </div>
          </div>
          <div class="two-wrap">
            <div class="checkbox-wrapper">
              <input
                type="checkbox"
                v-model="event"
                name="Event"
                class="checkbox-input sign-up-form"
                labelClass="label"
                @change="changeCreatorEvent(event)"
              />
              <span>Event</span>
            </div>
            <div class="checkbox-wrapper">
              <input
                type="checkbox"
                v-model="brand"
                name="Brand"
                class="checkbox-input sign-up-form"
                labelClass="label"
                @change="changeCreatorBrand(brand)"
              />
              <span>Brand</span>
            </div>
          </div>
        </div>
        <div class="sign-up-input-wrapper">
          <app-input
            type="text"
            v-model="creatorName"
            labelText="Name"
            name="CreatorName"
            inputClass="form-box-input sign-up-input"
            labelClass="label-creator"
            placeholder="Enter Your Name"
            @change="changeCreatorName(creatorName)"
          />
          <app-input
            type="text"
            v-model="creatorEmail"
            labelText="E Mail"
            name="CreatorEmail"
            inputClass="form-box-input sign-up-input"
            labelClass="label-creator"
            placeholder="e-mail address"
            @change="changeCreatorEmail(creatorEmail)"
          />
          <app-input
            type="text"
            v-model="creatorWalletAddress"
            labelText="Wallet Address"
            name="CreatorWalletAddress"
            inputClass="form-box-input sign-up-input"
            labelClass="label-creator"
            placeholder="e.g. 0x168...Ba38"
            @change="changeCreatorWalletAddress(creatorWalletAddress)"
          />
        </div>
        <div class="sign-up-button-wrapper">
          <app-button
            type="submit"
            class="sign-up-creator-button"
            text="Submit"
            :isLoader="buttonDisabled ? true : false"
          />
        </div>
      </base-form>
    </div>
  </div>
  <sign-up-creator-modal
    v-if="modalActive"
    @close="toggleModal"
    :modalActive="modalActive"
  />
</template>
<script>
import { ref } from "vue";
import { useStore } from "vuex";
import AppInput from "@/elements/AppInput";
import AppButton from "@/elements/AppButton";
import BaseForm from "@/components/BaseForm";
import { eventBus } from "@/main";
import { signUpCreatorSchema } from "@/validationSchemas/signUpCreatorSchema";
import useSignUpCampaignCreator from "@/composables/useSignUpCampaignCreator";
import SignUpCreatorModal from "@/components/SignUpCreatorModal.vue";
export default {
  components: {
    AppInput,
    AppButton,
    BaseForm,
    SignUpCreatorModal,
  },
  setup() {
    const store = useStore();
    const creatorName = ref("");
    const creatorEmail = ref("");
    const creatorWalletAddress = ref("");
    const project = ref(false);
    const nftCollection = ref(false);
    const event = ref(false);
    const brand = ref(false);
    const modalActive = ref(false);

    const {
      changeCreatorProject,
      changeCreatorNftcollection,
      changeCreatorBrand,
      changeCreatorEvent,
      changeCreatorName,
      changeCreatorEmail,
      changeCreatorWalletAddress,
      signUpCreator,
      buttonDisabled,
    } = useSignUpCampaignCreator(store);

    const signUp = async () => {
      store.dispatch("signUpCampaignCreator/commitByKey", {
        buttonDisabled: true,
      });
      const data = {
        walletAddress: creatorWalletAddress.value,
        name: creatorName.value,
        email: creatorEmail.value,
        promotingReason: JSON.stringify({
          ...(project.value && {
            project: project.value,
          }),
          ...(nftCollection.value && {
            nftCollection: nftCollection.value,
          }),
          ...(event.value && {
            event: event.value,
          }),
          ...(brand.value && {
            brand: brand.value,
          }),
        }),
      };
      const res = await signUpCreator(data);
      if (res.data) {
        store.dispatch("signUpCampaignCreator/commitByKey", {
          buttonDisabled: false,
        });
        creatorName.value = "";
        creatorEmail.value = "";
        creatorWalletAddress.value = "";
        project.value = false;
        nftCollection.value = false;
        event.value = false;
        brand.value = false;
        eventBus.emit("resetErrors");
        store.dispatch("signUpCampaignCreator/resetState");
        modalActive.value = true;
      }
    };
    const toggleModal = () => {
      modalActive.value = !modalActive.value;
    };

    return {
      event,
      brand,
      signUp,
      project,
      toggleModal,
      modalActive,
      creatorName,
      creatorEmail,
      nftCollection,
      buttonDisabled,
      changeCreatorName,
      changeCreatorEmail,
      changeCreatorEvent,
      changeCreatorBrand,
      signUpCreatorSchema,
      changeCreatorProject,
      creatorWalletAddress,
      changeCreatorWalletAddress,
      changeCreatorNftcollection,
    };
  },
};
</script>
<style lang="scss" scoped src="@/Common//Styles/signUpForm.scss"></style>
