import * as Yup from "yup";
import { ethers } from "ethers";
import moment from "moment";

const today = new Date();
today.setHours(24, 0, 0, 0);

const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/gif", "image/png"];
const MAX_FILE_SIZE = 20971520;

export const nftPfpSchema = Yup.object().shape({
  CampaignName: Yup.string()
    .required("Campaign name is required")
    .max(80, "Maximum length is 80"),
  CampaignDescription: Yup.string().required("Campaign descriptionis required"),
  StartDate: Yup.date()
    .min(today, "Start date can't be before today")
    .required("Application deadline is required")
    .nullable()
    .transform((curr, orig) => (orig === "" ? null : curr)),
  EndDate: Yup.date()
    .min(Yup.ref("StartDate"), "End date can't be before start date")
    .required("End date is required")
    .nullable()
    .transform((curr, orig) => (orig === "" ? null : curr)),
  CampaignNumberOfParticipats: Yup.number()
    .integer("Should not have decimals")
    .typeError("Must be a number")
    .nullable()
    .positive("Must be greater than 0")
    .transform((_, val) => (val !== "" ? Number(val) : null)),
  CampaignMaxNumberOfWinners: Yup.number()
    .integer("Should not have decimals")
    .typeError("Must be a number")
    .nullable()
    .positive("Must be greater than 0")
    .transform((_, val) => (val !== "" ? Number(val) : null)),
  NftCollectionAddress: Yup.string()
    .required("Nft collection address is required")
    .nullable()
    .test("ADDRESS VALID", "Nft collection address is not valid", (address) =>
      ethers.utils.isAddress(address)
    ),
  NumberOfFolowers: Yup.number()
    .integer("Should not have decimals")
    .typeError("Must be a number")
    .nullable()
    .positive("Must be greater than 0")
    .transform((_, val) => (val !== "" ? Number(val) : null)),
  SelectedFileNftPfp: Yup.mixed()
    .nullable()
    .required()
    .test(
      "FILE_SIZE",
      "The File Size should be less then 20MB",
      (value) => value.size <= MAX_FILE_SIZE
    )
    .test(
      "FILE_FORMAT",
      "Unsupported file, try again",
      (value) => value && SUPPORTED_FORMATS.includes(value.type)
    ),
  CampaignDaysToHoldPFP: Yup.number()
    .required("Number of days is required")
    .integer("Should not have decimals")
    .typeError("Must be a number")
    .nullable()
    .positive("Must be greater than 0")
    .transform((_, val) => (val !== "" ? Number(val) : null))
    .test(
      "NUMBER OF DAYS VALID",
      "Number of days should be less then campaign duration",
      (value, testContext) => {
        if (
          moment
            .duration(
              moment(testContext.parent.EndDate).diff(
                testContext.parent.StatDate
              )
            )
            .asDays() > value
        )
          return true;
        return false;
      }
    ),
  AccountDateCreation: Yup.date()
    .max(today, "Date can't be after today")
    .nullable()
    .transform((curr, orig) => (orig === "" ? null : curr)),
  OwnershipNftAddress: Yup.string()
    .nullable()
    .test(
      "ADDRESS VALID",
      "Nft collection address is not valid",
      (address) =>
        ethers.utils.isAddress(address) ||
        address === undefined ||
        address === ""
    ),
});
