<template>
  <div class="campaign-card">
    <a :href="'/campaign/' + campaignInfo.id">
      <div class="card-body">
        <h3 class="epilogue-subtitle dark-gray-base">
          {{ addEllipsis(campaignInfo.name) }}
        </h3>
        <div class="section">
          <div class="image-wrapper">
            <div
              class="campaign-image-wrapper"
              v-if="campaignInfo.proofType === 'post'"
            >
              <div v-if="campaignInfo.type === 'nft'" class="hexagon wrapper">
                <video
                  controls
                  autoplay
                  muted
                  width="180"
                  height="180"
                  class="hexagon reward"
                  v-if="checkFileMimeType(campaignInfo.awardMimeType, 'video')"
                  :src="getImgUrl(campaignInfo.awardPath)"
                ></video>
                <div
                  class="hexagon reward audio-wrap"
                  v-if="checkFileMimeType(campaignInfo.awardMimeType, 'audio')"
                >
                  <audio
                    autoplay
                    muted
                    controls
                    preload="auto"
                    :src="getImgUrl(campaignInfo.awardPath)"
                  ></audio>
                </div>
                <img
                  class="hexagon reward"
                  v-if="checkFileMimeType(campaignInfo.awardMimeType, 'image')"
                  :src="getImgUrl(campaignInfo.awardPath)"
                  alt="NFT Reward"
                />
              </div>
              <div
                v-else-if="campaignInfo.type === 'token'"
                class="circle wrapper"
              >
                <img
                  class="circle reward"
                  :src="getTokenImgUrl(campaignInfo.awardName)"
                  alt="Token Reward"
                />
              </div>
              <img
                class="campaign-reward-icon"
                src="@/Common/Icons/HomePage/rewardIcon.svg"
                alt="Campaign Reward"
              />
            </div>
            <div
              v-else
              class="campaign-pfp"
              :class="{
                hexagon: campaignInfo.proofType === 'nft',
                circle: campaignInfo.proofType === 'image',
              }"
            >
              <img :src="getImgUrl(campaignInfo.awardingCollImagePath)" />
            </div>
          </div>
          <div class="info-wrapper">
            <div class="label-value">
              <h4 class="subtitle">Bros</h4>
              <p class="data">
                <template v-if="campaignInfo.applicantsCount > 0">
                  {{ campaignInfo.applicantsCount }}
                </template>
                <template v-else> 0 </template>
              </p>
            </div>
            <div class="label-value">
              <h4 class="subtitle">Reach</h4>
              <p class="data">
                <template v-if="campaignInfo.applicantsCount > 0">
                  {{ campaignInfo.audienceCount }}
                </template>
                <template v-else> 0 </template>
              </p>
            </div>
            <div class="label-value reward">
              <h4 class="subtitle">Reward</h4>
              <p class="data">
                <span v-if="campaignInfo.type === 'nft'">NFT</span>
                <span v-else>
                  {{ campaignInfo.supply }}
                  <span class="token-name"> {{ campaignInfo.awardName }} </span>
                </span>
              </p>
            </div>
            <div class="label-value">
              <h4 v-if="campaignInfo.status === 'pending'" class="subtitle">
                Starts in
              </h4>
              <h4 v-else-if="campaignInfo.status === 'active'" class="subtitle">
                Ends in
              </h4>
              <h4 v-else class="subtitle">Completed</h4>
              <div class="data">
                <timer-mobile
                  v-if="campaignInfo.status === 'pending'"
                  :time="campaignInfo.startDate"
                  class="timer"
                />
                <timer-mobile
                  v-else-if="campaignInfo.status === 'active'"
                  :time="campaignInfo.endDate"
                  class="timer"
                />
                <div v-else-if="campaignInfo.status === 'ended'">
                  {{ formatDate(campaignInfo.endDate) }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <div
          :class="[
            {
              active: campaignInfo.avaliableFlag === 'claim',
            },
            {
              activeOutline: campaignInfo.avaliableFlag === 'claimed',
            },
            {
              upcoming:
                campaignInfo.avaliableFlag == 'info' &&
                campaignInfo.status == 'pending',
            },
            {
              join:
                campaignInfo.avaliableFlag == 'info' &&
                campaignInfo.status == 'active',
            },
            {
              joinOutline: campaignInfo.avaliableFlag === 'joined',
            },
            'button epilogue-smaller',
          ]"
        >
          <span
            v-if="
              campaignInfo.avaliableFlag == 'info' &&
              campaignInfo.status == 'pending'
            "
            >Upcoming</span
          >
          <span v-if="campaignInfo.avaliableFlag == 'joined'">Joined</span>
          <span v-if="campaignInfo.avaliableFlag == 'claim'">Claim</span>
          <span v-if="campaignInfo.avaliableFlag == 'claimed'">Claimed</span>
          <span
            v-if="
              campaignInfo.avaliableFlag == 'info' &&
              campaignInfo.status == 'active'
            "
            >Join
          </span>
          <span
            v-if="
              campaignInfo.avaliableFlag == 'info' &&
              campaignInfo.status == 'ended'
            "
            >Info
          </span>
        </div>
      </div>
    </a>
  </div>
</template>
<script>
import { ref, onMounted } from "vue";
import TimerMobile from "@/components/TimerMobile";
import useUtilities from "@/composables/useUtilities";

export default {
  name: "CampaignCard",
  components: {
    TimerMobile,
  },
  props: {
    campaignInfo: {
      type: Object,
      default: () => {},
    },
  },
  setup() {
    const modalActive = ref(false);
    const {
      formatDate,
      formatDateTimeString,
      formatDateWithoutYear,
      checkFileMimeType,
    } = useUtilities();
    const toggleModal = () => {
      modalActive.value = !modalActive.value;
    };
    const changeIconDepensOnCamapign = (socialMediaIcon) => {
      switch (socialMediaIcon) {
        case "twitter":
          return require(`@/Common/Icons/twitterModalIcon.svg`);
        case "instagram":
          return require(`@/Common/Icons/instagramModalIcon.svg`);
      }
    };
    const changeIconDepensOnCamapignPFPImage = (socialMediaIcon) => {
      switch (socialMediaIcon) {
        case "twitter":
          return require(`@/Common/Icons/twitterProfileImage.svg`);
        case "instagram":
          return require(`@/Common/Icons/instagramProfileImage.svg`);
      }
    };
    const changeCamapignPFPImage = (capaignType) => {
      switch (capaignType) {
        case "image":
          return require(`@/Common/Icons/regularPfpImage.svg`);
        case "nft":
          return require(`@/Common/Icons/nftPfpImage.svg`);
        case "post":
          return require(`@/Common/Icons/postContentImage.svg`);
      }
    };
    const changeCamapignPFPImageHover = (capaignType) => {
      switch (capaignType) {
        case "image":
          return require(`@/Common/Icons/regularPfpImageHover.svg`);
        case "nft":
          return require(`@/Common/Icons/nftPfpImageHover.svg`);
        case "post":
          return require(`@/Common/Icons/postContentImageHover.svg`);
      }
    };

    const getTokenImgUrl = (tokenName) => {
      switch (tokenName) {
        case "USDC":
          return require(`@/Common/Icons/Tokens/usdcToken.svg`);
        case "USDT":
          return require(`@/Common/Icons/Tokens/usdtToken.svg`);
        case "MATIC":
          return require(`@/Common/Icons/Tokens/polygonToken.svg`);
        case "BNB":
          return require(`@/Common/Icons/Tokens/bnbToken.svg`);
        case "DAI":
          return require(`@/Common/Icons/Tokens/daiToken.svg`);
        default:
          return require(`@/Common/Icons/Tokens/polygonToken.svg`);
      }
    };

    const getImgUrl = (imagePath) => {
      return process.env.VUE_APP_IMAGE_URL + imagePath;
    };

    const addEllipsis = (title) => {
      return title.length > 14 ? title.substring(0, 23) + "..." : title;
    };

    onMounted(async () => {});

    return {
      getImgUrl,
      formatDate,
      modalActive,
      addEllipsis,
      toggleModal,
      getTokenImgUrl,
      checkFileMimeType,
      formatDateTimeString,
      formatDateWithoutYear,
      changeCamapignPFPImage,
      changeIconDepensOnCamapign,
      changeCamapignPFPImageHover,
      changeIconDepensOnCamapignPFPImage,
    };
  },
};
</script>
<style lang="scss" scoped src="@/Common/Styles/campaignCard.scss"></style>
