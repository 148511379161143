<template>
  <div class="app-input">
    <!-- <label :for="id" :class="labelClass" v-if="label">{{ labelText }}</label> -->
    <!-- <VueCtkDateTimePicker
      :name="name"
      v-bind="$attrs"
      :label="placeholder"
      v-model="inputValue"
      :class="[errorMessage ? 'error-message-border' : '']"
      @input="$emit('update:modelValue', $event.target.value)"
      :state="errorMessage ? false : true"
    >
    </VueCtkDateTimePicker> -->
    <v-date-picker
      :name="name"
      v-bind="$attrs"
      v-model="inputValue"
      :state="errorMessage ? false : true"
      is24hr
      mode="dateTime"
      :popover="{ visibility: 'focus' }"
    >
      <template v-slot="{ inputValue, inputEvents }">
        <label :for="id" :class="labelClass" v-if="label">{{
          labelText
        }}</label>
        <input
          class="form-box-input"
          :value="inputValue"
          v-on="inputEvents"
          :placeholder="placeholder"
          @input="$emit('update:modelValue', $event.target.value)"
          :class="[errorMessage ? 'error-message-border' : '']"
        />
      </template>
    </v-date-picker>
    <p class="input-error-message" v-show="errorMessage || meta.valid">
      {{ errorMessage || successMessage }}
    </p>
  </div>
</template>
<script>
import { eventBus } from "@/main";
import { useField } from "vee-validate";
export default {
  props: {
    name: {
      type: String,
    },
    placeholder: {
      type: String,
      default: "",
    },
    labelText: {
      type: String,
      default: "",
    },
    labelClass: {
      type: String,
      default: "",
    },
    label: {
      type: Boolean,
      default: true,
    },
    id: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const {
      value: inputValue,
      errorMessage,
      meta,
      resetField,
    } = useField(props.name, undefined, {
      initialValue: props.value,
    });

    eventBus.on("resetErrors", () => {
      resetField();
    });
    return {
      errorMessage,
      inputValue,
      meta,
    };
  },
};
</script>
