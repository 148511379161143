import { ethers } from "ethers";

const useUtilities = () => {
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const formatAmountToWei = (amount) => {
    const weiAmount = ethers.utils.parseEther(amount);
    return weiAmount;
  };
  const convertFromWei = (value) => {
    const amount = ethers.utils.formatEther(value);
    return amount;
  };

  function copyToClipboard(data) {
    return navigator.clipboard.writeText(data);
  }
  const formatDate = (date) => {
    const currentDate = new Date(date);
    const options = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    };
    return currentDate.toLocaleDateString("en-GB", options);
  };
  const formatDateWithoutYear = (date) => {
    const currentDate = new Date(date);
    const options = {
      month: "short",
      day: "numeric",
    };
    return currentDate.toLocaleDateString("en-us", options);
  };
  const formatDateTimeString = (date) => {
    const currentDate = new Date(date);
    const options = {
      hour: "numeric",
      minute: "numeric",
    };
    return currentDate.toLocaleTimeString(
      "en-GB",
      { hour: "2-digit", minute: "2-digit" },
      options
    );
  };
  const makeContarctAddressFromTopics = (element, length) => {
    return "0x" + element.slice(length);
  };
  const formatDateTime = (date) => {
    const formatedDateTime = new Date(date);
    formatedDateTime.setHours(0, 0, 0, 0);
    return new Date(
      Date.UTC(
        formatedDateTime.getFullYear(),
        formatedDateTime.getMonth(),
        formatedDateTime.getDate(),
        0,
        0,
        0
      )
    ).toISOString();
  };

  const formatDateGeneratedNft = (date) => {
    let myDate = new Date(date);
    let d = myDate.getDate();
    let m = myDate.getMonth();
    m += 1;
    let y = myDate.getFullYear();

    let newDate = d + "." + m + "." + y;
    return newDate;
  };
  const makeFileObject = async (url, fileName = "generatedNftVideo") => {
    let response = await fetch(url);
    let data = await response.blob();
    let metadata = {
      type: "video/mp4",
    };
    return new File([data], fileName, metadata);
  };
  const checkFileMimeType = (mimeType, type) => {
    return mimeType.toLowerCase().includes(type);
  };
  const comapreDates = (firstDate, secondDate) => {
    if (new Date(firstDate) >= new Date(secondDate)) {
      return true;
    }
    return false;
  };
  const createUtmLink = async (
    websiteUrl,
    campaignSource,
    campaignMedium,
    campaignName,
    cmapaignId,
    campaignTerm,
    walletAddress
  ) => {
    if (!websiteUrl || !campaignSource) return;
    const utmLink = new URL(websiteUrl);
    if (campaignSource)
      utmLink.searchParams.append("utm_source", campaignSource);
    if (campaignMedium)
      utmLink.searchParams.append("utm_medium", campaignMedium);
    if (campaignName) utmLink.searchParams.append("utm_campaign", campaignName);
    if (cmapaignId) utmLink.searchParams.append("utm_id", cmapaignId);
    if (campaignTerm) utmLink.searchParams.append("utm_term", campaignTerm);
    if (walletAddress)
      utmLink.searchParams.append("utm_content", walletAddress);

    let shortUrl = require("turl");
    const shorten = shortUrl
      .shorten(utmLink.href)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log(err);
      });
    return await shorten;
  };
  return {
    formatDate,
    comapreDates,
    createUtmLink,
    convertFromWei,
    formatDateTime,
    makeFileObject,
    copyToClipboard,
    formatAmountToWei,
    checkFileMimeType,
    formatDateTimeString,
    capitalizeFirstLetter,
    formatDateWithoutYear,
    formatDateGeneratedNft,
    makeContarctAddressFromTopics,
  };
};

export default useUtilities;
