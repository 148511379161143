<template>
  <instagram-campaign-dashboard />
</template>
<script>
import InstagramCampaignDashboard from "@/components/CreateTwitterCampaign/InstagramCampaignDashboard";

export default {
  components: {
    InstagramCampaignDashboard,
  },
  setup() {
    return {};
  },
};
</script>
