import { createStore } from "vuex";
import users from "./modules/users";
import moralis from "./modules/moralis";
import campaign from "./modules/campaign";
import claiming from "./modules/claiming";
import VuexPersistence from "vuex-persist";
import mintAirBro from "./modules/mintAirBro";
import transactions from "./modules/transactions";
import sendExistingToken from "./modules/sendExistingToken";
import customPfpRequirements from "./modules/customPfpRequirements";
import nftPfpRequirements from "./modules/nftPfpRequirements";
import postContentRequirements from "./modules/postContentRequirements";
import signUpCampaignCreator from "./modules/signUpCampaignCreator";
import aditionalRequirements from "./modules/aditionalRequirements";

const vuexLocal = new VuexPersistence({
  reducer: (state) => ({
    users: state.users,
  }),
  storage: window.localStorage,
});

export default createStore({
  modules: {
    users,
    moralis,
    campaign,
    claiming,
    mintAirBro,
    transactions,
    sendExistingToken,
    nftPfpRequirements,
    customPfpRequirements,
    postContentRequirements,
    signUpCampaignCreator,
    aditionalRequirements,
  },
  plugins: [vuexLocal.plugin],
});
