import { computed } from "vue";

const usePostContentRequirements = (store) => {
  const postVerificationTimeInMinutes = computed(() =>
    store.getters["postContentRequirements/getByKey"](
      "postVerificationTimeInMinutes"
    )
  );
  const postContent = computed(() =>
    store.getters["postContentRequirements/getByKey"]("postContent")
  );
  const pickWinnersType = computed(() =>
    store.getters["postContentRequirements/getByKey"]("pickWinnersType")
  );
  const utmWebsiteURL = computed(() =>
    store.getters["postContentRequirements/getByKey"]("utmWebsiteURL")
  );
  const utmCampaignID = computed(() =>
    store.getters["postContentRequirements/getByKey"]("utmCampaignID")
  );
  const utmCampaignSource = computed(() =>
    store.getters["postContentRequirements/getByKey"]("utmCampaignSource")
  );
  const utmCampaignMedium = computed(() =>
    store.getters["postContentRequirements/getByKey"]("utmCampaignMedium")
  );
  const utmCampaignName = computed(() =>
    store.getters["postContentRequirements/getByKey"]("utmCampaignName")
  );
  const utmCampaignTerm = computed(() =>
    store.getters["postContentRequirements/getByKey"]("utmCampaignTerm")
  );
  const changePostContent = (value) => {
    store.dispatch("postContentRequirements/commitByKey", {
      postContent: value,
    });
  };
  const changeUtmWebsiteURL = (value) => {
    store.dispatch("postContentRequirements/commitByKey", {
      utmWebsiteURL: value,
    });
  };
  const changeUtmCampaignID = (value) => {
    store.dispatch("postContentRequirements/commitByKey", {
      utmCampaignID: value,
    });
  };
  const changeUtmCampaignSource = (value) => {
    store.dispatch("postContentRequirements/commitByKey", {
      utmCampaignSource: value,
    });
  };
  const changeUtmCampaignMedium = (value) => {
    store.dispatch("postContentRequirements/commitByKey", {
      utmCampaignMedium: value,
    });
  };
  const changeUtmCampaignName = (value) => {
    store.dispatch("postContentRequirements/commitByKey", {
      utmCampaignName: value,
    });
  };
  const changeUtmCampaignTerm = (value) => {
    store.dispatch("postContentRequirements/commitByKey", {
      utmCampaignTerm: value,
    });
  };

  return {
    postContent,
    utmWebsiteURL,
    utmCampaignID,
    utmCampaignName,
    utmCampaignTerm,
    pickWinnersType,
    utmCampaignMedium,
    utmCampaignSource,
    postVerificationTimeInMinutes,
    changePostContent,
    changeUtmWebsiteURL,
    changeUtmCampaignID,
    changeUtmCampaignName,
    changeUtmCampaignTerm,
    changeUtmCampaignMedium,
    changeUtmCampaignSource,
  };
};

export default usePostContentRequirements;
