<template>
  <div>
    <div class="add-requirements-wrapper">
      <p>NUMBER OF FOLLOWERS</p>
    </div>
    <div class="number-of-folowers-wrapper">
      <div class="number-of-folowers-input-wrapper">
        <app-input
          type="text"
          v-model="numberOfFolowers"
          name="NumberOfFolowers"
          inputClass="form-box-input"
          labelClass="label"
          labelText="Number of Folowers"
          placeholder="Enter number "
          @change="changeNumberOfFolowers(numberOfFolowers)"
        />
      </div>
      <div class="number-of-folowers-explanation-text folowers">
        <div>
          <img
            src="@/Common/Icons/infoIcon.svg"
            alt="image-regular-twitter-pfp"
          />
        </div>
        <div class="number-of-folowers-text">
          <p>
            Define how many Twitter followers participants will have to have.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref } from "vue";
import { useStore } from "vuex";
import AppInput from "@/elements/AppInput";
import useAditionalRequirements from "@/composables/useAditionalRequirements";
export default {
  props: {
    componentName: {
      type: String,
      default: "",
    },
  },
  components: {
    AppInput,
  },
  setup() {
    const store = useStore();
    const { changeNumberOfFolowers } = useAditionalRequirements(store);
    const numberOfFolowers = ref("");

    return {
      numberOfFolowers,
      changeNumberOfFolowers,
    };
  },
};
</script>
//
<style lang="scss" scoped src="@/Common/Styles/numberOfFolowers.scss"></style>
