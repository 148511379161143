<template>
  <div class="how-it-works-wrapper">
    <section class="community-section">
      <div class="custom-container">
        <h1 class="epilogue-title-xl">Be part of the Bro’munity</h1>
        <div class="info-section">
          <div class="img-wrapper">
            <img
              src="@/Common/Icons/HowItWorksPage/communityVisual.svg"
              alt="Bro Community"
            />
          </div>
          <div class="join-card">
            <div class="info">
              <p class="epilogue-subtitle-thin">
                Join any available campaign, or be bold enough to make your own.
              </p>
              <div class="button campaign">
                <a class="epilogue-m" href="/create-twitter-campaign"
                  >CREATE CAMPAIGN</a
                >
              </div>
            </div>
            <div class="info">
              <p class="epilogue-subtitle-thin">
                Connect with our twitter community and make sure not to miss any
                new opportunities to help each other reach more visibility.
              </p>
              <div class="button twitter">
                <a class="epilogue-m" href="https://twitter.com/AirBr0"
                  >FOLLOW TWITTER</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="purple-gradient"></div>
    </section>
    <section class="pose-section">
      <div class="custom-container">
        <div class="pose-card">
          <h2 class="epilogue-title-l gray-base">PoSE</h2>
          <h3 class="epilogue-subtitle-alt green-base">
            PROOF OF SOCIAL ENGAGEMENT
          </h3>
          <p class="epilogue-text">
            PoSE is irrefutable evidence of your involvement in a campaign and a
            ticket to receive a reward from the creator. PoSE NFT can either be
            bound to an individual or purchased on a secondary market and may
            sometimes be a mandatory requirement for participating in campaigns.
          </p>
          <ul class="mobile epilogue-text white">
            <li>PoSE NFT can be won through campaigns.</li>
            <li>It is irrefutable evidence of your involvement and support.</li>
            <li>It is also a ticket for additional rewards.</li>
          </ul>
        </div>
      </div>
    </section>
    <section class="pfp-campaign-wrapper">
      <div class="custom-container">
        <section class="pfp-campaign">
          <div class="info">
            <h2 class="epilogue-title-xl">PFP Campaign</h2>
            <h3 class="epilogue-subtitle green-light">PROFILE PICTURE WAVE</h3>
            <p class="epilogue-text">
              Unlock a New Era of Awareness and Growth with AirBro...
            </p>
            <p class="epilogue-text">
              The Web3 Tool That Revolutionises Profile Pictures!
            </p>
            <p class="epilogue-text">
              When creating a campaign, simply select your desired Image or NFT
              and let your community members spread the word by holding it as
              their Twitter PFPs for the duration of your campaign.
            </p>
            <p class="epilogue-text">
              This feature is native to AirBro and lets you maximise the
              advertising potential Twitter has!
            </p>
          </div>
          <img src="@/Common/Icons/HowItWorksPage/PFPBro.svg" alt="PFP Bro" />
        </section>
      </div>
    </section>
    <section class="tweet-campaign-wrapper">
      <div class="custom-container">
        <section class="tweet-campaign">
          <img
            src="@/Common/Icons/HowItWorksPage/tweetBro.svg"
            alt="Tweet Bro"
          />
          <div class="info">
            <h2 class="epilogue-title-xl">Tweet Campaign</h2>
            <h3 class="epilogue-subtitle green-light">TWEET THUNDER</h3>
            <p class="epilogue-text">
              Get Your Message Heard Loud and Clear with AirBro's Tweet
              Campaigns!
            </p>
            <p class="epilogue-text">
              Get your loyal community to spread the word about your amazing
              project by tweeting custom messages, and track their engagement
              with ease using UTM links
            </p>
          </div>
        </section>
      </div>
    </section>
    <section class="mobile-campaign-info-wrapper">
      <div class="custom-container">
        <section class="campaign-info">
          <img
            src="@/Common/Icons/HowItWorksPage/mobileCampaign.svg"
            alt="Campaign Bro"
          />
          <div class="info">
            <h2 class="epilogue-title-m white">PFP Campaign</h2>
            <h3 class="epilogue-subtitle green-light">PROFILE PICTURE WAVE</h3>
            <p class="epilogue-text">
              Unlock a New Era of Awareness and Growth with AirBro...
            </p>
            <p class="epilogue-text">
              The Web3 Tool That Revolutionises Profile Pictures!
            </p>
            <p class="epilogue-text">
              When creating a campaign, simply select your desired Image or NFT
              and let your community members spread the word by holding it as
              their Twitter PFPs for the duration of your campaign.
            </p>
            <p class="epilogue-text">
              This feature is native to AirBro and lets you maximise the
              advertising potential Twitter has!
            </p>
          </div>
          <div class="info">
            <h2 class="epilogue-title-m white">Tweet Campaign</h2>
            <h3 class="epilogue-subtitle green-light">TWEET THUNDER</h3>
            <p class="epilogue-text">
              Get Your Message Heard Loud and Clear with AirBro's Tweet
              Campaigns!
            </p>
            <p class="epilogue-text">
              Get your loyal community to spread the word about your amazing
              project by tweeting custom messages, and track their engagement
              with ease using UTM links
            </p>
          </div>
        </section>
      </div>
    </section>
    <faq />
  </div>
</template>
<script>
import Faq from "@/components/HomePage/Faq";

export default {
  name: "HowItWorks",
  components: {
    Faq,
  },
  setup() {
    return {};
  },
};
</script>
<style lang="scss" scoped src="@/Common/Styles/howItWorks.scss"></style>
