<template>
  <div class="campaign-page-view">
    <div class="custom-container">
      <campaign-page />
    </div>
  </div>
</template>
<script>
import CampaignPage from "@/components/SingleCampaignPage/CampaignPage";
export default {
  components: {
    CampaignPage,
  },

  setup() {
    return {};
  },
};
</script>
<style lang="scss" scoped src="@/Common/Styles/campaignPageView.scss"></style>
