<template>
  <div class="footer">
    <div class="epilogue-text">
      <p>Made by bros from <span>AirBro</span></p>
    </div>
  </div>
</template>
<script>
export default {
  setup() {
    return {};
  },
};
</script>
<style lang="scss" scoped src="@/Common/Styles/airBroFooter.scss"></style>
