<template>
  <div class="app-input">
    <label :for="id" :class="labelClass">{{ labelText }}</label>
    <input
      v-bind="$attrs"
      :name="name"
      v-model="inputValue"
      @input="$emit('update:modelValue', $event.target.value)"
      :id="id"
      :placeholder="placeholder"
      :class="[inputClass, errorMessage ? 'error-message-border' : '']"
      :disabled="disabled"
      v-on="subscribeInput ? validationListeners : ''"
    />
    <p
      class="input-error-message"
      :class="[errorMessage ? 'error' : '']"
      v-show="errorMessage"
    >
      {{ errorMessage }}
    </p>
  </div>
</template>

<script>
import { eventBus } from "@/main";
import { toRefs, watch, computed } from "vue";
import { useField } from "vee-validate";

export default {
  props: {
    id: {
      type: String,
    },
    name: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "",
    },
    labelText: {
      type: String,
      default: "",
    },
    modelValue: {
      type: [String, Number],
      default: "",
    },
    inputClass: {
      type: String,
      default: "",
    },
    labelClass: {
      type: String,
      default: "",
    },
    successMessage: {
      type: String,
      default: "",
    },
    subscribeInput: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const {
      value: inputValue,
      errorMessage,
      handleChange,
      meta,
      resetField,
    } = useField(props.name, undefined, {
      initialValue: props.value,
      ...(props.subscribeInput && { validateOnValueUpdate: false }),
    });

    eventBus.on("resetErrors", () => {
      resetField();
    });

    let { modelValue } = toRefs(props);
    watch(
      () => modelValue,
      (modelValue) => {
        inputValue.value = modelValue.value;
      },
      { deep: true }
    );
    const validationListeners = computed(() => {
      if (!errorMessage.value) {
        return {
          change: handleChange,
          input: (e) => handleChange(e, false),
        };
      }
      return {
        change: handleChange,
        input: handleChange,
      };
    });
    watch(errorMessage, (newValue) => {
      newValue
        ? eventBus.emit("errorMessage", newValue)
        : eventBus.emit("errorMessage", errorMessage.value);
    });
    return {
      validationListeners,
      errorMessage,
      inputValue,
      meta,
    };
  },
};
</script>
