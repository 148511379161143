import Moralis from "@/plugins/moralis";

const initialState = () => ({
  errors: [],
  tokensPerClaim: "",
  totalAirdropAmount: "",
  walletTokenBalances: [],
  selectedTokenAddress: {},
  optionsWalletBalances: [],
});
const state = initialState();

const getters = {
  getByKey: (state) => (key) => state[key],
};

const mutations = {
  commitByKey(state, object) {
    Object.keys(object).forEach((key) => (state[key] = object[key]));
  },
  pushError(state, error) {
    state.errors.push(error);
  },
  clearErrors(state) {
    state.errors.splice(0);
  },
  setWalletTokenBalances(state, walletTokenBalances) {
    state.walletTokenBalances = walletTokenBalances;
  },
  setOptionsWalletBalances(state, optionsWalletBalances) {
    state.optionsWalletBalances.push(optionsWalletBalances);
  },
  setTokensPerClaim(state, tokensPerClaim) {
    state.tokensPerClaim = tokensPerClaim;
  },
  resetState(state) {
    Object.assign(state, initialState());
  },
};

const actions = {
  resetState({ commit }) {
    commit("resetState");
  },
  commitByKey({ commit }, object) {
    commit("commitByKey", object);
  },

  async fetchWalletBalances({ commit }, options) {
    try {
      const response = await Moralis.Web3API.account.getTokenBalances(options);
      commit("setWalletTokenBalances", response);
      response.forEach((token) => {
        commit("setOptionsWalletBalances", {
          label: token.symbol,
          value: token.token_address,
        });
      });
      return response;
    } catch (error) {
      commit("pushError", error);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
