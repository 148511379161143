<template>
  <div class="token-reward-explanation-text">
    <div>
      <img
        src="@/Common/Icons/image-regular-twitter-pfp.svg"
        alt="image-regular-twitter-pfp"
      />
    </div>
    <div class="token-reward-text">
      <p>
        Fund the campaign contract with amount of tokens that you want. It will
        be equally distributed between the eligible participants. Everything
        that ends unclaimed will be available for refund.
      </p>
    </div>
  </div>
  <div class="send-existing-token-wrapper">
    <div class="existing-token-data">
      <div class="token-data-wrap row">
        <div class="input-wrap col-md-6">
          <app-select
            type="text"
            name="TokenToDistribute"
            v-model="tokenAddress"
            labelClass="label"
            labelText="Choose a Token"
            selectClass="form-box-select"
            :options="optionsWalletBalances"
            placeholder="Select the token from your wallet"
            @change="changeTokenAddress"
          />
        </div>
        <div class="input-wrap col-md-6">
          <app-input
            type="text"
            v-model="tokenAmount"
            name="AmountToDistribute"
            inputClass="form-box-input"
            labelClass="label"
            labelText="Enter Amount"
            placeholder="0"
            @change="changeTokenAmount"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { useStore } from "vuex";
import AppInput from "@/elements/AppInput";
import { onMounted, ref } from "vue";
import useAuth from "@/composables/useAuth";
import AppSelect from "@/elements/AppSelect";
import useUtilities from "@/composables/useUtilities";
import useSendExistingToken from "@/composables/useSendExistingToken";

export default {
  name: "SendExistingToken",
  components: {
    AppInput,
    AppSelect,
  },
  setup() {
    const store = useStore();
    const { fetchWalletBalances, optionsWalletBalances } =
      useSendExistingToken(store);
    const { address, networkName } = useAuth(store);
    const { formatAmountToWei } = useUtilities();
    const tokenAddress = ref("");
    const tokenAmount = ref("");

    const changeTokenAddress = () => {
      store.dispatch("sendExistingToken/commitByKey", {
        selectedTokenAddress: JSON.parse(tokenAddress.value),
      });
    };
    const changeTokenAmount = () => {
      store.dispatch("sendExistingToken/commitByKey", {
        totalAirdropAmount: tokensToSend(tokenAmount.value),
      });
    };
    const tokensToSend = (tokenSupply) => {
      let tokens = formatAmountToWei(tokenSupply);
      return tokens;
    };

    onMounted(async () => {
      store.dispatch("moralis/resetState");
      const options = { chain: networkName.value, address: address.value };
      await fetchWalletBalances(options);
    });
    return {
      address,
      networkName,
      tokenAmount,
      tokenAddress,
      changeTokenAmount,
      changeTokenAddress,
      fetchWalletBalances,
      optionsWalletBalances,
    };
  },
};
</script>
<style lang="scss" scoped src="@/Common/Styles/sendExistingToken.scss"></style>
