<template>
  <section class="countdown-card">
    <div class="card">
      <div class="card-header">
        <h3 class="epilogue-text-m-dark">
          <template v-if="currentCampaignData.status === 'ended'">
            Campaign Ended
          </template>
          <template v-else-if="currentCampaignData.status === 'active'">
            Campaign ends in
          </template>
          <template v-else-if="currentCampaignData.status === 'pending'">
            Campaign starts in
          </template>
        </h3>
        <h2 class="epilogue-subtitle-alt">
          <template v-if="currentCampaignData.status === 'ended'">
            {{ formatDate(currentCampaignData.endDate) }}
          </template>
          <timer-full
            v-else-if="currentCampaignData.status === 'active'"
            :time="currentCampaignData.endDate"
          />
          <timer-full
            v-else-if="currentCampaignData.status === 'pending'"
            :time="currentCampaignData.startDate"
          />
        </h2>
      </div>
    </div>
  </section>
</template>
<script>
import { onMounted, ref, watch } from "vue";
import TimerFull from "@/components/TimerFull";
import useUtilities from "@/composables/useUtilities";

export default {
  name: "CampaignCountdownCard",
  components: {
    TimerFull,
  },
  props: {
    campaignData: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const { formatDate } = useUtilities();

    const currentCampaignData = ref(props.campaignData);

    watch(
      () => props.campaignData,
      (newVal, oldVal) => {
        oldVal;
        currentCampaignData.value = newVal;
      }
    );

    onMounted(async () => {});
    return {
      formatDate,
      currentCampaignData,
    };
  },
};
</script>
<style lang="scss" scoped src="@/Common/Styles/countdownCard.scss"></style>
