<template>
  <div class="campaign-requirements">
    <app-accordion
      :isOpen="isOpen"
      :toggleAccordion="toggleAccordion"
      id="requirements"
    >
      <template v-slot:title>
        <div
          class="campaign-requirements-wrapper"
          :class="{ accordionOpen: isOpen }"
        >
          <div class="illustration-wrapper">
            <img
              src="@/Common/Icons/requirementsIllustration.svg"
              alt="requirementsIllustration"
              class="campaign-requirements-illustration"
            />
          </div>
          <div class="headline-text-wrapper">
            <span
              class="campaign-requirements-headline"
              :class="{ campaignHeadlineAccordionOpen: isOpen }"
              >Requirements</span
            >
            <span class="camapign-requirements-undertext"
              >Define what is necessary for participant to do.</span
            >
          </div>
          <div class="arrow-wrapper">
            <img
              src="@/Common/Icons/accordionArrowDown.svg"
              alt="arrowUp"
              v-if="!isOpen"
            />
            <img
              src="@/Common/Icons/accordionArrowUp.svg"
              alt="arrowDown"
              v-else
            />
          </div>
        </div>
      </template>
      <template v-slot:content>
        <div class="requirements-content">
          <div class="main-requirements">
            <div class="requirements-text-buttons-wrapper">
              <p class="main-requirements-text">
                Main Campaign Requirements <span>(pick one)</span>
              </p>
              <div class="tab-requirements-buttons-wrap">
                <app-button
                  text="Change PFP - Regular Image"
                  :requirementsButton="true"
                  :iconSource="regularImageIcon"
                  class="campaign-requirements-tab-button customPFP"
                  :class="{
                    active:
                      getCampaignRequirementsComponentName === 'CustomPFP',
                  }"
                  @click.prevent="changeComponent('CustomPFP', 'image')"
                />
                <app-button
                  text="Change PFP - NFT"
                  :requirementsButton="true"
                  :iconSource="nftPfpIcon"
                  class="campaign-requirements-tab-button nftPFP"
                  :class="{
                    active: getCampaignRequirementsComponentName === 'NftPFP',
                  }"
                  @click.prevent="changeComponent('NftPFP', 'nft')"
                />
                <app-button
                  text="Post Tweet"
                  :requirementsButton="true"
                  :iconSource="postTweetIcon"
                  class="campaign-requirements-tab-button post-content"
                  :class="{
                    active:
                      getCampaignRequirementsComponentName === 'PostContent',
                  }"
                  @click.prevent="changeComponent('PostContent', 'post')"
                />
              </div>
            </div>
          </div>

          <component :is="getCampaignRequirementsComponentName" />

          <div class="additional-requirements">
            <div class="requirements-text-buttons-wrapper">
              <p class="additional-requirements-text">
                Additional Requirements <span>(pick multiple)</span>
              </p>
              <div class="tab-requirements-buttons-wrap-additional-buttons">
                <app-button
                  text="Number of Twitter Followers"
                  :requirementsButton="true"
                  :iconSource="numberOfTwitterFollowersIcon"
                  class="campaign-requirements-tab-button customPFP"
                  :class="{
                    active: isTwitterFolowers,
                    disabled: !getCampaignRequirementsComponentName,
                  }"
                  @click.prevent="twitterFolowersToggle"
                  :disabled="!getCampaignRequirementsComponentName"
                />
                <app-button
                  text="Twitter Account Created Before"
                  :requirementsButton="true"
                  :iconSource="accountCreatedDateIcon"
                  class="campaign-requirements-tab-button nftPFP"
                  :class="{
                    active: isTwitterAccoutCreated,
                    disabled: !getCampaignRequirementsComponentName,
                  }"
                  @click.prevent="twitterAccoutCreatedToggle"
                  :disabled="!getCampaignRequirementsComponentName"
                />
                <app-button
                  text="Proof of NFT Ownership"
                  :requirementsButton="true"
                  :iconSource="proofOfOwnershipIcon"
                  class="campaign-requirements-tab-button post-content"
                  :class="{
                    active: isProofOfOwnership,
                    disabled: !getCampaignRequirementsComponentName,
                  }"
                  @click.prevent="proofOfOwnershipToggle"
                  :disabled="!getCampaignRequirementsComponentName"
                />
                <app-button
                  text="Generate UTM Link"
                  :requirementsButton="true"
                  :iconSource="utmLinkIcon"
                  class="campaign-requirements-tab-button post-content"
                  :class="{
                    active: isUtmLink,
                    disabled:
                      getCampaignRequirementsComponentName !== 'PostContent',
                  }"
                  @click.prevent="utmLinkToggle"
                  :disabled="
                    getCampaignRequirementsComponentName !== 'PostContent'
                  "
                />
              </div>
            </div>
          </div>
          <number-of-folowers v-show="isTwitterFolowers" />
          <twitter-acount-created-before v-show="isTwitterAccoutCreated" />
          <proof-of-nft-ownership v-show="isProofOfOwnership" />
          <utm-link v-show="isUtmLink" />
        </div>
      </template>
    </app-accordion>
  </div>
</template>

<script>
import { ref, computed } from "vue";
import { useStore } from "vuex";
import AppButton from "@/elements/AppButton";
import useCampaign from "@/composables/useCampaign";
import AppAccordion from "@/elements/AppAccordion.vue";
import NftPFP from "@/components/CreateTwitterCampaign/CampaignRequirementsWrapper/NftPFP";
import CustomPFP from "@/components/CreateTwitterCampaign/CampaignRequirementsWrapper/CustomPFP";
import PostContent from "@/components/CreateTwitterCampaign/CampaignRequirementsWrapper/PostContent";
import UtmLink from "@/components/CreateTwitterCampaign/CampaignRequirementsWrapper/UtmLink";
import NumberOfFolowers from "@/components/CreateTwitterCampaign/CampaignRequirementsWrapper/NumberOfFolowers";
import TwitterAcountCreatedBefore from "@/components/CreateTwitterCampaign/CampaignRequirementsWrapper/TwitterAcountCreatedBefore";
import ProofOfNftOwnership from "@/components/CreateTwitterCampaign/CampaignRequirementsWrapper/ProofOfNftOwnership";

export default {
  components: {
    AppButton,
    PostContent,
    CustomPFP,
    NftPFP,
    AppAccordion,
    NumberOfFolowers,
    TwitterAcountCreatedBefore,
    ProofOfNftOwnership,
    UtmLink,
  },
  setup() {
    const store = useStore();
    const { getCampaignRequirementsComponentName } = useCampaign(store);
    const campaignType = ref("image");
    const isTwitterFolowers = ref(false);
    const isTwitterAccoutCreated = ref(false);
    const isProofOfOwnership = ref(false);
    const isUtmLink = ref(false);
    const isOpen = ref(false);

    const regularImageIcon = computed(() => {
      if (getCampaignRequirementsComponentName.value === "CustomPFP") {
        return require(`@/Common/Icons/regularImageIconActive.svg`);
      } else {
        return require(`@/Common/Icons/regularImageIcon.svg`);
      }
    });
    const nftPfpIcon = computed(() => {
      if (getCampaignRequirementsComponentName.value === "NftPFP") {
        return require(`@/Common/Icons/nftPfpIconActive.svg`);
      } else {
        return require(`@/Common/Icons/nftPfpIcon.svg`);
      }
    });
    const postTweetIcon = computed(() => {
      if (getCampaignRequirementsComponentName.value === "PostContent") {
        return require(`@/Common/Icons/postTweetIconActive.svg`);
      } else {
        return require(`@/Common/Icons/postTweetIcon.svg`);
      }
    });
    const numberOfTwitterFollowersIcon = computed(() => {
      if (isTwitterFolowers.value) {
        return require(`@/Common/Icons/numberOfTwitterFollowersIconActive.svg`);
      } else {
        return require(`@/Common/Icons/numberOfTwitterFollowersIcon.svg`);
      }
    });
    const accountCreatedDateIcon = computed(() => {
      if (isTwitterAccoutCreated.value) {
        return require(`@/Common/Icons/accountCreatedDateIconActive.svg`);
      } else {
        return require(`@/Common/Icons/accountCreatedDateIcon.svg`);
      }
    });
    const proofOfOwnershipIcon = computed(() => {
      if (isProofOfOwnership.value) {
        return require(`@/Common/Icons/proofOfOwnershipIconActive.svg`);
      } else {
        return require(`@/Common/Icons/proofOfOwnershipIcon.svg`);
      }
    });

    const utmLinkIcon = computed(() => {
      if (isUtmLink.value) {
        return require(`@/Common/Icons/utmLinkIconActive.svg`);
      } else {
        return require(`@/Common/Icons/utmLinkIcon.svg`);
      }
    });

    const changeComponent = (name, type) => {
      campaignType.value = type;
      store.dispatch("campaign/commitByKey", {
        campaignRequirementsComponentName: name,
      });
      store.dispatch("campaign/commitByKey", {
        campaignType: campaignType.value,
      });
    };
    const toggleAccordion = () => {
      isOpen.value = !isOpen.value;
    };
    const twitterFolowersToggle = () => {
      isTwitterFolowers.value = !isTwitterFolowers.value;
    };
    const twitterAccoutCreatedToggle = () => {
      isTwitterAccoutCreated.value = !isTwitterAccoutCreated.value;
    };
    const proofOfOwnershipToggle = () => {
      isProofOfOwnership.value = !isProofOfOwnership.value;
    };
    const utmLinkToggle = () => {
      isUtmLink.value = !isUtmLink.value;
    };
    return {
      isOpen,
      toggleAccordion,
      changeComponent,
      getCampaignRequirementsComponentName,
      regularImageIcon,
      nftPfpIcon,
      postTweetIcon,
      numberOfTwitterFollowersIcon,
      accountCreatedDateIcon,
      proofOfOwnershipIcon,
      utmLinkIcon,
      twitterFolowersToggle,
      twitterAccoutCreatedToggle,
      proofOfOwnershipToggle,
      utmLinkToggle,
      isTwitterFolowers,
      isTwitterAccoutCreated,
      isProofOfOwnership,
      isUtmLink,
    };
  },
};
</script>
<style
  lang="scss"
  scoped
  src="@/Common/Styles/campaignRequirements.scss"
></style>
