<template>
  <div class="campaign-page d-flex flex-row">
    <div class="info-section">
      <campaign-page-info :campaignData="singleCamapignData" />
    </div>
    <div v-if="width > 900" class="requirements-section">
      <join-campaign-req-wrapper />
    </div>
    <requirements-bar v-else :campaignID="singleCamapignData.id" />
  </div>
</template>
<script>
import { onMounted, ref, onUnmounted, computed } from "vue";
import { useStore } from "vuex";
import useAuth from "@/composables/useAuth";
import useCampaign from "@/composables/useCampaign";
import CampaignPageInfo from "@/components/SingleCampaignPage/CampaignPageInfo";
import JoinCampaignReqWrapper from "@/components/SingleCampaignPage/JoinCampaignRequirementSteps/JoinCampaignReqWrapper.vue";
import RequirementsBar from "@/components/SingleCampaignPage/RequirementsBar.vue";

export default {
  components: {
    CampaignPageInfo,
    JoinCampaignReqWrapper,
    RequirementsBar,
  },
  setup() {
    const store = useStore();
    const {
      getCampaignByIdLogged,
      getCampaignById,
      singleCamapignData,
      fetchNFTsForContract,
    } = useCampaign(store);

    const { isLoggedIn, networkName, address } = useAuth(store);

    let windowWidth = ref(window.innerWidth);

    const onWidthChange = () => (windowWidth.value = window.innerWidth);

    const width = computed(() => windowWidth.value);

    onMounted(async () => {
      const campaignId = window.location.pathname.split("/")[2];
      if (isLoggedIn.value) {
        await getCampaignByIdLogged(campaignId);
        await store.dispatch("campaign/commitByKey", {
          campaignId: Number(campaignId),
        });
      } else {
        await getCampaignById(campaignId);
        await store.dispatch("campaign/commitByKey", {
          campaignId: Number(campaignId),
        });
      }
      if (address.value && singleCamapignData.value.tokenRequirements) {
        const options = {
          chain: networkName?.value,
          address: address.value,
          token_address: singleCamapignData.value.tokenRequirements,
        };
        fetchNFTsForContract(options);
      }
      window.addEventListener("resize", onWidthChange);
    });

    onUnmounted(() => window.removeEventListener("resize", onWidthChange));

    return {
      width,
      singleCamapignData,
    };
  },
};
</script>
<style lang="scss" scoped src="@/Common/Styles/campaignPage.scss"></style>
