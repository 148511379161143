import { computed } from "vue";

const useTransactions = (store) => {
  const isTarnsationSuccess = computed(() =>
    store.getters["transactions/getByKey"]("isTarnsationSuccess")
  );
  const isTransactionFinished = computed(() =>
    store.getters["transactions/getByKey"]("isTransactionFinished")
  );

  const tokenToSendComponent = computed(() =>
    store.getters["transactions/getByKey"]("tokenToSendComponent")
  );
  const currentCampaignData = computed(() =>
    store.getters["transactions/getByKey"]("currentCampaignData")
  );
  const isCampaignShareModalActive = computed(() =>
    store.getters["transactions/getByKey"]("isCampaignShareModalActive")
  );

  const txHash = computed(() =>
    store.getters["transactions/getByKey"]("txHash")
  );

  const rewardNftToNftHolders = (
    rewardedNftCollection,
    newNftCollectionName,
    newNftCollectionSymbol,
    newNftSupply,
    baseUri
  ) => {
    store.dispatch("transactions/rewardNftToNftHolders", {
      rewardedNftCollection,
      newNftCollectionName,
      newNftCollectionSymbol,
      newNftSupply,
      baseUri,
    });
  };

  const dropNewTokensToNftHolders = (
    rewardedNftCollection,
    newTokenName,
    newTokenSymbol,
    tokensPerClaimNew
  ) => {
    store.dispatch("transactions/dropNewTokensToNftHolders", {
      rewardedNftCollection,
      newTokenName,
      newTokenSymbol,
      tokensPerClaimNew,
    });
  };

  const dropExistingTokensToNftHolders = (
    rewardedNftCollection,
    tokensPerClaimExisting,
    rewardToken,
    totalAirdropAmount
  ) => {
    store.dispatch("transactions/dropExistingTokensToNftHolders", {
      rewardedNftCollection,
      tokensPerClaimExisting,
      rewardToken,
      totalAirdropAmount,
    });
  };

  const dropExisting1155NftsToNftHolders = (
    rewardedNftCollection,
    reward1155Nft,
    tokensPerClaim,
    tokenId,
    totalAirdropAmount
  ) => {
    store.dispatch("transactions/dropExisting1155NftsToNftHolders", {
      rewardedNftCollection,
      reward1155Nft,
      tokensPerClaim,
      tokenId,
      totalAirdropAmount,
    });
  };
  return {
    rewardNftToNftHolders,
    dropNewTokensToNftHolders,
    dropExistingTokensToNftHolders,
    dropExisting1155NftsToNftHolders,
    isTarnsationSuccess,
    isTransactionFinished,
    txHash,
    currentCampaignData,
    tokenToSendComponent,
    isCampaignShareModalActive,
  };
};
export default useTransactions;
