<template>
  <div v-show="modalActive" @click="close" class="modal-overflow"></div>
  <transition name="modal-animation">
    <div v-show="modalActive" class="modal" :class="modalClass">
      <transition name="modal-animation-inner">
        <div
          class="modal-inner"
          :class="[
            claimingModal ? 'claimnig-inner' : proofModal ? 'proof-inner' : '',
          ]"
        >
          <div class="modal-header">
            <slot name="modal-header"></slot>
            <img
              @click="close"
              class="x-modal-button"
              src="@/Common/Icons/xButton.svg"
              alt="XModalButton"
              v-if="isXbutton"
            />
          </div>
          <div class="modal-content">
            <slot />
          </div>
        </div>
      </transition>
    </div>
  </transition>
</template>

<script>
import { onMounted, onUnmounted } from "vue";
import { useRouter } from "vue-router";
export default {
  props: {
    modalClass: {
      type: String,
    },
    modalActive: {
      type: Boolean,
      default: false,
    },
    claimingModal: {
      type: Boolean,
      default: false,
    },
    proofModal: {
      type: Boolean,
      default: false,
    },
    batchClaim: {
      type: Function,
      default: () => {},
    },
    claim: {
      type: Function,
      default: () => {},
    },
    isXbutton: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, context) {
    const router = useRouter();
    const close = () => {
      context.emit("close");
      router.replace({ query: null });
    };
    onMounted(() => document.body.classList.add("stop-scroll"));
    onUnmounted(() => document.body.classList.remove("stop-scroll"));
    return { close };
  },
};
</script>
<style lang="scss" scoped src="@/Common/Styles/modal.scss"></style>
