<template>
  <template v-if="width < 1000">
    <mobile-unavailable />
  </template>
  <template v-else>
    <twitter-campaign-dashboard />
  </template>
</template>
<script>
import { onMounted, ref, computed, onUnmounted } from "vue";
import TwitterCampaignDashboard from "@/components/CreateTwitterCampaign/TwitterCampaignDashboard";
import MobileUnavailable from "../components/MobileUnavailable.vue";

export default {
  components: {
    MobileUnavailable,
    TwitterCampaignDashboard,
  },
  setup() {
    let windowWidth = ref(window.innerWidth);
    const onWidthChange = () => (windowWidth.value = window.innerWidth);
    const width = computed(() => windowWidth.value);

    onMounted(async () => {
      window.addEventListener("resize", onWidthChange);
    });

    onUnmounted(() => window.removeEventListener("resize", onWidthChange));

    return {
      width,
    };
  },
};
</script>
