<template>
  <div
    class="action-to-join-req"
    :class="{
      disable:
        !twHandle ||
        !twitterFolowersComaparation ||
        singleCamapignData.status === 'ended',
    }"
  >
    <div class="action-to-join-headline">
      <div v-if="singleCamapignData.avaliableFlag === 'joined'">
        <div class="campaign-joined">
          <p>3. Campaign Joined</p>
          <img src="@/Common/Icons/doneIcon.gif" alt="done" />
        </div>
      </div>
      <div v-else>
        <div>
          <div class="campaign-joined" v-if="socialProfileData.postUrl">
            <p>3. Verified</p>
            <img src="@/Common/Icons/doneIcon.gif" alt="done" />
          </div>
          <div
            class="campaign-joined"
            v-if="socialProfileData.statusCode === 400"
          >
            <p>3. Verification failed</p>
            <img src="@/Common/Icons/notDoneIcon.svg" alt="Not done" />
          </div>
        </div>
        <div
          class="verifying-image"
          v-if="!socialProfileData.postUrl && !socialProfileData.statusCode"
        >
          <p>3. Post Tweet</p>
          <img
            v-if="isLoader"
            src="@/Common/Icons/loaderThreeDots.gif"
            alt="done"
          />
        </div>
      </div>
      <span v-if="singleCamapignData.avaliableFlag === 'joined'"
        >Don't remove the tweet until the end of the campaign.</span
      >
      <span v-else>Post tweet on your twitter </span>
    </div>
    <div class="download-pfp-requirements">
      <div class="req-image-wrapper">
        <div class="headline-wrapper">
          <div>
            <div class="download-pfp-headline">
              <img src="@/Common/Icons/statusIcon.svg" alt="ConditionsIcon" />
              <div>
                <span>Status</span>
                <div class="require-condition">
                  <p
                    v-if="
                      !isPostItAction &&
                      !socialProfileData.postUrl &&
                      singleCamapignData.avaliableFlag !== 'joined'
                    "
                  >
                    Post
                  </p>
                  <p
                    v-if="
                      isPostItAction &&
                      !socialProfileData.postUrl &&
                      singleCamapignData.avaliableFlag !== 'joined'
                    "
                  >
                    Verify
                  </p>
                  <p
                    v-if="
                      socialProfileData.postUrl &&
                      singleCamapignData.avaliableFlag !== 'joined'
                    "
                  >
                    Join
                  </p>
                  <p v-if="singleCamapignData.avaliableFlag === 'joined'">
                    Joined
                  </p>
                  <p v-if="singleCamapignData.avaliableFlag === 'claim'">
                    Claim
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="download-pfp-image-wrapper">
        <img
          v-if="singleCamapignData.awardingCollImagePath"
          :src="getImgUrl(singleCamapignData.awardingCollImagePath)"
          alt="requirementImage"
        />
      </div>
    </div>

    <div
      class="download-pfp-button-wrapper"
      v-if="singleCamapignData.avaliableFlag !== 'joined'"
    >
      <ShareNetwork
        v-for="network in networks"
        :network="network.network"
        :key="network.network"
        url=""
        :title="postText"
        :description="sharing.description"
        :quote="sharing.quote"
        :hashtags="sharing.hashtags"
      >
        <div
          class="span-wrapper small-button action-type-download"
          @click.prevent="postIt"
        >
          <span>POST TWEET</span>
        </div>
      </ShareNetwork>
      <app-button
        :text="buttonText"
        class="small-button"
        @click.prevent="verifySocilaNetworkReq"
        :disabled="
          !twHandle ||
          !twitterFolowersComaparation ||
          singleCamapignData.status === 'ended'
        "
      />
    </div>
  </div>
</template>
<script>
import { ref, computed, onMounted, watch } from "vue";
import { useStore } from "vuex";
import AppButton from "@/elements/AppButton";
import useAuth from "@/composables/useAuth";
import useCampaign from "@/composables/useCampaign";
import useUtilities from "@/composables/useUtilities";

export default {
  components: {
    AppButton,
  },
  setup() {
    const store = useStore();
    const { twHandle, twFollowersCount, address } = useAuth(store);
    const { singleCamapignData, getSocialProfileData, socialProfileData } =
      useCampaign(store);
    const { createUtmLink } = useUtilities();

    const isPostItAction = ref(false);
    const isLoader = ref(false);
    const buttonText = ref("VERIFY");
    const postText = ref("");

    const postIt = () => {
      isPostItAction.value = true;
    };

    const verifySocilaNetworkReq = async () => {
      store.dispatch("campaign/commitByKey", {
        socialProfileData: {},
      });
      store.dispatch("campaign/commitByKey", {
        errors: [],
      });
      isLoader.value = true;
      buttonText.value = "VERIFYING...";
      const data = {
        postContent: postText.value,
        handle: twHandle.value,
        socialNetwork: singleCamapignData.value.socialNetwork,
        campaignId: singleCamapignData.value.id,
      };
      await getSocialProfileData(data);
      if (singleCamapignData.value.proofType === "post") {
        await store.dispatch("users/commitByKey", {
          linkToContent: socialProfileData.value.postUrl,
        });
      }
      isLoader.value = false;
      buttonText.value = "VERIFY";
    };
    const getImgUrl = (imagePath) => {
      return process.env.VUE_APP_IMAGE_URL + imagePath;
    };
    const sharing = ref({
      description: "",
      quote: "",
      hashtags: [""],
      twitterUser: "",
    });
    const networks = ref([
      {
        network: "twitter",
        name: "Twitter",
      },
    ]);
    const campaignId = window.location.pathname.split("/")[2];
    const url = ref(window.location.origin + "/campaign/" + campaignId);

    const twitterFolowersComaparation = computed(() => {
      return twFollowersCount.value >= singleCamapignData.value.profileFollowers
        ? true
        : false;
    });

    const utmLink = async (campData) => {
      if (campData.utmLinkParams) {
        const utm = await createUtmLink(
          JSON.parse(campData.utmLinkParams).url,
          JSON.parse(campData.utmLinkParams).campaignSource,
          JSON.parse(campData.utmLinkParams).campaignMedium,
          JSON.parse(campData.utmLinkParams).campaignName,
          JSON.parse(campData.utmLinkParams).cmapaignId,
          JSON.parse(campData.utmLinkParams).campaignTerm,
          address.value
        );
        store.dispatch("users/commitByKey", {
          utm: utm,
        });
        if (JSON.parse(campData.sharingPost).title.includes("%%UTM_LINK%%")) {
          postText.value = JSON.parse(campData.sharingPost).title.replace(
            "%%UTM_LINK%%",
            " "
          );
          return (postText.value = JSON.parse(
            campData.sharingPost
          ).title.replace("%%UTM_LINK%%", utm));
        }

        postText.value = JSON.parse(campData.sharingPost).title;
        return (postText.value =
          JSON.parse(campData.sharingPost).title + " " + utm + "!");
      } else {
        postText.value = JSON.parse(campData.sharingPost).title;
        store.dispatch("users/commitByKey", {
          utm: "",
        });
      }
    };

    watch(
      () => singleCamapignData.value,
      async (newVal, oldVal) => {
        oldVal;
        utmLink(newVal);
      }
    ),
      onMounted(() => {
        store.dispatch("campaign/commitByKey", {
          socialProfileData: {},
        });
        store.dispatch("campaign/commitByKey", {
          errors: [],
        });
        utmLink(singleCamapignData.value);
      });
    return {
      url,
      postIt,
      sharing,
      networks,
      twHandle,
      isLoader,
      postText,
      getImgUrl,
      buttonText,
      isPostItAction,
      socialProfileData,
      singleCamapignData,
      verifySocilaNetworkReq,
      twitterFolowersComaparation,
    };
  },
};
</script>
<style
  lang="scss"
  scoped
  src="@/Common/Styles/actionTypeRequirements.scss"
></style>
