import { computed } from "vue";
import { useRouter } from "vue-router";

// import { useStore } from "vuex";
// import useCampaign from "@/composables/useCampaign";
// const store = useStore();
// const { socialProfileData } = useCampaign(store);

const useAuth = (store, ethereumService, router = useRouter()) => {
  const disconnect = async (disconnectProvider = false) => {
    await store.dispatch("users/resetState");
    await store.dispatch("claiming/resetState");
    if (disconnectProvider) {
      await ethereumService.disconnectProvider();
    }
    await store.dispatch("users/logout");
    await localStorage.clear();
    return await router.push("/");
  };
  const handleAccountChange = async (account) => {
    console.log(
      `Account changed: ${account}`,
      `Please, sign the transaction on Metamask to continue.`,
      "Success"
    );
    await disconnect();
    await loginOrSignUp(true);
  };
  const handleChainChange = async (chain) => {
    chain === "Selected network is not supported."
      ? console.log("Network not supported", chain, "Error")
      : console.log("Network changed", chain, "Success");
    let publicAddress = await store.dispatch("users/fetchAddress");
    await store.dispatch("users/fetchNetwork");
    await store.dispatch("users/fetchNetworkName");
    await store.dispatch("users/fetchBalance", publicAddress);
  };
  const loginOrSignUp = async (isAccountChange = false) => {
    await ethereumService.connectToProvider();
    const networkProvider = await ethereumService.checkCachedProvider();
    const signerAppWalletLocal = computed(() => {
      if (networkProvider.isMetaMask) {
        store.dispatch("users/commitByKey", {
          signerAppWallet: "metamask",
        });
        return "metamask";
      } else {
        store.dispatch("users/commitByKey", {
          signerAppWallet: "walletConnect",
        });
        return "walletConnect";
      }
    });
    if (!isAccountChange) {
      await ethereumService.onAccountChanged(handleAccountChange);
    }
    await ethereumService.onChainChanged(handleChainChange);

    await store.dispatch("users/fetchNetwork");
    await store.dispatch("users/fetchNetworkName");
    let publicAddress = await store.dispatch("users/fetchAddress");
    await store.dispatch("users/fetchBalance", publicAddress);
    let nonce = await store.dispatch("users/login", publicAddress);
    const signature = await store.dispatch("users/signNonce", nonce);
    if (signature === undefined) {
      await store.dispatch("users/commitByKey", {
        isLoggedIn: false,
      });
      await disconnect();
      throw new Error(
        "MetaMask error",
        "Please, sign the message to access the advanced features of the application.",
        "Error"
      );
    }
    await store.dispatch("users/verifyNonce", {
      signature,
      nonce,
      publicAddress,
      signerApp: signerAppWalletLocal.value,
    });
    await store.dispatch("users/commitByKey", {
      isLoggedIn: true,
    });
    await fetchMe();
  };
  const isLoggedIn = computed(() =>
    store.getters["users/getByKey"]("isLoggedIn")
  );

  const userEmail = computed(() =>
    store.getters["users/getByKey"]("userEmail")
  );
  const changeUserEmail = (email) => {
    store.dispatch("users/commitByKey", {
      userEmail: email,
    });
  };

  const fetchMe = async () => {
    if (isLoggedIn.value) {
      try {
        await store.dispatch("users/fetchMe");
      } catch (err) {
        console.log(err);
        disconnect();
        await store.dispatch("users/resetState");
      }
    }
  };

  const updateUser = async () => {
    const userData = {
      email: store.getters["users/getByKey"]("userEmail"),
      twName: store.getters["users/getByKey"]("twName"),
      twEmailVerified: store.getters["users/getByKey"]("twEmailVerified"),
      twHandle: store.getters["users/getByKey"]("twHandle"),
      twPhotoUrl: store.getters["users/getByKey"]("twPhotoUrl"),
      twCreatedAt: store.getters["users/getByKey"]("twCreatedAt"),
      twFollowersCount: store.getters["users/getByKey"]("twFollowersCount"),
    };
    return await store.dispatch("users/updateUser", userData);
  };

  const updateUserTokens = async () => {
    const userTokenInfo = store.getters["claiming/getByKey"]("userNfts");
    let tokenArray = [];
    userTokenInfo.forEach((nft) => {
      tokenArray.push({
        token_id: nft.token_id,
        token_address: nft.token_address,
      });
    });
    await store.dispatch("users/updateUserTokens", tokenArray);
  };
  const applyToTwitterCampaign = async (proofType) => {
    const walletAddress = store.getters["users/getByKey"]("walletAddressInput");
    const utm = store.getters["users/getByKey"]("utm");
    const Id = store.getters["campaign/getByKey"]("campaignId");
    const twHandle = store.getters["users/getByKey"]("twHandle");
    const userEmail = store.getters["users/getByKey"]("userEmail");
    const twName = store.getters["users/getByKey"]("twName");
    const twEmailVerified = store.getters["users/getByKey"]("twEmailVerified");
    const twPhotoUrl = store.getters["users/getByKey"]("twPhotoUrl");
    const twFollowersCount =
      store.getters["users/getByKey"]("twFollowersCount");
    const twCreatedAt = store.getters["users/getByKey"]("twCreatedAt");
    const linkToContent = store.getters["users/getByKey"]("linkToContent");
    console.log("use Auth.js linkToContent", linkToContent.value);

    const data = {
      walletAddress: walletAddress,
      campaignId: Number(Id),
      ...(proofType === "post" &&
        utm !== "" && {
          utmShortLink: utm,
        }),
      handle: twHandle,
      ...(proofType === "post" && {
        postUrl: linkToContent,
      }),
      profileData: {
        email: userEmail,
        name: twName,
        emailVerified: twEmailVerified,
        handle: twHandle,
        photoUrl: twPhotoUrl,
        followersCount: twFollowersCount,
        createdAt: twCreatedAt,
        socialNetwork: "twitter",
      },
    };

    return await store.dispatch("campaign/applyToTwitterCampaign", data);
  };
  const applyToInstagramCampaign = async () => {
    const walletAddress = store.getters["users/getByKey"]("walletAddressInput");
    const Id = store.getters["campaign/getByKey"]("campaignId");
    const instagramAuthorizationCode = localStorage.getItem(
      "campaignInstagramCode"
    );
    const data = {
      walletAddress: walletAddress,
      campaignId: Number(Id),
      code: instagramAuthorizationCode,
      redirectUri: process.env.VUE_APP_URL,
    };

    return await store.dispatch("campaign/applyToInstagramCampaign", data);
  };
  const applyToInstagramCampaignManual = async () => {
    const walletAddress = store.getters["users/getByKey"]("walletAddressInput");
    const Id = store.getters["campaign/getByKey"]("campaignId");
    const handle = store.getters["users/getByKey"]("inUsername");

    const data = {
      walletAddress: walletAddress,
      campaignId: Number(Id),
      handle: handle,
      profileData: {
        name: handle,
        handle: handle,
        socialNetwork: "instagram",
      },
    };

    return await store.dispatch("campaign/applyToTwitterCampaign", data);
  };
  const address = computed(() => store.getters["users/getByKey"]("address"));
  const balance = computed(() => store.getters["users/getByKey"]("balance"));
  const network = computed(() => store.getters["users/getByKey"]("network"));
  const networkName = computed(() =>
    store.getters["users/getByKey"]("networkName")
  );
  const userId = computed(() => store.getters["users/getByKey"]("id"));
  const role = computed(() => store.getters["users/getByKey"]("role"));
  const signerAppWallet = computed(() =>
    store.getters["users/getByKey"]("signerAppWallet")
  );
  const twFollowersCount = computed(() =>
    store.getters["users/getByKey"]("twFollowersCount")
  );

  const twCreatedAt = computed(() =>
    store.getters["users/getByKey"]("twCreatedAt")
  );
  const twHandle = computed(() => store.getters["users/getByKey"]("twHandle"));
  const walletAddressInput = computed(() =>
    store.getters["users/getByKey"]("walletAddressInput")
  );

  return {
    role,
    userId,
    fetchMe,
    address,
    balance,
    network,
    twHandle,
    userEmail,
    disconnect,
    isLoggedIn,
    updateUser,
    twCreatedAt,
    networkName,
    loginOrSignUp,
    changeUserEmail,
    signerAppWallet,
    twFollowersCount,
    updateUserTokens,
    handleChainChange,
    walletAddressInput,
    handleAccountChange,
    applyToTwitterCampaign,
    applyToInstagramCampaign,
    applyToInstagramCampaignManual,
  };
};

export default useAuth;
