<template>
  <section class="participants-card">
    <div class="stats">
      <div class="stat">
        <h3 class="epilogue-subtitle-alt">
          <img
            src="@/Common/Icons/CampaignSinglePage/broIcon.svg"
            alt="Bro Icon"
            class="icon"
          />
          <span>
            {{ currentCampaignData.applicantsCount }}
          </span>
        </h3>
        <span class="epilogue-smaller">Bros Joined</span>
      </div>
      <div class="stat">
        <h3 class="epilogue-subtitle-alt">
          <img
            src="@/Common/Icons/CampaignSinglePage/reachIcon.svg"
            alt="Reach Icon"
            class="icon"
          />
          <span>
            <template v-if="currentCampaignData.applicantsCount > 0">
              {{ currentCampaignData.audienceCount }}
            </template>
            <template v-else> 0 </template>
          </span>
        </h3>
        <span class="epilogue-smaller">Bros Reach</span>
      </div>
    </div>
    <div class="participants" @mouseleave="sApplicantTooltipActive = false">
      <div
        v-for="(applicant, index) in currentApplicantList"
        class="participant"
        :class="[
          { active: applicant.flag === 'active' },
          { completed: applicant.flag === 'completed' },
          { winner: applicant.flag === 'winner' },
        ]"
        :key="index"
        :id="'id-' + index"
        @mouseover="handleApplicantTooltipActivation(applicant, index)"
      >
        <img
          v-if="
            currentCampaignData.proofType !== 'post' &&
            applicant.flag !== 'failed' &&
            applicant.flag !== 'joined'
          "
          :src="getImgUrl(currentCampaignData.awardingCollImagePath)"
          alt="Bro"
        />
        <div v-else class="placeholder-wrapper">
          <div class="placeholder placeholder-bg-1"></div>
        </div>
      </div>
      <applicant-tooltip
        @mouseover="isApplicantTooltipActive = true"
        @mouseleave="isApplicantTooltipActive = false"
        :style="{ left: tooltipX + 'px', top: tooltipY + 'px' }"
        v-if="isApplicantTooltipActive"
        :applicant="currentCampApplicant"
      />
    </div>
    <div
      v-if="
        singleCampaignApplicantPageData.totalCount >
          currentApplicantList.length &&
        params.page < singleCampaignApplicantPageData.totalPages
      "
      class="outline-button"
      @click="incrementPage()"
    >
      Load More
    </div>
  </section>
</template>
<script>
import { onMounted, ref, onUnmounted, computed, watch } from "vue";
import { useStore } from "vuex";
import useCampaign from "@/composables/useCampaign";
import ApplicantTooltip from "@/components/SingleCampaignPage/ApplicantTooltip";

export default {
  name: "CampaignParticipantsCard",
  components: {
    ApplicantTooltip,
  },
  props: {
    campaignData: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const store = useStore();
    const { getApplicantsByCampaignId, singleCampaignApplicantPageData } =
      useCampaign(store);

    let windowWidth = ref(window.innerWidth);

    const onWidthChange = () => (windowWidth.value = window.innerWidth);

    const width = computed(() => windowWidth.value);

    const isApplicantTooltipActive = ref(false);

    const currentCampApplicant = ref({
      flag: "",
      twitterHandle: "",
      walletAddress: "",
      proofType: "",
      awardingCollImagePath: "",
    });

    const tooltipX = ref("");
    const tooltipY = ref("");

    const handleApplicantTooltipActivation = (applicant, index) => {
      const element = document.getElementById("id-" + index);
      const distanceTop = element.getBoundingClientRect().top;
      const distanceLeft = element.getBoundingClientRect().left;
      const distanceRight = element.getBoundingClientRect().right;
      if (distanceLeft < 80) {
        tooltipX.value = element.offsetLeft + element.offsetWidth / 2 - 30;
      } else if (width.value - distanceRight < 80) {
        tooltipX.value = element.offsetLeft + element.offsetWidth / 2 - 190;
      } else {
        tooltipX.value = element.offsetLeft + element.offsetWidth / 2 - 110;
      }
      if (distanceTop >= 260) {
        tooltipY.value = element.offsetTop - element.offsetHeight - 190;
      } else {
        tooltipY.value = element.offsetTop + element.offsetHeight + 5;
      }

      currentCampApplicant.value = applicant;
      currentCampApplicant.value.proofType =
        currentCampaignData.value.proofType;
      currentCampApplicant.value.awardingCollImagePath =
        currentCampaignData.value.awardingCollImagePath;
      isApplicantTooltipActive.value = true;
    };

    const toggleApplicantTooltip = () => {
      isApplicantTooltipActive.value = !isApplicantTooltipActive.value;
    };

    const currentCampaignData = ref(props.campaignData);

    const placeholderClass = ref("");

    const params = ref({
      page: 1,
    });

    const incrementPage = () => {
      params.value.page = params.value.page + 1;
    };

    const currentApplicantList = ref([]);

    const getImgUrl = (imagePath) => {
      return process.env.VUE_APP_IMAGE_URL + imagePath;
    };

    watch(
      () => [props.campaignData, params.value.page],
      async ([newCampaignVal, newPageVal], [oldCampaignVal, oldPageVal]) => {
        newPageVal;
        oldPageVal;
        if (newCampaignVal !== oldCampaignVal) {
          oldCampaignVal;
          currentCampaignData.value = newCampaignVal;
        }
        if (currentCampaignData.value.id) {
          await getApplicantsByCampaignId(
            currentCampaignData.value.id,
            params.value
          );
          currentApplicantList.value.push(
            ...singleCampaignApplicantPageData.value.applicants
          );
        }
      }
    );

    onMounted(async () => {
      window.addEventListener("resize", onWidthChange);
    });

    onUnmounted(() => window.removeEventListener("resize", onWidthChange));

    return {
      params,
      getImgUrl,
      tooltipY,
      tooltipX,
      incrementPage,
      placeholderClass,
      currentCampaignData,
      currentApplicantList,
      currentCampApplicant,
      toggleApplicantTooltip,
      isApplicantTooltipActive,
      singleCampaignApplicantPageData,
      handleApplicantTooltipActivation,
    };
  },
};
</script>
<style lang="scss" scoped src="@/Common/Styles/participantsCard.scss"></style>
