<template>
  <button :class="buttonClass" :style="buttonStyle" :disabled="isDisabled">
    <i v-if="iconClass" :class="iconClass" :style="iconStyle"></i>
    <div class="button-loader" v-if="isLoader">
      <img
        src="@/Common/Icons/loader-button.gif"
        alt="CoverImage"
        class="button-loader-image"
        v-if="isLoader"
      />
    </div>
    <div v-else>
      <img
        :src="iconSource"
        alt=""
        v-if="requirementsButton"
        class="requirements-button-iocn"
      />
      <span>{{ text }} <span class="arrow"></span> </span>
    </div>
    <span class="subText" v-if="subText"> {{ subText }}</span>
  </button>
</template>

<script>
export default {
  name: "AppButton",
  props: {
    text: String,
    subText: String,
    buttonClass: {
      type: String,
      default: "",
    },
    buttonStyle: {
      type: Object,
      default: () => {},
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    iconClass: {
      type: String,
      default: "",
    },
    iconStyle: {
      type: Object,
      default: () => {},
    },
    isLoader: {
      type: Boolean,
      default: false,
    },
    requirementsButton: {
      type: Boolean,
      default: false,
    },
    iconSource: {
      type: String,
      default: "",
    },
  },
};
</script>
