import * as Yup from "yup";

const today = new Date();
today.setHours(0, 0, 0, 0);

export const campaignDetailsSchema = Yup.object().shape({
  CampaignName: Yup.string()
    .required("Campaign name is required")
    .max(80, "Maximum length is 80"),
  CampaignDescription: Yup.string().required("Campaign descriptionis required"),
  StartDate: Yup.date()
    .min(today, "Start date can't be before today")
    .required("Application deadline is required")
    .nullable()
    .transform((curr, orig) => (orig === "" ? null : curr)),
  EndDate: Yup.date()
    .min(Yup.ref("StartDate"), "End date can't be before start date")
    .required("End date is required")
    .nullable()
    .transform((curr, orig) => (orig === "" ? null : curr)),
  CampaignNumberOfParticipats: Yup.number()
    .integer("Should not have decimals")
    .typeError("Must be a number")
    .nullable()
    .positive("Must be greater than 0")
    .transform((_, val) => (val !== "" ? Number(val) : null)),
  CampaignMaxNumberOfWinners: Yup.number()
    .integer("Should not have decimals")
    .typeError("Must be a number")
    .nullable()
    .positive("Must be greater than 0")
    .transform((_, val) => (val !== "" ? Number(val) : null)),
});
