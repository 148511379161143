<template>
  <div class="empty-state-wrapper create-campaign-cta cc-open-list">
    <img src="@/Common/Icons/noCampaignsRunner.svg" alt="Runner" />
    <div class="headline-text">
      <span>New campaigns are</span>
      <span> currently being crafted</span>
    </div>
    <div class="secondary-text">
      <span> Come back soon to check out available campaigns, or </span>
      <span>promote a cause or a brand on your own. </span>
    </div>
    <div class="button-wrapper">
      <app-button
        text="CREATE A CAMPAIGN"
        buttonClass="primary-button-on-light"
        @click="$router.push('/create-twitter-campaign')"
      />
    </div>
  </div>
</template>
<script>
import AppButton from "@/elements/AppButton";
export default {
  components: {
    AppButton,
  },
  setup() {
    return {};
  },
};
</script>
<style lang="scss" scoped src="@/Common/Styles/emptyTableState.scss"></style>
