<template>
  <div class="create-campaign-wrap row">
    <div class="campaign-form">
      <campaign-details />
      <campaign-requirements />
      <campaign-reward />
      <div class="create-campaign-button-wrapper">
        <app-button
          :text="isLoggedIn ? 'Create Campaign' : 'Connect Wallet & Create'"
          class="primary-button-on-light create-campaign-button"
          type="submit"
          :disabled="buttonDisabled"
          :isLoader="buttonDisabled ? true : false"
        />
      </div>
    </div>
    <creating-campaign-modal
      v-if="buttonDisabled"
      :modalActive="buttonDisabled"
      :isXbutton="false"
    />
    <campaign-share-modal
      v-if="isCampaignShareModalActive"
      :modalActive="isCampaignShareModalActive"
      :shareInfo="shareInfo"
      @close="toggleModalClaim"
    />
  </div>
</template>
<script>
import { ref } from "vue";
import { useStore } from "vuex";
import { ethereumService } from "@/main";
import useAuth from "@/composables/useAuth";
import AppButton from "@/elements/AppButton";
import useCampaign from "@/composables/useCampaign";
import useTransactions from "@/composables/useTransactions";
import CampaignDetails from "@/components/CreateTwitterCampaign/CampaignDetails";
import CampaignReward from "@/components/CreateTwitterCampaign/CampaignRewardWrapper/CampaignReward";
import CampaignRequirements from "@/components/CreateTwitterCampaign/CampaignRequirementsWrapper/CampaignRequirements";
import CampaignShareModal from "@/components/CreateTwitterCampaign/CampaignShareModal";
import CreatingCampaignModal from "@/components/CreateTwitterCampaign/CreatingCampaignModal";

export default {
  components: {
    AppButton,
    CampaignReward,
    CampaignDetails,
    CampaignShareModal,
    CampaignRequirements,
    CreatingCampaignModal,
  },
  setup() {
    const store = useStore();
    const { buttonDisabled } = useCampaign(store);
    const { isCampaignShareModalActive, currentCampaignData } =
      useTransactions(store);
    const { isLoggedIn } = useAuth(store, ethereumService);
    const toggleModalClaim = () => {
      store.dispatch("transactions/commitByKey", {
        isCampaignShareModalActive: false,
      });
      store.dispatch("transactions/commitByKey", {
        currentCampaignData: {},
      });
    };
    const modalActiveClaim = ref(false);
    const shareInfo = ref({
      campaignInfo: currentCampaignData,
    });

    return {
      shareInfo,
      isLoggedIn,
      buttonDisabled,
      modalActiveClaim,
      toggleModalClaim,
      currentCampaignData,
      isCampaignShareModalActive,
    };
  },
};
</script>
<style lang="scss" scoped src="@/Common/Styles/createCampaign.scss"></style>
