<template>
  <div class="campaigns-mobile">
    <div class="table-filter-wrap mobile">
      <div class="campaigns-filter-search-wrap">
        <div class="campaigns-search">
          <app-input
            v-model="searchString"
            @keydown.enter.stop="searchFilter"
            inputClass="form-box-input"
            placeholder="Search Campaigns"
          />
          <img
            alt="search"
            class="campaigns-search-icon"
            src="@/Common/Icons/search_icon.svg"
          />
          <img
            alt="delete"
            v-show="searchString !== ''"
            @click="resetSearchString()"
            class="campaigns-search-cross"
            src="@/Common/Icons/search_cross.svg"
          />
        </div>
        <div class="campaigns-filter">
          <div class="filter-wrap pointer" @click="toggleShowFilters()">
            Filter
            <img
              alt="filter"
              class="campaigns-filter-icon"
              src="@/Common/Icons/filter-icon.svg"
            />
          </div>
          <div class="filtering-options" v-if="showFilters">
            <teleport to="Body">
              <div
                class="filter-overlay"
                v-if="showFilters"
                @click="toggleShowFilters()"
              ></div>
            </teleport>
            <div class="filtering-option pfp-type">
              <p>Social Media</p>
              <ul>
                <li
                  :class="{ active: filterOption.socialNetwork === 'twitter' }"
                  @click="setFilter('socialNetwork', 'twitter')"
                >
                  Twitter
                </li>
                <li
                  :class="{
                    active: filterOption.socialNetwork === 'instagram',
                  }"
                  @click="setFilter('socialNetwork', 'instagram')"
                >
                  Instagram
                </li>
              </ul>
            </div>
            <div class="filtering-option pfp-type">
              <p>PFP Type</p>
              <ul>
                <li
                  :class="{ active: filterOption.pfpType === 'image' }"
                  @click="setFilter('pfpType', 'image')"
                >
                  Regular Image
                </li>
                <li
                  :class="{ active: filterOption.pfpType === 'nft' }"
                  @click="setFilter('pfpType', 'nft')"
                >
                  NFT
                </li>
              </ul>
            </div>
            <div class="filtering-option reward">
              <p>Reward</p>
              <ul>
                <li
                  :class="{ active: filterOption.reward === 'nft' }"
                  @click="setFilter('reward', 'nft')"
                >
                  NFT
                </li>
                <li
                  :class="{ active: filterOption.reward === 'token' }"
                  @click="setFilter('reward', 'token')"
                >
                  Token
                </li>
              </ul>
            </div>
            <div class="filtering-option status">
              <p>Status</p>
              <ul>
                <li
                  :class="{ active: filterOption.joined === 'true' }"
                  @click="setFilter('joined', 'true')"
                >
                  Joined
                </li>
                <li
                  v-if="status !== 'pending'"
                  :class="{ active: filterOption.claimed === 'false' }"
                  @click="setFilter('claimed', 'false')"
                >
                  Claim
                </li>
                <li
                  v-if="status !== 'pending'"
                  :class="{ active: filterOption.claimed === 'true' }"
                  @click="setFilter('claimed', 'true')"
                >
                  Claimed
                </li>
              </ul>
            </div>
            <div class="filtering-action">
              <div class="clear-filter" @click="setFilter()">Clear</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="spinner" v-if="isSpinner">
      <img src="@/Common/Icons/spinner.gif" alt="spinner" />
    </div>
    <div class="campaign-cards-list" ref="scrollComponent">
      <campaign-card
        v-for="campaign in campaigns"
        v-bind:key="campaign.id"
        :campaignInfo="campaign"
      />
      <div
        v-if="pageCount > params.page"
        class="outline-button campaign-load-more"
        @click="incrementPage()"
      >
        Load More
      </div>
      <div class="spinner" v-if="isScrollSpinner">
        <img src="@/Common/Icons/spinner.gif" alt="spinner" />
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/services/api";
import { useStore } from "vuex";
import { eventBus } from "@/main";
import AppInput from "@/elements/AppInput";
import useAuth from "@/composables/useAuth";
import useUtilities from "@/composables/useUtilities";
import { nextTick, ref, onMounted } from "vue";
import CampaignCard from "@/components/CampaignCard";

export default {
  components: {
    AppInput,
    CampaignCard,
  },
  props: {
    status: {
      type: String,
      default: "pending",
    },
  },
  setup(props) {
    const store = useStore();
    const campaignInfo = ref({});
    const isCampaignData = ref(false);
    const filterCount = ref(0);
    const searchString = ref("");
    const modalActive = ref(false);
    const modalActiveClaim = ref(false);
    const showFilters = ref(false);
    const campaignsTable = ref(null);
    const { isLoggedIn } = useAuth(store);
    const { formatDate } = useUtilities();
    const filterOption = ref({
      socialNetwork: "",
      pfpType: "",
      reward: "",
      joined: "",
      claimed: "",
    });

    const campaigns = ref([]);
    const moreCampaigns = ref([]);
    const pageCount = ref(0);
    const isSpinner = ref(false);
    const isScrollSpinner = ref(false);
    const scrollComponent = ref(null);

    const params = {
      page: 1,
      status: props.status,
      order: "start_first",
    };

    const getImgUrl = (imagePath) => {
      return process.env.VUE_APP_IMAGE_URL + imagePath;
    };
    const openModal = async (campaign) => {
      await store.dispatch("campaign/commitByKey", {
        campaignId: campaign.id,
      });

      localStorage.setItem("campaignId", campaign.id);

      await store.dispatch("campaign/commitByKey", {
        campaignFlag: campaign.avaliableFlag,
      });
      campaignInfo.value = campaign;
      if (campaign.avaliableFlag === "claim") {
        return (modalActiveClaim.value = true);
      }
      return (modalActive.value = true);
    };

    const toggleModal = () => {
      modalActive.value = !modalActive.value;
    };
    const toggleModalClaim = () => {
      modalActiveClaim.value = !modalActiveClaim.value;
    };
    const toggleShowFilters = () => {
      showFilters.value = !showFilters.value;
    };
    const resetSearchString = () => {
      searchString.value = "";
    };

    const setFilter = (filter, value) => {
      if (!filter && !value) {
        for (const [key] of Object.entries(filterOption.value)) {
          filterOption.value[key] = "";
        }
        return [(filterCount.value = 0), getCampaigns()];
      }
      if (filterOption.value[filter] === value) {
        filterCount.value--;
        return [(filterOption.value[filter] = ""), getCampaigns()];
      }
      if (filterOption.value[filter] === "") {
        filterCount.value++;
      }
      filterOption.value[filter] = value;

      if (filterOption.value.socialNetwork)
        params.social_network = filterOption.value.socialNetwork;
      if (filterOption.value.pfpType) params.proof = filterOption.value.pfpType;
      if (filterOption.value.reward) params.reward = filterOption.value.reward;
      if (filterOption.value.claimed)
        params.claimed = filterOption.value.claimed;
      if (filterOption.value.joined) params.joined = filterOption.value.joined;

      getCampaigns();
    };
    const searchFilter = () => {
      params.search = searchString.value;
      getCampaigns();
    };
    const getCampaigns = async () => {
      if (isLoggedIn.value) {
        campaigns.value = await api.getCampaignsLoggedIn(params);
      } else {
        campaigns.value = await api.getAllCampaigns(params);
      }
      pageCount.value = campaigns.value.data.pages;
      campaigns.value = campaigns.value.data.campagins;
      isCampaignData.value = false;
      if (campaigns.value.length < 1) {
        isCampaignData.value = true;
      }
    };

    const getMoreCampaigns = async () => {
      if (isLoggedIn.value) {
        moreCampaigns.value = await api.getCampaignsLoggedIn(params);
      } else {
        moreCampaigns.value = await api.getAllCampaigns(params);
      }
      Array.prototype.push.apply(
        campaigns.value,
        moreCampaigns.value.data.campagins
      );
      moreCampaigns.value.data.campagins = [];
    };

    eventBus.on("resetCampaignsInfo", async (data) => {
      campaignInfo.value = data;
      modalActive.value = false;
      await nextTick();
      getCampaigns();
      if (data.avaliableFlag === "claim") {
        return (modalActiveClaim.value = true), (modalActive.value = false);
      }
      modalActive.value = true;
    });

    eventBus.on("resetCampaignsClaim", async (data) => {
      campaignInfo.value = data;
      await nextTick();
      getCampaigns();
    });

    const handleScroll = async () => {
      if (pageCount.value === 0) return;

      let element = scrollComponent.value;
      if (element.getBoundingClientRect().bottom < window.innerHeight) {
        if (params.page === pageCount.value || isScrollSpinner.value) return;

        params.page += 1;

        isScrollSpinner.value = true;
        await getMoreCampaigns();
        isScrollSpinner.value = false;
      }
    };

    const incrementPage = async () => {
      params.page += 1;
      await getMoreCampaigns();
    };

    onMounted(async () => {
      // window.addEventListener("scroll", handleScroll);

      isSpinner.value = true;
      await getCampaigns();
      isSpinner.value = false;
    });

    // onUnmounted(() => {
    //   window.removeEventListener("scroll", handleScroll);
    // });

    return {
      params,
      pageCount,
      campaigns,
      getImgUrl,
      openModal,
      isSpinner,
      setFilter,
      formatDate,
      modalActive,
      showFilters,
      filterCount,
      toggleModal,
      handleScroll,
      campaignInfo,
      filterOption,
      searchFilter,
      searchString,
      incrementPage,
      campaignsTable,
      isCampaignData,
      scrollComponent,
      modalActiveClaim,
      toggleModalClaim,
      resetSearchString,
      toggleShowFilters,
    };
  },
};
</script>
<style lang="scss" scoped src="@/Common/Styles/campaignTable.scss"></style>
