import { computed } from "vue";

const useClaiming = (store) => {
  const userNfts = computed(() =>
    store.getters["claiming/getByKey"]("userNfts")
  );
  const calimableCampaigns = computed(() =>
    store.getters["claiming/getByKey"]("calimableCampaigns")
  );
  const calimableCount = computed(() =>
    store.getters["claiming/getByKey"]("calimableCount")
  );

  const fetchNftUserAddress = (options) =>
    store.dispatch("claiming/fetchNftUserAddress", options);

  const getClaimableCamapigns = () =>
    store.dispatch("claiming/getClaimableCamapigns");

  const getClaimableCount = () => store.dispatch("claiming/getClaimableCount");

  const getMerkleProof = async () => {
    const Id = store.getters["campaign/getByKey"]("campaignId");
    return await store.dispatch("claiming/getMerkleProof", Id);
  };

  const claimGaslessReward = async () => {
    const Id = store.getters["campaign/getByKey"]("campaignId");
    return await store.dispatch("claiming/gaslessClaim", Id);
  };

  return {
    userNfts,
    calimableCount,
    getMerkleProof,
    getClaimableCount,
    claimGaslessReward,
    calimableCampaigns,
    fetchNftUserAddress,
    getClaimableCamapigns,
  };
};

export default useClaiming;
