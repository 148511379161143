import { computed } from "vue";
import api from "@/services/api";

const useSignUpCampaignCreator = (store) => {
  const project = computed(() =>
    store.getters["signUpCampaignCreator/getByKey"]("project")
  );
  const nftCollection = computed(() =>
    store.getters["signUpCampaignCreator/getByKey"]("nftCollection")
  );
  const event = computed(() =>
    store.getters["signUpCampaignCreator/getByKey"]("event")
  );
  const brand = computed(() =>
    store.getters["signUpCampaignCreator/getByKey"]("brand")
  );
  const creatorName = computed(() =>
    store.getters["signUpCampaignCreator/getByKey"]("creatorName")
  );
  const creatorEmail = computed(() =>
    store.getters["signUpCampaignCreator/getByKey"]("creatorEmail")
  );
  const creatorWalletAddress = computed(() =>
    store.getters["signUpCampaignCreator/getByKey"]("creatorWalletAddress")
  );
  const pendingCreatorsList = computed(() =>
    store.getters["signUpCampaignCreator/getByKey"]("pendingCreatorsList")
  );
  const setSignUpCreatorData = computed(() =>
    store.getters["signUpCampaignCreator/getByKey"]("setSignUpCreatorData")
  );
  const buttonDisabled = computed(() =>
    store.getters["signUpCampaignCreator/getByKey"]("buttonDisabled")
  );

  const signUpCreator = (data) =>
    store.dispatch("signUpCampaignCreator/signUpCreator", data);

  const pendingCreators = () =>
    store.dispatch("signUpCampaignCreator/pendingCreators");

  const changeCreatorProject = (value) => {
    store.dispatch("signUpCampaignCreator/commitByKey", {
      project: value,
    });
  };
  const changeCreatorNftcollection = (value) => {
    store.dispatch("signUpCampaignCreator/commitByKey", {
      nftCollection: value,
    });
  };
  const changeCreatorBrand = (value) => {
    store.dispatch("signUpCampaignCreator/commitByKey", {
      brand: value,
    });
  };
  const changeCreatorEvent = (value) => {
    store.dispatch("signUpCampaignCreator/commitByKey", {
      event: value,
    });
  };
  const changeCreatorName = (value) => {
    store.dispatch("signUpCampaignCreator/commitByKey", {
      creatorName: value,
    });
  };
  const changeCreatorEmail = (value) => {
    store.dispatch("signUpCampaignCreator/commitByKey", {
      creatorEmail: value,
    });
  };
  const changeCreatorWalletAddress = (value) => {
    store.dispatch("signUpCampaignCreator/commitByKey", {
      creatorWalletAddress: value,
    });
  };
  const updateCreators = async (data) => {
    await api.updateCreators(data);
  };

  return {
    event,
    brand,
    project,
    creatorName,
    creatorEmail,
    nftCollection,
    buttonDisabled,
    pendingCreatorsList,
    creatorWalletAddress,
    setSignUpCreatorData,
    changeCreatorBrand,
    changeCreatorEvent,
    changeCreatorProject,
    changeCreatorNftcollection,
    changeCreatorName,
    changeCreatorEmail,
    changeCreatorWalletAddress,
    signUpCreator,
    pendingCreators,
    updateCreators,
  };
};

export default useSignUpCampaignCreator;
