<template>
  <div class="twitter-campain-wrap">
    <div class="container">
      <div class="explanation-paragraf">
        <p class="twitter-campaign-headline">
          Create a <br />
          Twitter Campaign
        </p>
      </div>
      <base-form :handleSubmit="submit" :schema="schema">
        <create-campaign />
      </base-form>
    </div>
  </div>
</template>
<script>
import { useStore } from "vuex";
import { ref, computed, onMounted } from "vue";
import { ethereumService } from "@/main";
import useAuth from "@/composables/useAuth";
import BaseForm from "@/components/BaseForm";
import { ADDRESSES } from "@/contracts/addresses.js";
import useCampaign from "@/composables/useCampaign";
import useMintAirBro from "@/composables/useMintAirBro";
import useCreateCampaignDrop from "@/composables/useCreateCampaignDrop";
import CreateCampaign from "@/components/CreateTwitterCampaign/CreateCampaign";
import campaignNftPfpRewardNftSchema from "@/validationSchemas/campaignNftPfpRewardNftSchema";
import campaignNftPfpRewardTokenSchema from "@/validationSchemas/campaignNftPfpRewardTokenSchema";
import campaignCustomPfpRewardNftSchema from "@/validationSchemas/campaignCustomPfpRewardNftSchema";
import campaignCustomPfpRewardTokenSchema from "@/validationSchemas/campaignCustomPfpRewardTokenSchema";
import campaignCustomPfpRewardGeneratedNftSchema from "@/validationSchemas/campaignCustomPfpRewardGeneratedNftSchema";
import campaignNftPfpRewardGeneratedNftSchema from "@/validationSchemas/campaignNftPfpRewardGeneratedNftSchema";
import campaignPostContentRewardNftSchema from "@/validationSchemas/campaignPostContentRewardNftSchema";
import campaignPostContentRewardTokenSchema from "@/validationSchemas/campaignPostContentRewardTokenSchema";
import campaignPostContentRewardGeneratedNftSchema from "@/validationSchemas/campaignPostContentRewardGeneratedNftSchema";
export default {
  components: {
    BaseForm,
    CreateCampaign,
  },
  setup() {
    const store = useStore();
    const mintingAddress = ref(ADDRESSES["MintingAddressMumbai"]);
    const {
      getCampaignRewardComponentName,
      getCampaignRequirementsComponentName,
    } = useCampaign(store);
    const { handleSubmit } = useCreateCampaignDrop(store);
    const { videoClassActive } = useMintAirBro(store);

    const { loginOrSignUp, isLoggedIn } = useAuth(store, ethereumService);
    const submit = async () => {
      if (!isLoggedIn.value) {
        await loginOrSignUp();
      }
      await handleSubmit();
    };

    onMounted(() => {
      store.dispatch("transactions/commitByKey", {
        socialNetwork: "twitter",
      });
    });
    const schema = computed(() => {
      if (
        getCampaignRewardComponentName.value === "MintAirBro" &&
        getCampaignRequirementsComponentName.value === "CustomPFP"
      ) {
        if (videoClassActive.value) {
          return campaignCustomPfpRewardGeneratedNftSchema;
        }
        return campaignCustomPfpRewardNftSchema;
      }
      if (
        getCampaignRewardComponentName.value === "MintAirBro" &&
        getCampaignRequirementsComponentName.value === "NftPFP"
      ) {
        if (videoClassActive.value) {
          return campaignNftPfpRewardGeneratedNftSchema;
        }
        return campaignNftPfpRewardNftSchema;
      }
      if (
        getCampaignRewardComponentName.value === "MintAirBro" &&
        getCampaignRequirementsComponentName.value === "PostContent"
      ) {
        if (videoClassActive.value) {
          return campaignPostContentRewardGeneratedNftSchema;
        }
        return campaignPostContentRewardNftSchema;
      }
      if (
        getCampaignRewardComponentName.value === "SendExistingToken" &&
        getCampaignRequirementsComponentName.value === "CustomPFP"
      ) {
        return campaignCustomPfpRewardTokenSchema;
      }
      if (
        getCampaignRewardComponentName.value === "SendExistingToken" &&
        getCampaignRequirementsComponentName.value === "NftPFP"
      ) {
        return campaignNftPfpRewardTokenSchema;
      }
      if (
        getCampaignRewardComponentName.value === "SendExistingToken" &&
        getCampaignRequirementsComponentName.value === "PostContent"
      ) {
        return campaignPostContentRewardTokenSchema;
      }
      return {};
    });

    return {
      schema,
      submit,
      handleSubmit,
      mintingAddress,
    };
  },
};
</script>
<style
  lang="scss"
  scoped
  src="@/Common/Styles/twitterCampaignDashboard.scss"
></style>
