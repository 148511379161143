import store from "./store";
import App from "./App.vue";
import router from "./router";
import Hotjar from "vue-hotjar";
import { createApp } from "vue";
import Moralis from "./plugins/moralis";
import { ServerTable } from "v-tables-3";
import BootstrapVue3 from "bootstrap-vue-3";
import VueSocialSharing from "vue-social-sharing";
import EthereumService from "./services/ethereum";
import CustomSortControl from "@/elements/CustomSortControl";
import Toaster from "@meforma/vue-toaster";
import vClickOutside from "click-outside-vue3";
import VCalendar from "v-calendar";
const VueScrollTo = require("vue-scrollto");

import "vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue-3/dist/bootstrap-vue-3.css";
import "@/Common/Styles/base.scss";
import "v-calendar/dist/style.css";

import mitt from "mitt";
export const eventBus = mitt();

const app = createApp(App)
  .use(store)
  .use(router)
  .use(BootstrapVue3)
  .use(VueSocialSharing)
  .use(VueScrollTo)
  .use(vClickOutside)
  .use(ServerTable, {}, "", {
    sortControl: CustomSortControl,
  })
  .use(Hotjar, {
    id: "3154662",
    isProduction: true,
  })
  .use(VCalendar, {})
  .use(Toaster)
  .provide("$moralis", Moralis);

const CHAIN_ID = 1;
export const ethereumService = new EthereumService(CHAIN_ID);

app.provide("eventBus", eventBus);
app.provide("ethereumService", ethereumService);
app.mount("#app");
